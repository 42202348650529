import { FC, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

import { useTheme } from '@liveconnect/components'

import useMember from '../core/member/useMember'
import SigninCallback from '../pages/auth/SigninCallback'
import Logout from '../pages/auth/Logout'
import CommunitiesRouter from './CommunitiesRouter'
import SigninSilentRenew from '../pages/auth/SigninSilentRenew'
import SignedOut from '../pages/auth/SignedOut'
import { DeletedUserPage } from '../pages/Shared/DeletedUser'
import NotFound from '../pages/Error/NotFound'
import useAuth from '../core/auth/useAuth'
import useTenantConfig from '../core/tenantConfig/useTenantConfig'
import useGTM from '../utils/useGTM'

const COMETCHAT_LANGUAGE = 'cometchat:locale'

const RouterWrapper: FC = () => {
  const { i18n } = useTranslation()
  const { member } = useMember()
  const { isLoggedIn } = useAuth()
  const { fetchPublicTenantConfig, tenantConfig } = useTenantConfig()
  const { pushDataLayer } = useGTM()

  const { changeThemeVars } = useTheme()

  useEffect(() => {
    if (tenantConfig && tenantConfig.componentConfiguration) {
      const themeVars: Record<string, string> = {}
      for (const [, themeVar] of Object.entries(
        tenantConfig.componentConfiguration
      )) {
        themeVars[themeVar.key] = themeVar.value
      }
      changeThemeVars(themeVars)
    }
  }, [tenantConfig])

  useEffect(() => {
    !isLoggedIn && fetchPublicTenantConfig()
  }, [isLoggedIn])

  useEffect(() => {
    const lang = member?.isoCode ?? navigator.language
    i18n.changeLanguage(lang)
    localStorage.setItem(COMETCHAT_LANGUAGE, lang.split('-')[0])
    pushDataLayer({
      event: 'change_language',
      category: 'interaction',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member])

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: (member?.isoCode ?? navigator.language).split('-')[0],
        }}
      >
        {tenantConfig?.favIcoUrl && (
          <link
            id="favicon"
            rel="icon"
            href={tenantConfig?.favIcoUrl}
            type="image/x-icon"
          />
        )}
      </Helmet>
      <Routes>
        <Route path="/deleted-user" element={<DeletedUserPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/signed-out" element={<SignedOut />} />
        <Route path="/signin-callback" element={<SigninCallback />} />
        <Route path="/signin-silent-renew" element={<SigninSilentRenew />} />
        <Route path="t/:tenantId/*" element={<CommunitiesRouter />} />
        <Route path="/" element={<div />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

const AppRouter: FC = () => {
  return (
    <BrowserRouter>
      <RouterWrapper />
    </BrowserRouter>
  )
}

export default AppRouter
