import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseRecomendedMembersResult,
  RecomendedMemberListResponse,
  RecomendedMemberRequestParams,
} from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useRecomendedMembers = (): UseRecomendedMembersResult => {
  const dispatch = useAppDispatch()
  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.recomendedMembers)
  const { get } = useFetch()

  const endpoint = 'members/recommended'

  const parseRecomendedMemberParams = (
    requestParams?: RecomendedMemberRequestParams
  ) => {
    const params: RecomendedMemberRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getRecomendedMembers = async (
    params?: RecomendedMemberRequestParams
  ) => {
    const response: RecomendedMemberListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchRecomendedMembers = async (
    params?: RecomendedMemberRequestParams
  ) => {
    dispatch(showLoader())
    const reqParams = parseRecomendedMemberParams(params)
    const response: RecomendedMemberListResponse | undefined =
      await getRecomendedMembers(reqParams)
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const clearRecomendedMemberList = () => dispatch(clearList())

  return {
    recomendedMemberList: list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    fetchRecomendedMembers,
    clearRecomendedMemberList,
  }
}

export default useRecomendedMembers
