import { FC, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { HtmlSanitizer } from '@liveconnect/communities-ui'
import { Loader, Avatar, ButtonAsync } from '@liveconnect/components'

import { Main } from '../../../components/Main'
import useMember from '../../../core/member/useMember'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useUi from '../../../core/ui/useUi'
import useContactRequests from '../../../core/contactRequests/useContactRequests'
import useContacts from '../../../core/contacts/useContacts'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import useCulture from '../../../utils/culture'
import Carousel from '../../../components/Carousel'
import { TypeCard } from '../../../components/Carousel/types'
import ContactModal from '../../../components/ContactModal'
import { CreateContactPayload } from '../../../core/contactRequests/types'
import { JobsMember } from '../../../core/member/types'
import { returnHypphen } from '../../../utils/showHyppen/showHyppen'

import './styles.scss'

const ProfileScreen: FC = () => {
  const { t } = useTranslation()
  const notify = useSnackBars()
  const navigate = useNavigate()
  const { profileId } = useParams()
  const { basePath } = useCustomRouter()
  const { showConfirmation } = useUi()
  const [sameMember, setSameMember] = useState<boolean>(false)
  const [showModalContact, setShowModalContact] = useState<boolean>(false)
  const [memberContact, setMemberContact] =
    useState<CreateContactPayload | null>(null)
  const { formatDate } = useCulture()
  const {
    memberProfile,
    fetchMemberProfile,
    isLoaded,
    unsetMemberProfile,
    member,
  } = useMember()
  const {
    cancelContactRequest,
    acceptContactRequest,
    rejectContactRequest,
    createContactRequest,
  } = useContactRequests()
  const { deleteContact } = useContacts()

  const handleConfirmCancel = () => {
    const name = `${memberProfile?.name} ${memberProfile?.surname}`
    showConfirmation({
      title: t('contacts.sentList.confirm.title'),
      subtitle: t('contacts.sentList.confirm.subtitle', { name }),
      text: t('contacts.sentList.confirm.text'),
      confirmText: t('contacts.sentList.confirm.button'),
      iconName: 'report_problem',
      onConfirm: handleCancel,
    })
  }

  const handleCancel = async () => {
    try {
      await cancelContactRequest(memberProfile?.contactRequestId as string)
      notify.success(t('contacts.sentList.ignore.successFeedback'))
    } catch (e) {
      notify.error(t('contacts.sentList.ignore.unknownError'))
    } finally {
      profileId && fetchMemberProfile(profileId)
    }
  }

  const handleConfirmIgnore = () => {
    const name = `${memberProfile?.name} ${memberProfile?.surname}`
    showConfirmation({
      title: t('contacts.receivedList.confirm.title'),
      subtitle: t('contacts.receivedList.confirm.subtitle', { name }),
      text: t('contacts.receivedList.confirm.text'),
      confirmText: t('common.ignore'),
      iconName: 'report_problem',
      onConfirm: handleIgnore,
    })
  }

  const handleIgnore = async () => {
    try {
      await rejectContactRequest(memberProfile?.contactRequestId as string)
      notify.success(t('contacts.receivedList.ignore.successFeedback'))
    } catch (e) {
      notify.error(t('contacts.receivedList.ignore.unknownError'))
    } finally {
      profileId && fetchMemberProfile(profileId)
    }
  }

  const handleAccept = async () => {
    try {
      await acceptContactRequest(memberProfile?.contactRequestId as string)
      notify.success(t('contacts.receivedList.accept.successFeedback'))
    } catch (e) {
      notify.error(t('contacts.receivedList.accept.unknownError'))
    } finally {
      profileId && fetchMemberProfile(profileId)
    }
  }

  const handleConfirmDelete = () => {
    showConfirmation({
      title: t('networking.confirmDelete.title'),
      subtitle: t('networking.confirmDelete.subtitle'),
      text: t('networking.confirmDelete.text'),
      confirmText: t('common.delete'),
      iconName: 'report_problem',
      onConfirm: handleDelete,
    })
  }

  const handleDelete = async () => {
    try {
      await deleteContact(memberProfile?.contactId as string)
      notify.success(t('networking.delete.successFeedback'))
    } catch (e) {
      notify.error(t('networking.delete.unknownError'))
    } finally {
      profileId && fetchMemberProfile(profileId)
    }
  }

  const handleMessage = () => {
    navigate(`${basePath}/chat/${memberProfile?.id}`)
  }

  const handleConnect = async (id: string, name: string) => {
    setMemberContact({ id: id, name: name })
    setShowModalContact(true)
  }

  const handleCreateContact = async (
    message: string | undefined,
    id: string
  ) => {
    setShowModalContact(false)
    try {
      if (message) {
        await createContactRequest(id, message)
      } else {
        await createContactRequest(id)
      }
      notify.success(t('recomendedMembers.list.successFeedback'))
    } catch (error: any) {
      if (error?.body?.[0]?.code === 'CONTACT_REQUEST_EXCEEDED') {
        const date = formatDate(
          new Date(error?.body?.[0]?.errorContext[0].value),
          'LONG_DATE_TIME_VERBOSE'
        )
        showConfirmation({
          title: t('common.warning'),
          subtitle: t('recomendedMembers.error.subtitle'),
          text: t('recomendedMembers.error.text', { date }),
          confirmText: t('common.accept'),
          iconName: 'report_problem',
          hideCancel: true,
        })
      } else {
        notify.error(t('recomendedMembers.list.unknownError'))
      }
    } finally {
      profileId && fetchMemberProfile(profileId)
    }
  }

  const renderProfileActions = () => {
    if (!memberProfile) return
    if (memberProfile.contactRequestId) {
      if (memberProfile.contactRequestType === 'Sent') {
        return renderActionsSent()
      }
      return renderActionsReceived()
    }
    if (memberProfile.contactId) {
      return renderActionsContact()
    }
    if (memberProfile.id) {
      return renderActionsNewContact(memberProfile.id, memberProfile.name)
    }
  }

  useEffect(() => {
    profileId && fetchMemberProfile(profileId)
    profileId === member?.id && setSameMember(true)
    return unsetMemberProfile
  }, [profileId])

  const renderActionsSent = () => (
    <button
      className="btn btn-primary btn--purple"
      onClick={handleConfirmCancel}
    >
      {t('contacts.sentList.button')}
    </button>
  )

  const renderActionsReceived = () => (
    <>
      <button className="btn btn-outline-primary" onClick={handleConfirmIgnore}>
        {t('common.ignore')}
      </button>
      <ButtonAsync className="btn btn-primary" onClick={handleAccept}>
        {t('common.accept')}
      </ButtonAsync>
    </>
  )

  const renderActionsContact = () => (
    <>
      <button className="btn btn-outline-primary" onClick={handleConfirmDelete}>
        {t('networking.deleteContact')}
      </button>
      <button className="btn btn-primary" onClick={handleMessage}>
        {t('networking.sendMessage')}
      </button>
    </>
  )

  const renderActionsNewContact = (id: string, name: string) => (
    <button className="btn btn-primary" onClick={() => handleConnect(id, name)}>
      {t('recomendedMembers.list.connect')}
    </button>
  )

  const renderPositions = (list: JobsMember[]) => {
    const jobs = [...list]
    jobs.shift()
    return jobs.map((job: JobsMember) => (
      <p key={job.id}>
        {job.jobTitle} - {job.company}
      </p>
    ))
  }

  return !isLoaded || memberProfile === null ? (
    <Loader />
  ) : (
    <Main ariaLabelledby="profile" className="Profile">
      <div className="Profile__box row">
        <div className="Profile__imageContent">
          <div>
            <div className="Profile__imageContent__image">
              <Avatar
                size="md"
                name={memberProfile?.name || ''}
                surname={memberProfile?.surname || ''}
                imageUrl={memberProfile?.imageUrl || undefined}
              />
            </div>
            <div className="Profile__imageContent__name">
              <h4>
                {memberProfile?.name} {memberProfile?.surname}
              </h4>
            </div>

            <div className="Profile__imageContent__job ">
              {memberProfile?.positions &&
                memberProfile?.positions.length > 0 &&
                `${memberProfile?.positions[0].jobTitle} ${returnHypphen(
                  memberProfile?.positions[0].jobTitle,
                  memberProfile?.positions[0].company
                )} ${memberProfile?.positions[0].company}`}
            </div>
          </div>
          <div>
            {!sameMember && (
              <div className="Profile__imageContent__actions d-none d-md-block">
                {renderProfileActions()}
              </div>
            )}
          </div>
        </div>
        {memberProfile.badges.length > 0 && (
          <div className="Profile__badges">
            <div className="row contentCarousel">
              <Carousel
                elements={memberProfile?.badges}
                type={TypeCard.Badges}
              />
            </div>
          </div>
        )}
        {memberProfile?.positions && memberProfile?.positions.length > 1 && (
          <div className="Profile__jobs">
            <p className="Profile__jobs__title">
              {t('member.list.jobs.others')}
            </p>
            <div className="Profile__jobs__list">
              {renderPositions(memberProfile.positions)}
            </div>
          </div>
        )}
        {!sameMember && (
          <div className="Profile__imageContent__actions d-block d-md-none">
            {renderProfileActions()}
          </div>
        )}
        <div className="Profile__text">
          <HtmlSanitizer
            className="Profile__text__description"
            html={memberProfile?.description || ''}
          />
        </div>
        <hr />
        {memberProfile.channels && memberProfile.channels.length > 0 && (
          <div className="Profile__channels">
            <p className="title">{t('networking.info.text')}</p>
            <p className="channelsTit">
              {t('activities.filter.organizer.channels')}
            </p>
            <div className="row contentCarousel">
              <Carousel
                elements={memberProfile.channels}
                type={TypeCard.Channels}
              />
            </div>
          </div>
        )}
      </div>
      {showModalContact && memberContact && (
        <ContactModal
          contact={memberContact}
          onContact={(message, id) => handleCreateContact(message, id)}
          onClose={() => setShowModalContact(false)}
        />
      )}
    </Main>
  )
}

export default ProfileScreen
