import { matchPath, useLocation } from 'react-router-dom'

type CommunitiesParams = {
  tenantId: string
  basePath: string
  section: string
}

export const tenantWithSection = '/t/:tenantId/:section'
export const tenantWithoutSection = '/t/:tenantId'

function extractParams(pathname: string): CommunitiesParams {
  const hasSection = matchPath({ path: tenantWithSection }, pathname)

  const match = matchPath(
    { path: hasSection ? tenantWithSection : tenantWithoutSection, end: false },
    pathname
  ) ?? {
    params: { tenantId: '', basePath: '', section: '' },
  }

  const { params } = match
  const tenantId = params.tenantId || ''
  const section = params.section || ''
  const basePath = `/t/${tenantId}`

  return {
    tenantId,
    basePath,
    section,
  }
}

export function useCustomRouter(): CommunitiesParams {
  const location = useLocation()
  const communitiesParams = extractParams(location.pathname)

  return communitiesParams
}
