import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { PrivacyPolicy, UsePrivacyPoliciesResult } from './types'
import {
  setList,
  setSignState,
  showLoader,
  hideLoader,
  show,
  hide,
  setIsReadOnly,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const usePrivacyPolicies = (): UsePrivacyPoliciesResult => {
  const dispatch = useAppDispatch()
  const { isVisible, isReadOnly, privacyPolicies, isSigned, isLoaded } =
    useAppSelector((state) => state.privacyPolicies)
  const { get, post } = useFetch()

  const endpoint = 'privacypolicies'

  const showModal = () => {
    dispatch(show())
  }

  const hideModal = () => {
    dispatch(hide())
  }

  const setReadOnly = (readonly: boolean) => {
    dispatch(setIsReadOnly(readonly))
  }

  const fetchPrivacyPolicies = async () => {
    const response: PrivacyPolicy[] | undefined = await get({ endpoint })
    response && dispatch(setList(response))
  }

  const checkPrivacyPolicies = async () => {
    dispatch(showLoader())
    const response: boolean | undefined = await get({
      endpoint: `${endpoint}/check`,
    })
    response && dispatch(setSignState(response))
    dispatch(hideLoader())
  }

  const signPrivacyPolicies = async () => {
    const response: boolean | undefined = await post({
      endpoint: `${endpoint}/sign`,
      body: {},
    })
    response && dispatch(setSignState(response))
  }

  return {
    privacyPolicies,
    isSigned,
    isVisible,
    isReadOnly,
    showModal,
    hideModal,
    setReadOnly,
    fetchPrivacyPolicies,
    checkPrivacyPolicies,
    signPrivacyPolicies,
    isLoaded,
  }
}

export default usePrivacyPolicies
