import { FC } from 'react'
import classNames from 'classnames'

import './index.scss'

export type LogoType = {
  src: string
  alt: string
  className?: string
}

export const LogoImage: FC<LogoType> = ({ src, alt, className }) => {
  return (
    <div className={classNames('logo-image', className)}>
      <img src={src} alt={alt} />
    </div>
  )
}
