import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  FormDate,
  FormTextInput,
  FormProvider,
  TextControl,
  AvatarUploadControl,
  FormRichText,
  CheckboxControl,
} from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import FormActions from '../../../components/FormActions'
import { onboardingStep1ValidationSchema } from './validations'
import { Step1Form } from '../types'
import useUi from '../../../core/ui/useUi'
import useMember from '../../../core/member/useMember'
import { IsoCode } from '../../../core/member/types'

import './styles.scss'

interface Step1Props {
  email?: string
  setShowConfirmModal: (v: boolean) => void
  nextStep: (member: Step1Form) => void
}

const Step1: FC<Step1Props> = ({ email, setShowConfirmModal, nextStep }) => {
  const { t } = useTranslation()
  const { uploadMemberImage, member } = useMember()
  const { showConfirmation } = useUi()
  const [imageUrl, setImage] = useState<string>()
  const [lang, setLang] = useState<IsoCode>()

  const methods = useForm<Step1Form>({
    mode: 'onChange',
    resolver: yupResolver(onboardingStep1ValidationSchema(t)),
    defaultValues: {
      name: '',
      dateOfBirth: '',
      company: '',
      description: '',
      surname: '',
      jobTitle: '',
      imageUrl: '',
      positions: [],
    },
  })

  const {
    control,
    reset,
    getValues,
    watch,
    trigger,
    formState: { errors, isValid },
    handleSubmit,
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'positions',
  })

  const watchJobs = watch('positions')

  const handleCheck = (newValue: boolean, position: number) => {
    const copyJobs = watchJobs
    copyJobs[position].isHighlighted = newValue
    reset({
      ...getValues(),
      positions: copyJobs,
    })
  }

  const onNextStep = async (values: Step1Form) => {
    if (imageUrl) {
      try {
        if (imageUrl.startsWith('blob')) {
          const newImage = await uploadMemberImage(imageUrl)
          values = { ...values, imageUrl: newImage }
        }
        nextStep(values)
      } catch (e) {
        showConfirmation({
          title: t('common.warning'),
          subtitle: t('explicitImage.subtitle'),
          text: t('explicitImage.text'),
          confirmText: t('common.accept'),
          iconName: 'report_problem',
          hideCancel: true,
        })
        return
      }
    } else {
      nextStep({ ...values })
    }
  }

  useEffect(() => {
    setLang(member?.isoCode)
  }, [member?.isoCode])

  useEffect(() => {
    if (member) {
      reset({
        name: member.name,
        surname: member.surname,
        description: member.description,
        dateOfBirth: member.dateOfBirth,
        positions: member.positions,
      })
      setImage(member.imageUrl ?? undefined)
    }
  }, [member])

  const isCheckDisabled = (position: number) => {
    const copyJobs = [...watchJobs]
    if (copyJobs.length > 1) {
      copyJobs.splice(position, 1)
      return copyJobs.some((job) => job.isHighlighted === true)
    }
    return false
  }

  const validateSave = () => {
    const copyJobs = [...watchJobs]
    return copyJobs.some((job) => job.company === '' || job.jobTitle === '')
  }

  const validateCheck = () => {
    const copyJobs = [...watchJobs]
    if (copyJobs.length === 0) {
      return false
    }
    return copyJobs.every((job) => job.isHighlighted === false)
  }

  return (
    <div className="formContainer">
      <FormProvider methods={methods}>
        <div className="row d-block d-md-none">
          <div className="col-12 formContainer__textMobile">
            <span>*</span>
            {t('common.mandatory_fields')}
          </div>
        </div>
        <div className="row">
          <div className="col-9">
            <div className="sectionTitles">{t('onboarding.form.title')}</div>
          </div>
          <div className="col-3 mandatory d-none d-md-block">
            <span>*</span>
            {t('common.mandatory_fields')}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <p>{t('onboarding.form.subtitle')}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-xs-12 col-md-2 mt-3 formContainer__contentImage">
                <AvatarUploadControl
                  title={t('member.edit.image')}
                  label={t('common.addImage')}
                  name="imageUrl"
                  value={imageUrl as string}
                  error={errors.imageUrl}
                  onChange={setImage}
                  required={false}
                  selectFilesSuccess={() => {}}
                  selectFilesError={() => {}}
                />
              </div>
              <div className="col-xs-12 col-md-10">
                <div className="row">
                  <div className="col-xs-12 col-md-6">
                    <FormTextInput
                      control={control}
                      name="name"
                      label={t('member.edit.name.label')}
                      placeholder={t('member.edit.name.placeholder')}
                      type="text"
                      required={true}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <FormTextInput
                      control={control}
                      name="surname"
                      label={t('member.edit.surname.label')}
                      placeholder={t('member.edit.surname.placeholder')}
                      type="text"
                      required={true}
                    />
                  </div>
                </div>
                <div className="row formContainer__contentMargin">
                  <div className="col-xs-12 col-md-6">
                    <TextControl
                      name="email"
                      label={t('member.edit.email')}
                      type="text"
                      value={email ?? ''}
                      onChange={() => {
                        return false
                      }}
                      disabled={true}
                    />
                  </div>
                  <div className="col-xs-12 col-md-6">
                    <FormDate
                      control={control}
                      name="dateOfBirth"
                      label={t('member.edit.dateOfBirth')}
                      max={new Date()}
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row formContainer__contentMargin">
              <div className="col-xs-12 col-md-10 offset-md-2">
                <FormRichText
                  control={control}
                  name="description"
                  placeholder={t('onboarding.description.placeholder')}
                  label={t('onboarding.description.label')}
                  required={true}
                  key={lang}
                />
              </div>
            </div>
            <div className="row formContainer__contentMargin">
              <div className="col-xs-12 col-md-10 offset-md-2">
                <div className="row mt-5">
                  <div className="col-12">
                    <h4>{t('member.list.jobs')}</h4>
                  </div>
                  <div className="col-12">
                    <p>{t('member.list.jobs.text')}</p>
                  </div>
                </div>
                <div
                  className={`row d-none EditMember__leyend ${
                    watchJobs.length === 0 ? 'd-none ' : 'd-sm-block'
                  }`}
                >
                  <p className="textLeyend">{t('member.list.job.default')}</p>
                </div>
                <div className="row EditMember__jobs">
                  {fields.map((item, index) => (
                    <div className="col-12 col-md-11" key={`${item.id}-job`}>
                      <div className="row">
                        <div className="d-flex d-sm-none EditMember__jobs__onlyMobile">
                          <div className="contentText col-6">
                            <p>{t('member.list.job.default')}</p>
                          </div>
                          <div className="contentActions col-6">
                            <CheckboxControl
                              control={control}
                              name={`positions.${index}.isHighlighted`}
                              value={watchJobs[index].isHighlighted || false}
                              key={`${watchJobs}`}
                              onChange={(value) => handleCheck(value, index)}
                            />
                            <button
                              className="non-styled-btn deleteButtonMobile"
                              onClick={() => remove(index)}
                            >
                              <Icon name="delete_outline" />
                            </button>
                          </div>
                        </div>
                        <div className="col-12 col-md-5">
                          <FormTextInput
                            control={control}
                            name={`positions.${index}.jobTitle`}
                            label={t('member.edit.job.label')}
                            placeholder={t('member.edit.job.placeholder')}
                            type="text"
                            onChange={() => trigger('positions')}
                          />
                        </div>
                        <div className="col-12 col-md-5">
                          <FormTextInput
                            control={control}
                            name={`positions.${index}.company`}
                            label={t('member.edit.company.label')}
                            placeholder={t('member.edit.company.placeholder')}
                            type="text"
                            onChange={() => trigger('positions')}
                          />
                        </div>
                        <div className="col-12 d-block d-sm-none">
                          <hr />
                        </div>
                        <div className="contActions col-2 d-none d-sm-block">
                          <div className="row">
                            <div className="col-6">
                              <button
                                className="non-styled-btn deleteButton"
                                onClick={() => remove(index)}
                              >
                                <Icon name="delete_outline" />
                              </button>
                            </div>

                            <div className="col-6">
                              <div className="contCheck">
                                <CheckboxControl
                                  control={control}
                                  name={`positions.${index}.isHighlighted`}
                                  value={watchJobs[index].isHighlighted}
                                  onChange={(value) =>
                                    handleCheck(value, index)
                                  }
                                  disabled={isCheckDisabled(index)}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-11">
                    <button
                      className="moreButton"
                      onClick={() =>
                        append({
                          company: '',
                          jobTitle: '',
                          isHighlighted: false,
                        })
                      }
                    >
                      {t('member.list.job.add')}
                      <Icon name={'add'} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <FormActions>
              <div className="formContainer__actions">
                <button
                  type="button"
                  onClick={() => setShowConfirmModal(true)}
                  className="btn btn-outline-primary"
                >
                  {t('common.cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  disabled={!isValid || validateSave() || validateCheck()}
                  onClick={handleSubmit(onNextStep)}
                >
                  {t('common.continue')}
                </button>
              </div>
            </FormActions>
          </div>
        </div>
      </FormProvider>
    </div>
  )
}

export default Step1
