import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Loader } from '@liveconnect/components'

import { Main } from '../../../components/Main'
import { BrandItem } from './BrandItem'
import { Brand } from '../../../core/brandSpaces/types'
import useMember from '../../../core/member/useMember'
import useBrands from '../../../core/brandSpaces/useBrandSpaces'

import './styles.scss'

const BrandListScreen: FC = () => {
  const { t } = useTranslation()
  const { brandList, fetchBrands, isLoaded } = useBrands()
  const { member } = useMember()
  const navigate = useNavigate()

  const handleClick = (brand: Brand): void => {
    navigate(brand.id)
  }

  useEffect(() => {
    fetchBrands()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main ariaLabelledby="brands" className="brands">
      <h2 className="brands__header">{t('brands.title')}</h2>
      <p className="brands__subtit">
        {t(
          `brands.subtitle${
            member && member.channels.length > 1 ? '.plural' : ''
          }`,
          {
            channels: member?.channels.length ?? '',
          }
        )}
      </p>
      {!isLoaded && brandList.length === 0 ? (
        <Loader />
      ) : (
        <div className="brands__list row">
          {brandList &&
            brandList.map((brand: Brand) => (
              <BrandItem
                key={brand.id}
                brand={brand}
                onBrandClick={handleClick}
              />
            ))}
        </div>
      )}
    </Main>
  )
}

export default BrandListScreen
