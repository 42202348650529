import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { CheckboxControl, MultiSelectControl } from '@liveconnect/components'

import useDirectories from '../../../../core/directories/useDirectories'

import './styles.scss'
import { Channel } from '../../../../core/directories/types'
import { FiltersModal } from '@liveconnect/communities-ui'

interface DirectoryDetailFiltersProps {
  isOpened: boolean
  onClose: () => void
  onSubmit: (params: {
    channelsIds: string[]
    isHighLighted: boolean
    IsNoHighLighted: boolean
  }) => void
}

const DirectoryDetailFilters: FC<DirectoryDetailFiltersProps> = ({
  isOpened,
  onClose,
  onSubmit,
}) => {
  const { getChannelFilterList } = useDirectories()
  const { t } = useTranslation()
  const { id } = useParams()
  const [channelsOptions, setChannelsOptions] = useState<Channel[]>([])
  const filterPanelWrapper = useRef<HTMLDivElement>(
    document.createElement('div')
  )

  const [channelsIds, setChannelsIds] = useState<string[]>([])
  const [isHighLighted, setIsHighLighted] = useState<boolean>(false)
  const [isNotHighLighted, setIsNotHighLighted] = useState<boolean>(false)

  const channelsOptionsParsed = useMemo(() => {
    return channelsOptions.map((channel) => ({
      label: channel.name,
      value: channel.id,
    }))
  }, [channelsOptions])

  const handleChangeChannelsIds = (channelsIds: string[]) => {
    setChannelsIds(channelsIds)
  }

  const handleSubmit = () => {
    onSubmit({
      channelsIds,
      isHighLighted,
      IsNoHighLighted: isNotHighLighted,
    })
  }

  const handleReset = () => {
    setChannelsIds([])
    setIsHighLighted(false)
    setIsNotHighLighted(false)
  }

  const getChannels = async () => {
    if (id) {
      const response = await getChannelFilterList(id)
      if (response) {
        setChannelsOptions(response.channels)
      }
    }
  }

  useEffect(() => {
    const filterPanelElement = document.getElementById('left-panel')
    filterPanelElement?.appendChild(filterPanelWrapper.current)
    getChannels()
  }, [id])

  return (
    <FiltersModal
      isOpened={isOpened}
      onClose={onClose}
      onReset={handleReset}
      onFilter={handleSubmit}
      texts={{
        title: t('directory.filters.title'),
        reset: t('directory.filters.reset'),
        viewResults: t('directory.filters.submit'),
      }}
    >
      <FiltersModal.Section>
        <MultiSelectControl
          name="channelsIds"
          value={channelsIds}
          onChange={handleChangeChannelsIds}
          label={t('directory.filters.channelsIds.label')}
          options={channelsOptionsParsed}
          placeholder={t('directory.filters.channelsIds.placeholder')}
          noOptionsMessage={t('directory.filters.channelsIds.noOptionsMessage')}
        />
      </FiltersModal.Section>

      <FiltersModal.Section title={t('directory.filters.type')}>
        <CheckboxControl
          className="mb-0"
          name="isHighLighted"
          value={isHighLighted}
          onChange={setIsHighLighted}
          label={t('directory.filters.isHighLighted.label')}
        />
        <CheckboxControl
          name="isNotHighLighted"
          value={isNotHighLighted}
          onChange={setIsNotHighLighted}
          label={t('directory.filters.isNotHighLighted.label')}
        />
      </FiltersModal.Section>
    </FiltersModal>
  )
}

export default DirectoryDetailFilters
