import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  DirectoriesState,
  DirectoryListItem,
  MainDirectoryListItem,
} from './types'

const initialState: DirectoriesState = {
  main: [],
  channel: [],
  brandSpace: [],
  isLoaded: false,
}

export const slice = createSlice({
  name: 'directories',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setMain: (state, action: PayloadAction<MainDirectoryListItem[]>) => {
      state.main = action.payload
    },
    setChannel: (state, action: PayloadAction<DirectoryListItem[]>) => {
      state.channel = action.payload
    },
    setBrandSpace: (state, action: PayloadAction<DirectoryListItem[]>) => {
      state.brandSpace = action.payload
    },
  },
})

export const { showLoader, hideLoader, setMain, setChannel, setBrandSpace } =
  slice.actions

export const selectDirectories = (state: RootState) => state.directories

export default slice.reducer
