import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import useAuth, { postLogoutRedirectPathKey } from '../../../core/auth/useAuth'

import { Icon } from '@liveconnect/icons'
import { useParams } from 'react-router-dom'

type CreatePostProps = {
  onClose: () => void
}

const ConfirmModal: FC<CreatePostProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const { tenantId } = useParams()
  const { logout } = useAuth()

  const handleLogout = () => {
    sessionStorage.setItem(postLogoutRedirectPathKey, `/t/${tenantId}`)
    logout()
  }

  return (
    <Modal show={true} onHide={onClose} className="ConfirmationModal">
      <Modal.Header className="justify-content-around">
        <Modal.Title>{t('cancel.modal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12 text-center report_problem ConfirmationModal__icon">
            <Icon name="report_problem" />
          </div>
          <div className="col-12 text-center content">
            <h6 className="title">{t('cancel.modal.subtitle')}</h6>
            <p className="ConfirmationModal__text">{t('cancel.modal.text')}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={() => onClose()}>
          {t('common.no')}
        </button>
        <button className="btn btn-primary" onClick={() => handleLogout()}>
          {t('common.yes')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
