import { FC, useEffect } from 'react'

import Menu from '../Menu'
import HeaderApp from '../Header'
import Footer from '../Footer'
import Snackbar from '../Snackbar'
import SidePanel from '../SidePanel'
import ConfirmationModal from '../ConfirmationModal'
import TermsConditionsModal from '../TermsConditionsModal'
import PrivacyPoliciesModal from '../PrivacyPoliciesModal'

import useDirectories from '../../core/directories/useDirectories'

import ValidateDataMember from './ValidateDataMember'

export const scrollableId = 'scrollable'

import './index.scss'

const Layout: FC = ({ children }) => {
  const { fetchMainDirectories } = useDirectories()

  useEffect(() => {
    fetchMainDirectories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="layout">
      <div className="layout__header">
        <HeaderApp />
        <div className="layout__snackbar">
          <Snackbar />
        </div>
      </div>

      <SidePanel />
      <ConfirmationModal />
      <TermsConditionsModal />
      <PrivacyPoliciesModal />

      <div className="layout__scrollable" id={scrollableId}>
        <div className="layout__content container">
          <div id="left-panel" />
          <div className="layout__nav">
            <Menu />
          </div>
          <div className="layout__main">
            <ValidateDataMember />
            {children}
          </div>
        </div>
        <div className="layout__footer">
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Layout
