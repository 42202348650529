import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useMember from "../../core/member/useMember";
import Chat from "../../components/Chat/ChatComponent/ChatComponent";
import { useTranslation } from "react-i18next";
import { Main } from "../../components/Main"
import  * as ChatType from '../../core/new-chat/types';
import back from '../../../assets/images/back.svg';
import './ChatPage.scss';
import useSignalR from "../../utils/signalR/useSignalR";
import { useChat } from "../../core/new-chat/useChat";
import { mockChats } from "./mockChats";
const ChatPage: React.FC = () => {
    const { t } = useTranslation();
    const { tenantId } = useParams();
    const { member } = useMember();
    const hub = "notificationhub";
    const { initConnection, isConnectionLoaded, connection, connectionId } = useSignalR(hub);
    const { sendMessageToChat, sendMessageToGroup, joinGroup, leaveGroup, sendDirectMessage, connect, getHistory } = useChat(
        tenantId!,
        connectionId || "",
      );

    let [activeChat, setActiveChat] = useState<ChatType.Chat | null>(null); //Chat seleccionado
    const [isMobile, setIsMobile] = useState<boolean>(false); //Booleano para indicar si el ancho de la pantalla es tamaño móvil
    const [chats, setChats] = useState<ChatType.Chat[]>(mockChats);

    const handleSetMessage = (message: string) => {
      if (!activeChat) {
          console.error("No active chat available.");
          return;
      }
  
      // Obtener el último `messageId` y calcular el siguiente id
      const lastMessageId = activeChat.messages.length > 0
          ? Math.max(...activeChat.messages.map((msg) => parseInt(msg.messageId)))
          : 0;
      const newMessageId = (lastMessageId + 1).toString();
  
      // Crear el nuevo mensaje
      const newMessage: ChatType.Message = {
          messageId: newMessageId,
          content: message,
          sentAt: new Date(),
          isDeleted: false,
          sentBy: {
              chatParticipantId: "user7",
              chatId: activeChat.chatId,
              userId: "user7",
              name: "Julián",
              surname: "test",
              jobTitle: "N/A",
              joinedAt: new Date(),
              chatRole: { id: "2", role: "Member" },
          },
      };
  
      setChats((prevChats) => {
          return prevChats.map((chat) =>
              chat.chatId === activeChat.chatId
                  ? {
                        ...chat,
                        messages: [...chat.messages, newMessage],
                    }
                  : chat
          );
      });
  
      setActiveChat((prevChat) =>
          prevChat && prevChat.chatId === activeChat.chatId
              ? {
                    ...prevChat,
                    messages: [...prevChat.messages, newMessage],
                }
              : prevChat
      );
  
  };

    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        const handleResize = () => setIsMobile(mediaQuery.matches);

        handleResize();

        mediaQuery.addEventListener("change", handleResize);

        return () => mediaQuery.removeEventListener("change", handleResize);
    }, []);

    useEffect(() => {
        if (tenantId && member){
           console.log("MemberId :", member.id);
            getHistory();
        }
    }, []);
    
    return (
        <Main ariaLabelledby="chat-title" className="d-flex flex-column chat-page">
            {!isMobile || !activeChat ? (
                <h1 id="chat-title" className="h3 mb-4">
                    {t("chat.title")}
                </h1>
            ) : (
                <button
                    onClick={() => setActiveChat(null)}
                    className="btn btn-back"
                >
                    <img
                        src={back}
                    />
                    {t("chat.title")}
                </button>
            )}

            <div>
                {tenantId && member?.id ? (
                        <Chat 
                            chats={chats}
                            activeChat={activeChat}
                            isMobile={isMobile}
                            setActiveChat={setActiveChat}
                            onSendMessage={handleSetMessage}
                        />
                ) : (
                    <p>Loading...</p>
                )}
            </div>

        </Main>
      
        
    );
};

export default ChatPage;