import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  ActivitiesResult,
  ActivityDetail,
  Topic,
  UseActivitiesResult,
  ActivitiesRequestParams,
  BrandSpace,
  Channel,
  CommentActivityInterface,
  CommentActivityPutInterface,
  TranslateComment,
  CommentPayload,
} from './types'
import { Reactions, ReactionTypeEnum, Comment } from '../posts/types'
import {
  setList,
  showLoader,
  hideLoader,
  setDetail,
  setBrands,
  addComment,
  deleteComment,
  updateComment,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const useActivities = (): UseActivitiesResult => {
  const dispatch = useAppDispatch()
  const { detail, list, brandSpaces, isLoaded } = useAppSelector(
    (state) => state.activities
  )
  const { get, del, post, put } = useFetch()

  const endpoint = 'activities'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const getActivities = async (
    params?: ActivitiesRequestParams
  ): Promise<ActivitiesResult | undefined> => {
    const response: ActivitiesResult | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const parseActivitiesParams = (
    requestParams: ActivitiesRequestParams | undefined
  ) => {
    const params: ActivitiesRequestParams | undefined = {
      search: requestParams?.search,
      brandSpace: requestParams?.brandSpace,
      channels: requestParams?.channels,
      startDate: requestParams?.startDate,
      endDate: requestParams?.endDate,
      types: requestParams?.types,
    }
    return params
  }

  const fetchActivities = async (
    params?: ActivitiesRequestParams | undefined
  ): Promise<void> => {
    await dispatchLoaders(async () => {
      if (params) {
        const reqParams = parseActivitiesParams(params)
        const response: ActivitiesResult | undefined = await getActivities(
          reqParams
        )
        if (response) {
          return dispatch(setList(response))
        }
      }
      const response: ActivitiesResult | undefined = await getActivities()
      if (response) {
        return dispatch(setList(response))
      }
    })
  }

  const fetchBrandSpaces = async (): Promise<void> => {
    await dispatchLoaders(async () => {
      const response: BrandSpace[] | undefined = await get({
        endpoint: `${endpoint}/brandspaces`,
      })
      response && dispatch(setBrands(response))
    })
  }

  const fetchChannels = async (
    brandId?: string
  ): Promise<Channel[] | undefined> => {
    return await dispatchLoaders<Channel[] | undefined>(async () => {
      const response: Channel[] | undefined = await get({
        endpoint: brandId
          ? `${endpoint}/channels?brandId=${brandId}`
          : `${endpoint}/channels`,
      })
      return response
    })
  }

  const fetchActivity = async (
    activityId: string
  ): Promise<ActivityDetail | undefined> => {
    return await dispatchLoaders<ActivityDetail | undefined>(async () => {
      const response: ActivityDetail | undefined = await get({
        endpoint: `${endpoint}/${activityId}`,
      })
      if (response) {
        dispatch(setDetail(response))
      }
      return response
    })
  }

  const createCommentActivity = async (
    activityId: string,
    commentToSave: CommentActivityInterface
  ): Promise<void> => {
    const response: Comment | undefined = await post({
      endpoint: `${endpoint}/${activityId}/comments`,
      body: { ...commentToSave },
    })
    if (response) {
      const params: CommentPayload = {
        activityId: activityId,
        comment: response,
      }
      dispatch(addComment(params))
    }
  }

  const updateCommentActivity = async (
    activityId: string,
    comment: CommentActivityPutInterface
  ): Promise<void> => {
    const response: Comment | undefined = await put<Comment>({
      endpoint: `${endpoint}/${activityId}/comments`,
      body: comment,
    })
    if (response) {
      const params: CommentPayload = {
        activityId: activityId,
        comment: response,
      }
      dispatch(updateComment(params))
    }
  }

  const deleteCommentActivity = async (
    activityId: string,
    commentId: string
  ) => {
    await del({ endpoint: `${endpoint}/${activityId}/comment`, id: commentId })
    dispatch(deleteComment({ activityId, commentId }))
  }

  const translateCommentActivity = async (
    commentId: string,
    activityId: string,
    lang: string
  ): Promise<TranslateComment | undefined> => {
    const response: TranslateComment | undefined = await get({
      endpoint: `${endpoint}/${activityId}/comment/${commentId}/translate`,
      params: {
        to: lang,
      },
    })
    return response
  }

  const updateCommentActivityReaction = async (
    activityId: string,
    commentId: string,
    reaction: ReactionTypeEnum
  ): Promise<Reactions | undefined> => {
    const response: Reactions | undefined = await put({
      endpoint: `${endpoint}/${activityId}/comment/${commentId}/reaction`,
      body: { type: reaction },
    })
    return response
  }

  const subscribeActivity = async (
    activityId: string
  ): Promise<Topic[] | undefined> => {
    return await dispatchLoaders<Topic[] | undefined>(async () => {
      const response: Topic[] | undefined = await post({
        endpoint: `${endpoint}/subscribe`,
        body: { activityId },
      })
      return response
    })
  }

  const unSubscribeActivity = async (activityId: string): Promise<void> => {
    return await dispatchLoaders<void>(async () => {
      await post({
        endpoint: `${endpoint}/unsubscribe`,
        body: { activityId },
      })
    })
  }

  return {
    activity: detail,
    activities: list,
    brandSpaces,
    isLoaded,
    fetchActivities,
    fetchBrandSpaces,
    fetchChannels,
    fetchActivity,
    createCommentActivity,
    updateCommentActivity,
    deleteCommentActivity,
    translateCommentActivity,
    updateCommentActivityReaction,
    subscribeActivity,
    unSubscribeActivity,
  }
}

export default useActivities
