export enum Permission {}
// Comunidades no tiene permisos todavía
// TenantAdmin_Tenant = 'TenantAdmin_Tenant',

export interface PermissionListResponse {
  hasProfile: boolean
  actions: Permission[]
}
export interface PermissionsListPayload {
  list: Permission[]
}

export interface PermissionsState {
  list: Permission[]
  isLoaded: boolean
}

export enum ComplimentType {
  one = 'one',
  all = 'all',
}

export interface UsePermissionsResult {
  list: Array<Permission>
  isLoaded: boolean
  fetchList: () => void
  checkPermissions: (to: Permission[], mustComply?: ComplimentType) => boolean
}
