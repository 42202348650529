import { FC } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loader } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import { Event, EventSummary } from '../../core/events/types'
import { Brand } from '../../core/brandSpaces/types'

import EventBannerItemComponent from './EventBannerItem'

// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination'

import './styles.scss'

interface EventsComponentProps {
  summary: EventSummary | undefined
  isHome?: boolean
  brand?: Brand
}

export const EventsBannerComponent: FC<EventsComponentProps> = ({
  summary,
  isHome = true,
  brand,
}): JSX.Element => {
  const { t } = useTranslation()

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    autoplaySpeed: 5000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    spaceBetween: 16,
    arrows: false,
    pagination: { clickable: true },
    modules: [Pagination],
  }

  return (
    <>
      {!summary ? (
        <Loader />
      ) : (
        <>
          {(summary.events.length > 0 ||
            summary.recommendedEvents.length > 0) && (
            <div
              className={classNames({
                Events: true,
                'Events--Home': isHome,
              })}
            >
              {summary.events.length > 0 && (
                <div className="Events__my-events">
                  {isHome && (
                    <>
                      <h6>{t('events.my-events')}</h6>
                      {summary.events.map((event: Event) => (
                        <EventBannerItemComponent
                          key={event.id}
                          {...event}
                          imageUrl={undefined}
                          withButton={false}
                          isHome={isHome}
                        />
                      ))}
                    </>
                  )}
                  {!isHome && (
                    <>
                      <h6>{t('events.next-events')}</h6>
                      <Swiper
                        {...settings}
                        className={
                          summary.events.length > 1
                            ? 'swiper--has-pagination'
                            : 'swiper--no-pagination'
                        }
                      >
                        {summary.events.map((event: Event) => (
                          <SwiperSlide key={event.id}>
                            <EventBannerItemComponent
                              key={event.id}
                              {...event}
                              imageUrl={event.imageUrl}
                              withButton={true}
                              brand={brand}
                              isHome={isHome}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </>
                  )}
                </div>
              )}
              {summary.recommendedEvents.length > 0 && (
                <div className="Events__recommended-events">
                  <h6>{t('events.recommended-events')}</h6>
                  <Swiper
                    {...settings}
                    className={
                      summary.recommendedEvents.length > 1
                        ? 'swiper--has-pagination'
                        : 'swiper--no-pagination'
                    }
                  >
                    {summary.recommendedEvents.map((event: Event) => (
                      <SwiperSlide key={event.id}>
                        <EventBannerItemComponent
                          key={event.id}
                          {...event}
                          withButton={false}
                          isHome={isHome}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              )}
              <div className="contentLink">
                <a
                  className="btn btn-link"
                  href={summary.eventsUrl}
                  title={summary.eventsUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('events.external-link')}
                  <Icon name="keyboard_arrow_right" />
                </a>
              </div>
            </div>
          )}
        </>
      )}
    </>
  )
}
