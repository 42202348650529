import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { TabsComponent } from '@liveconnect/communities-ui'
import { Icon } from '@liveconnect/icons'

import useSettings from '../../core/settings/useSettings'
import { Main } from '../../components/Main'
import { useCustomRouter } from '../../utils/useCustomRouter'
import Recommended from './Recommended'
import Networking from './Networking'
import AdSlot from '../../containers/AdSlot'
import useIsMobile from '../../utils/useIsMobile'

import './styles.scss'

const PeopleScreen: FC = () => {
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const isMobile = useIsMobile()
  const { settings } = useSettings()

  const tabs = [
    {
      id: 'recommended',
      label: t('recomendedMembers.list.title'),
      component: <Recommended />,
    },
    {
      id: 'network',
      label: t('networking.title'),
      component: <Networking />,
    },
  ]

  return (
    <Main ariaLabelledby="user-list-title" className="UserList">
      <div className="row">
        <div className="col-xs-12 col-lg-9">
          <div className="UserList__header">
            <div>
              <h1 id="user-list-title" className="h3">
                {t('people.title')}
              </h1>
              <p>{t('recomendedMembers.list.hint')}</p>
            </div>
            <div className="UserList__header__link">
              <Link className="btn btn-link" to={`${basePath}/contact-requests`}>
                <span>{t('contacts.externalLink')}</span>
                <Icon name="keyboard_arrow_right" />
              </Link>
            </div>
          </div>
          <TabsComponent tabs={tabs} />
        </div>
        <div className="col-lg-3 d-none d-lg-block">
          {!isMobile && (
            <AdSlot
              context={settings?.environment}
              section="comunity365Networking"
              container="sideBarRight"
              device="desktop"
              id="peopleDesktop"
            />
          )}
        </div>
      </div>
    </Main>
  )
}

export default PeopleScreen
