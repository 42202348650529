import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'

import { Main } from '../../../components/Main'
import { ApplicationLogo } from '../../../containers/Header/ApplicationLogo'

import './styles.scss'

export const DeletedUserPage = () => {
  const { t } = useTranslation()

  return (
    <Main ariaLabelledby="deleted-user-page">
      <div className="lc-error-page">
        <div className="lc-error-page__logo">
          <ApplicationLogo />
        </div>
        <div className="lc-error-page__content">
          <div className="lc-error-page__icon">
            <Icon
              className="icon--xl icon--success"
              name="check_circle_outline"
            />
          </div>
          <h1 id="page-error-title" className="h4">
            {t('deletedUserPage.title')}
          </h1>
          <p className="mb-3">{t('deletedUserPage.subtitle')}</p>
        </div>
      </div>
    </Main>
  )
}
