import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useCustomRouter } from '../../utils/useCustomRouter'
import useGTM from '../../utils/useGTM'

const VirtualPageView = () => {
  const location = useLocation()
  const { section } = useCustomRouter()
  const { pushDataLayer } = useGTM()

  useEffect(() => {
    const lastPageView = [...(window.dataLayer || [])]
      .reverse()
      .find((item) => item.event === 'virtual_pageview')
    if (!lastPageView || lastPageView.page_url !== document.location.href) {
      pushDataLayer({
        event: 'virtual_pageview',
        category: 'navigation',
        extraParams: {
          page_url: document.location.href,
          page_section: section,
        },
      })
    }
  }, [location, pushDataLayer])

  return null
}

export default VirtualPageView
