import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Icon } from '@liveconnect/icons'
import { ButtonAsync } from '@liveconnect/components'

import useUi from '../../core/ui/useUi'

import './styles.scss'

const ConfirmationModal: FC = () => {
  const { t } = useTranslation()
  const {
    confirmation: {
      isVisible,
      title,
      subtitle,
      text,
      cancelText,
      confirmText,
      hideCancel,
      iconName,
      onCancel,
      onConfirm,
    },
    hideConfirmation,
  } = useUi()

  const handleCancel = () => {
    onCancel && onCancel()
    hideConfirmation()
  }

  const handleConfirm = async () => {
    onConfirm && (await onConfirm())
    hideConfirmation()
  }

  return (
    <Modal
      show={isVisible}
      onHide={hideConfirmation}
      className="ConfirmationModal"
      backdrop="static"
      backdropClassName="ConfirmationBackdrop"
    >
      <Modal.Header className="justify-content-around">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {iconName && (
            <div
              className={`col-12 text-center ${iconName} ConfirmationModal__icon`}
            >
              <Icon name={iconName} />
            </div>
          )}
          <div className="col-12 text-center content">
            <h6>{subtitle}</h6>
            <p className="ConfirmationModal__text">{text}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {!hideCancel && (
          <button className="btn btn-outline-primary" onClick={handleCancel}>
            {cancelText || t('common.cancel')}
          </button>
        )}
        <ButtonAsync
          className={classNames('btn btn-primary', {
            'ConfirmationModal__unique-button': hideCancel,
          })}
          onClick={handleConfirm}
        >
          {confirmText || t('common.accept')}
        </ButtonAsync>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationModal
