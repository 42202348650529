import { FC } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import SlidePanel from '../../components/SlidePanel'
import useUi from '../../core/ui/useUi'
import { useCustomRouter } from '../../utils/useCustomRouter'
import { navbarItems } from '../Menu/items'

import useDirectories from '../../core/directories/useDirectories'

import './styles.scss'

const SidePanel: FC = () => {
  const { t } = useTranslation()
  const {
    isPanelVisibleRight,
    toggleShowPanelRight,
    isPanelVisibleLeft,
    toggleShowPanelLeft,
  } = useUi()
  const { basePath } = useCustomRouter()
  const { main } = useDirectories()

  const isActiveLink = (isActive: boolean) => {
    return isActive ? 'active SidePanel__item' : 'SidePanel__item'
  }

  return (
    <>
      <SlidePanel opened={isPanelVisibleRight} close={toggleShowPanelRight}>
        <ul className="SidePanel">
          <li aria-hidden="true" onClick={toggleShowPanelRight}>
            <NavLink
              className={({ isActive }) => isActiveLink(isActive)}
              to={`${basePath}/member`}
            >
              <span className="SidePanel__text">{t('common.my_profile')}</span>
            </NavLink>
          </li>
          <li
            aria-hidden="true"
            onClick={toggleShowPanelRight}
            className="d-block d-sm-none"
          >
            <NavLink
              className={({ isActive }) => isActiveLink(isActive)}
              to={`${basePath}/events`}
            >
              <span className="SidePanel__text">{t('events.my-events')}</span>
            </NavLink>
          </li>
          <li aria-hidden="true" onClick={toggleShowPanelRight}>
            <NavLink
              className={({ isActive }) => isActiveLink(isActive)}
              to={`${basePath}/configuration`}
            >
              <span className="SidePanel__text">
                {t('common.configuration')}
              </span>
            </NavLink>
          </li>
          <li aria-hidden="true" onClick={toggleShowPanelRight}>
            <NavLink
              className={({ isActive }) => isActiveLink(isActive)}
              to="/logout"
            >
              <span className="SidePanel__text">{t('common.logout')}</span>
            </NavLink>
          </li>
        </ul>
      </SlidePanel>
      <SlidePanel
        openLeft={true}
        opened={isPanelVisibleLeft}
        close={toggleShowPanelLeft}
        alignment="left"
      >
        <ul className="SidePanel SidePanelLeft">
          {navbarItems.map((item, index) => {
            return (
              <li key={index} onClick={toggleShowPanelLeft} aria-hidden="true">
                <NavLink
                  className="SidePanel__item SidePanelLeft__item"
                  to={`${basePath}${item.path}`}
                >
                  <span className="SidePanel__text SidePanelLeft__text">
                    {t(item.trnsKey)}
                  </span>
                </NavLink>
              </li>
            )
          })}
          {main.map((item, index) => (
            <li key={index} onClick={toggleShowPanelLeft} aria-hidden="true">
              <NavLink
                className="SidePanel__item SidePanelLeft__item"
                to={`${basePath}/directories/${item.id}`}
              >
                <span className="SidePanel__text SidePanelLeft__text">
                  {item.name}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </SlidePanel>
    </>
  )
}

export default SidePanel
