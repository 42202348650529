import { Icon } from '@liveconnect/icons'
import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import './styles.scss'

interface BoxTextProps {
  message: string
}

const BoxText: FC<BoxTextProps> = ({ message }) => {
  const { t } = useTranslation()
  const textContainerRef = useRef<HTMLDivElement>(null)
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showExpand, setShowExpand] = useState<boolean>(false)
  const [showExpandMobile, setShowExpandMobile] = useState<boolean>(false)

  useEffect(() => {
    if (textContainerRef.current === null) return
    const containerStyles = window.getComputedStyle(textContainerRef.current)
    const containerHeight = parseInt(containerStyles.height.replace('px', ''))
    if (containerHeight >= 24) {
      if (message.length > 36) {
        setShowExpandMobile(true)
      }
      if (message.length > 113) {
        setShowExpand(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="BoxText">
      <div className="empty d-none d-md-flex"></div>
      <div className="BoxText__content">
        <div>
          <div
            ref={textContainerRef}
            className={
              showComplete
                ? 'BoxText__content__textContainer showComplete'
                : 'BoxText__content__textContainer'
            }
          >
            {message}
          </div>
        </div>
        {showExpand && (
          <div className="BoxText__content__containerLink d-none d-md-flex">
            <button
              className="link"
              onClick={() => setShowComplete(!showComplete)}
            >
              {showComplete ? t('post.view_less') : t('post.view_more')}
              <Icon
                name={
                  showComplete ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                }
              />
            </button>
          </div>
        )}
        {showExpandMobile && (
          <div className="BoxText__content__containerLink d-flex d-md-none">
            <button
              className="link"
              onClick={() => setShowComplete(!showComplete)}
            >
              {showComplete ? t('post.view_less') : t('post.view_more')}
              <Icon
                name={
                  showComplete ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                }
              />
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default BoxText
