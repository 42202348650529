import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactRequestsState, ContactRequestListPayload } from './types'

const initialState: ContactRequestsState = {
  list: [],
  page: 1,
  pageSize: 6,
  availablePages: 1,
  availableItems: 0,
  isLoaded: false,
}

export const slice = createSlice({
  name: 'contactRequests',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<ContactRequestListPayload>) => {
      state.list = action.payload.list
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.availableItems = action.payload.availableItems
    },
  },
})

export const { showLoader, hideLoader, clearList, setList } = slice.actions

export default slice.reducer
