import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  FormCheckbox,
  FormProvider,
  Tooltip,
  Loader,
} from '@liveconnect/components'

import { Main } from '../../components/Main'
import useTopics from '../../core/topicFamilies/useTopicFamilies'
import useMember from '../../core/member/useMember'
import { Topic } from '../../core/topicFamilies/types'
import { MemberChannel } from '../../core/member/types'
import { useCustomRouter } from '../../utils/useCustomRouter'
import FormActions from '../../components/FormActions'
import useSnackBars from '../../utils/notifications/useNotifications'
import { useBlockRouteChangeWithDialog } from '../../utils/routing/useBlockRouteChange'
import useGTM from '../../utils/useGTM'

import topicPlaceholder from '../../../assets/images/topic-placeholder2.png'

import './styles.scss'

const ChannelsScreen: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useSnackBars()
  const { basePath } = useCustomRouter()
  const { getTopics, isLoaded } = useTopics()
  const { member, updateMemberChannels, fetchMember } = useMember()
  const [topics, setTopics] = useState<Topic[]>()
  const [withChannels, setWithChannels] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { pushDataLayer } = useGTM()

  const methods = useForm({
    mode: 'onChange',
  })

  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty },
    setValue,
    watch,
    reset,
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const dataSelects = watch()

  const fetchTopics = async () => {
    const response = await getTopics()
    if (response) {
      setTopics(response)
    }
  }

  const setSelectedChannels = async () => {
    member?.channels?.forEach((channel: MemberChannel) => {
      setValue(channel.id, true)
    })
  }

  const handleCancel = () => navigate(basePath)

  const onSubmit = async (values: Record<string, boolean>) => {
    setIsSubmitting(true)
    try {
      const channelsToSend: string[] = Object.keys(values).filter(
        (key) => values[key]
      )

      await updateMemberChannels(channelsToSend)
      pushDataLayer({
        event: 'update_channel_community',
        category: 'interaction',
        action: 'update_channel',
      })
      reset()
      notify.success(t('channels.list.succeessFeedback'))
      await fetchMember()

      navigate(basePath)
    } catch (e) {
      notify.error(t('channels.list.unknownError'))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    fetchTopics()
    setSelectedChannels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const values = Object.values(dataSelects)
    setWithChannels(values.some((item) => item === true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelects])

  return (
    <Main className="Channels" ariaLabelledby="channels">
      <div className="Channels__header">
        <h3 id="list-topics-title" className="Channels__header__title">
          {t('channels.list.title')}
        </h3>
        <p className="Channels__header__subtitle">{t('channels.list.hint')}</p>
      </div>
      <section className="Channels__form">
        <FormProvider methods={methods}>
          {!isLoaded ? (
            <Loader />
          ) : (
            <div className="Channels__form__list row">
              {topics?.map((topic, index) => (
                <div className="Topic-family col-12 col-lg-2" key={index}>
                  <img
                    src={topic.imageUrl || topicPlaceholder}
                    alt={topic.name}
                  />
                  <div className="Topic-family__content">
                    <div className="Topic-family__content__title">
                      {topic.name}
                    </div>
                    <div className="Topic-family__content__channels">
                      {(topic.channels || []).map((channel) => (
                        <Tooltip
                          content={channel.name}
                          key={channel.id}
                          position="left"
                        >
                          <FormCheckbox
                            control={control}
                            name={channel.id}
                            label={channel.name}
                          />
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <FormActions>
            <div className="Channels__buttons">
              <button
                className="btn btn-outline-primary"
                disabled={!isValid || isSubmitting}
                onClick={handleCancel}
              >
                {t('common.cancel')}
              </button>
              <button
                className="btn btn-primary"
                disabled={!isValid || isSubmitting || !withChannels || !isDirty}
                onClick={handleSubmit(onSubmit)}
              >
                {isSubmitting ? <Loader /> : t('common.save')}
              </button>
            </div>
          </FormActions>
        </FormProvider>
      </section>
    </Main>
  )
}

export default ChannelsScreen
