import React, { useRef, useState } from 'react';
import './ChatWindow.scss';

import send from '../../../../assets/images/send.svg';
import MessagesList from '../MessagesList/MessagesList';
import { Chat } from '../../../core/new-chat/types';
import { Avatar } from '@liveconnect/components';
import useMember from '../../../core/member/useMember';

interface ChatWindowProps {
  chat : Chat | null;
  onSendMessage: (message : string) => void;
}

type Message = {
  id: string;
  text: string;
  timestamp: Date;
  isMine: boolean;
  senderAvatar?: string;
};

const ChatWindow : React.FC<ChatWindowProps>= ({ chat,  onSendMessage }) => {
  const { member } = useMember();
    // const myId = member?.id;
    const myId = 'user7';

    const inputRef = useRef<HTMLInputElement | null>(null);

  if (!chat) {

    return <div className="chat-window" />;
  }

  let participantName = "";
  let participantSurname = "";
  let participantImageUrl = "";

  //TODO Comprobar también el tipo de chat y no solo el nombre
  if (chat.participants.length === 2) {
    const otherParticipant = chat.participants.find(participant => participant.userId !== myId);

    if (otherParticipant) {
      const { name, surname, imageUrl } = otherParticipant;
      participantName = name;
      participantSurname = surname;
      participantImageUrl = imageUrl || "";
      chat.name = `${name} ${surname}`;
    }
  }

  const handleSendMessage = () => {
    if (inputRef.current?.value.trim()) {
      onSendMessage(inputRef.current?.value);
      inputRef.current.value = ''; // Clear the input field
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSendMessage(); // Trigger the message sending on Enter
    }
  };

  return (
    <div className="chat-window">
      <div className='chat-window__header'>
        {/* TODO IMG */}
        <Avatar name={participantName} surname={participantSurname} imageUrl={participantImageUrl} size="lg" />
        <div className="chat-window__header__title">{chat?.name}</div>
      </div>
      <div className="chat-window__messages-container">
        <MessagesList messages={chat.messages} />
      </div>
      <div className="chat-window__input-container">
        <input type="text" placeholder="Escribe un mensaje"
          ref={inputRef} 
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSendMessage}>
        <img
                src={send}
              />
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
