import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import BrandScreen from '../pages/Brand'
import BrandListScreen from '../pages/Brand/BrandList'
import SponsorsScreen from '../pages/Sponsors'

const BrandsRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<BrandListScreen />} />
      <Route path=":brandId" element={<BrandScreen />} />
      <Route path=":brandId/sponsors" element={<SponsorsScreen />} />
    </Routes>
  )
}

export default BrandsRouter
