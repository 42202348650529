import { FC, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { ButtonAsync } from '@liveconnect/components'
import { Icon } from '@liveconnect/icons'

import useAuth from '../../../../core/auth/useAuth'
import useMember from '../../../../core/member/useMember'

import './styles.scss'

interface ConfirmationDeleteModalProps {
  isVisible: boolean
  onHide: () => void
}

enum Type {
  Community = 'Community',
  Platform = 'Platform',
}

export const ConfirmationDeleteModal: FC<ConfirmationDeleteModalProps> = ({
  isVisible,
  onHide,
}) => {
  const { t } = useTranslation()
  const { logout } = useAuth()
  const { anonimizeMember, member } = useMember()
  const [type, setType] = useState<Type | undefined>(Type.Community)

  const handleConfirm = async () => {
    try {
      if (!type || !member || !member.id) return
      await anonimizeMember(member.id)
      setType(undefined)
      onHide()
      await logout('/deleted-user')
    } catch (e) {}
  }

  const handleHide = () => {
    setType(undefined)
    onHide()
  }

  return (
    <Modal
      show={isVisible}
      onHide={handleHide}
      className="ConfirmationModal ConfirmationModal--delete"
      backdrop="static"
      backdropClassName="ConfirmationBackdrop"
    >
      <Modal.Header>
        <Modal.Title>{t('member.edit.modalDelete.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center report_problem ConfirmationModal__icon">
          <Icon name="report_problem" />
        </div>
        <div className="content">
          <h6 className="text-center">
            {t('member.edit.modalDelete.subtitle')}
          </h6>
          {/* <RadioControl
            name="type"
            value={Type.Community}
            onChange={(v) => {
              setType(v as Type)
            }}
            label={t('member.edit.modalDelete.community.label')}
          /> */}
          {type === Type.Community && (
            <div className="mb-4">
              <p className="confirmation-delete-modal-subtitle">
                {t('member.edit.modalDelete.community.title')}
              </p>
              <ul className="confirmation-delete-modal-list">
                <li>
                  <Icon name="report_problem" />{' '}
                  <span>{t('member.edit.modalDelete.community.textOne')}</span>
                </li>
                <li>
                  <Icon name="report_problem" />{' '}
                  <span>{t('member.edit.modalDelete.community.textTwo')}</span>
                </li>
                <li>
                  <Icon name="report_problem" />{' '}
                  <span>
                    {t('member.edit.modalDelete.community.textThree')}
                  </span>
                </li>
              </ul>
            </div>
          )}
          {/* <RadioControl
            name="type"
            value={Type.Platform}
            onChange={(v) => {
              setType(v as Type)
            }}
            label={t('member.edit.modalDelete.platform.label')}
          />
          {type === Type.Platform && (
            <div className="mb-4">
              <p className="confirmation-delete-modal-subtitle">
                {t('member.edit.modalDelete.platform.title')}
              </p>
              <ul className="confirmation-delete-modal-list">
                <li>
                  <Icon name="report_problem" />{' '}
                  <span>{t('member.edit.modalDelete.platform.textOne')}</span>
                </li>
                <li>
                  <Icon name="report_problem" />{' '}
                  <span>{t('member.edit.modalDelete.platform.textTwo')}</span>
                </li>
                <li>
                  <Icon name="report_problem" />{' '}
                  <span>{t('member.edit.modalDelete.platform.textThree')}</span>
                </li>
              </ul>
            </div>
          )} */}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={handleHide}>
          {t('common.cancel')}
        </button>
        <ButtonAsync
          disabled={!type}
          className="btn btn-primary"
          onClick={handleConfirm}
        >
          {t('member.edit.modalDelete.confirm')}
        </ButtonAsync>
      </Modal.Footer>
    </Modal>
  )
}
