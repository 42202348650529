import { FC } from 'react'
import { Avatar } from '@liveconnect/components'

import { BadgeCardProps } from './types'

import './styles.scss'

const BadgeCard: FC<BadgeCardProps> = ({ title, image }) => {
  return (
    <div className="badgeCard">
      <Avatar size="md" name={''} surname={''} imageUrl={image || undefined} />
      <p>{title}</p>
    </div>
  )
}

export default BadgeCard
