import { Comment, Reactions, ReactionTypeEnum } from '../posts/types'

export interface CommentActivityInterface {
  message: string
}
export interface CommentPayload {
  activityId: string
  comment: Comment | undefined
}
export interface DeleteCommentActivityPayload {
  activityId: string
  commentId: string
}
export interface CommentActivityPutInterface {
  id: string
  message: string
}

export interface TranslateComment {
  translateContent: string
}

export interface Topic {
  id: string
  name: string
  channels: Array<Channel>
  imageUrl: string
}

export interface BrandSpace {
  id: string
  name: string
  code: string
}

export interface Channel {
  id: string
  name: string
}

export interface Contact {
  imageUrl: string | null
  name: string
  surname: string
  id: string
}
export interface Attendees {
  max: number
  count: number
  hasMaxAttendees: boolean
  contacts: Contact[]
}
export interface Address {
  fullAddress: string
  mapUrl: string
}
export interface ActivityDetail {
  id: string
  title: string
  type: TypeActivity
  startDate: string
  endDate: string
  imageUrl: string
  brandLogoImageUrl: string
  description: string
  language: string
  comments: Comment[]
  attendees: Attendees
  isRegistered: boolean
  video: string
  brandSpaceId: string
  address: Address
  challengeTypeFile: string
  challengeTypeFileName: string
  challengeTypeUrl: string
  contributorInfo: ContributorInfo | null
  challengeResult: ChallengeResult | null
}

export interface ContributorInfo {
  contributorType: string
  contributors: {
    name: string
    company: string
    image: string
    description: string
  }[]
}

export interface ChallengeResult {
  image: string
  title: string
  description: string
}

export interface ActivitiesState {
  isLoaded: boolean
  list: ActivitiesResult
  detail: ActivityDetail | null
  brandSpaces: BrandSpace[]
}
export interface ActivitiesResult {
  activities: Activity[]
  recommendedActivities: Activity[]
  interestingActivities: Activity[]
  search: string | undefined
}
export interface Activity {
  id: string
  title: string
  type: TypeActivity
  startDate: string
  endDate: string
  showDate: string
  imageUrl: string
  brandLogoImageUrl: string
  isSponsored: boolean
}
export enum TypeActivity {
  Video = 'VideoConference',
  Debate = 'Debate',
  Challenge = 'Challenge',
  FaceToFace = 'FaceToFace',
  RoundTable = 'RoundTable',
  AskAuthor = 'AskAuthor',
}

export interface ActivitiesRequestParams {
  search?: string
  brandSpace?: string
  channels?: string[]
  startDate?: string
  endDate?: string
  types?: TypeActivity[]
}

export interface UseActivitiesResult {
  activity: ActivityDetail | null
  activities: ActivitiesResult
  brandSpaces: BrandSpace[]
  isLoaded: boolean
  fetchActivities: (
    params?: ActivitiesRequestParams | undefined
  ) => Promise<void>
  fetchActivity: (activityId: string) => void
  fetchBrandSpaces: () => Promise<void>
  fetchChannels: (brandId?: string) => Promise<Channel[] | undefined>
  createCommentActivity: (
    activityId: string,
    commentToSave: CommentActivityInterface
  ) => Promise<void>
  updateCommentActivity: (
    activityId: string,
    body: CommentActivityPutInterface
  ) => Promise<void>
  deleteCommentActivity: (activityId: string, id: string) => Promise<void>
  translateCommentActivity: (
    commentId: string,
    activityId: string,
    lang: string
  ) => Promise<TranslateComment | undefined>
  updateCommentActivityReaction: (
    postId: string,
    commentId: string,
    reaction: ReactionTypeEnum
  ) => Promise<Reactions | undefined>
  subscribeActivity: (activityId: string) => Promise<Topic[] | undefined>
  unSubscribeActivity: (activityId: string) => Promise<void>
}
