import { useLayoutEffect, useState } from 'react'
import debounce from 'lodash/debounce'

const MOBILE_SIZE = 768

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(false)

  const updateSize = (): void => {
    setIsMobile(window.innerWidth < MOBILE_SIZE)
  }

  useLayoutEffect(() => {
    updateSize()
    window.addEventListener('resize', debounce(updateSize, 250))
    return (): void => window.removeEventListener('resize', updateSize)
  }, [])

  return isMobile
}

export default useIsMobile
