import * as signalR from "@microsoft/signalr";

type ConnectionMap = Map<string, signalR.HubConnection>;

const connectionMap: ConnectionMap = new Map<string, signalR.HubConnection>();

const initializeConnection = async (
  hubUrl: string,
  token: string
): Promise<void> => {
  if (connectionMap.has(hubUrl)) {
    return;
  }

  const connection = new signalR.HubConnectionBuilder()
    .withUrl(hubUrl, {
      accessTokenFactory: () => token,
    })
    .withAutomaticReconnect()
    .build();

  connectionMap.set(hubUrl, connection);
  await connection.start();  // Esperamos hasta que la conexión esté lista
};

const getConnection = (hubUrl: string): signalR.HubConnection | null => {
  return connectionMap.get(hubUrl) || null;
};

const getConnectionId = (hubUrl: string): string | null => {
  const connection = connectionMap.get(hubUrl);
  return connection && connection.state === signalR.HubConnectionState.Connected
    ? connection.connectionId
    : null;  // Solo devolver connectionId si está conectado
};

const SignalRSingleton = {
  initializeConnection,
  getConnection,
  getConnectionId,
};

export default SignalRSingleton;
