import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { HtmlSanitizer } from '@liveconnect/communities-ui'
import { Avatar } from '@liveconnect/components'

import { Main } from '../../components/Main'
import useMember from '../../core/member/useMember'
import Carousel from '../../components/Carousel'
import { TypeCard } from '../../components/Carousel/types'
import IconButton from '../../components/Buttons/IconButton'
import { JobsMember } from '../../core/member/types'
import { returnHypphen } from '../../utils/showHyppen/showHyppen'

import './styles.scss'

const MemberScreen: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { member, fetchMember, validateDataMember } = useMember()

  const handleNavigate = () => {
    navigate('edit')
  }

  const renderPositions = (list: JobsMember[]) => {
    const jobs = [...list]
    jobs.shift()
    return jobs.map((job: JobsMember) => (
      <p key={job.id}>
        {job.jobTitle} - {job.company}
      </p>
    ))
  }

  const getData = async () => {
    await fetchMember()
    if (member && member.id && member.changeToValidate) {
      validateDataMember(member.id)
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <Main ariaLabelledby="member" className="Member">
      <div className="Member__box">
        <div className="Member__imageContent">
          <div>
            <div className="Member__imageContent__image">
              <Avatar
                size="md"
                name={member?.name || ''}
                surname={member?.surname || ''}
                imageUrl={member?.imageUrl || undefined}
              />
            </div>
            <div className="Member__imageContent__name">
              <h4>
                {member?.name} {member?.surname}
              </h4>
            </div>

            <div className="Member__imageContent__job ">
              {member?.positions &&
                member?.positions.length > 0 &&
                `${member?.positions[0].jobTitle} ${returnHypphen(
                  member?.positions[0].jobTitle,
                  member?.positions[0].company
                )} ${member?.positions[0].company}`}
            </div>
          </div>
          <div>
            <div className="Member__imageContent__actions">
              <IconButton icon="edit" onClick={handleNavigate} />
            </div>
          </div>
        </div>
        {member && member.badges && member.badges.length > 0 && (
          <div className="Member__badges">
            <div className="row contentCarousel">
              <Carousel elements={member?.badges} type={TypeCard.Badges} />
            </div>
          </div>
        )}
        {member?.positions && member?.positions.length > 1 && (
          <div className="Member__jobs">
            <p className="Member__jobs__title">
              {t('member.list.jobs.others')}
            </p>
            <div className="Member__jobs__list">
              {renderPositions(member.positions)}
            </div>
          </div>
        )}
        <div className="Member__text">
          <p className="Member__text__title">
            {t('onboarding.description.label')}
          </p>
          <HtmlSanitizer
            className="Member__text__description"
            html={member?.description || ''}
          />
        </div>
        <hr />
        {member && member.channels && member.channels.length > 0 && (
          <div className="Member__channels">
            <p className="title">{t('networking.info.text')}</p>
            <p className="channelsTit">
              {t('activities.filter.organizer.channels')}
            </p>
            <div className="contentCarousel">
              <div className="row carousel">
                <Carousel elements={member.channels} type={TypeCard.Channels} />
              </div>
            </div>
          </div>
        )}
      </div>
    </Main>
  )
}

export default MemberScreen
