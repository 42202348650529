import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormTextInput, FormProvider } from '@liveconnect/components'
import { yupResolver } from '@hookform/resolvers/yup'

import { buildValidationSchema } from './validations'

import './styles.scss'

interface VideoUploadProps {
  onClose: () => void
  onSetVideo: (video: string) => void
}

interface VideoUploadInterface {
  video: string
}

const VideoUpload: FC<VideoUploadProps> = ({ onClose, onSetVideo }) => {
  const { t } = useTranslation()
  const methods = useForm<VideoUploadInterface>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: { video: '' },
  })
  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = methods

  const onSubmit = ({ video }: VideoUploadInterface) => {
    onSetVideo(video)
  }

  return (
    <Modal
      show={true}
      backdrop="static"
      onHide={onClose}
      className="VideoUpload"
      backdropClassName="VideoUploadBackdrop"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('post.videoUpload.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormProvider methods={methods}>
          <FormTextInput
            control={control}
            name="video"
            label={t('post.videoUpload.label')}
            placeholder={t('post.videoUpload.placeholder')}
            type="text"
            required={true}
          />
        </FormProvider>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-outline-primary" onClick={onClose}>
          {t('common.cancel')}
        </button>
        <button
          className="btn btn-primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          {t('common.add')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default VideoUpload
