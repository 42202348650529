import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSettings from "../../core/settings/useSettings";
import SignalRSingleton from "./singleton";
import { useSignalRResult } from "./types";

const useSignalR = (hub: string): useSignalRResult => {
  const { tenantId } = useParams();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [connectionId, setConnectionId] = useState<string | null>(null);  // Estado para almacenar el connectionId
  const { settings } = useSettings();
  const base = settings?.bff?.url;
  const url = `${base!.split('/api/v1')[0]}/${hub}`;

  const initConnection = async () => {
    try {
      const token = localStorage.getItem(`ifema_communities_web_access_token${tenantId}`) as string;
      await SignalRSingleton.initializeConnection(url, token);  // Espera a que se inicialice la conexión
      
      const connectionId = SignalRSingleton.getConnectionId(url);  // Obtiene el connectionId
      if (connectionId) {
        setConnectionId(connectionId);  // Actualiza el estado con el connectionId
      }
      setIsLoaded(true);  // Solo se marca como cargado cuando la conexión se ha inicializado
    } catch (e) {
      console.error('Error initializing SignalR connection: ', e);
    }
  };

  useEffect(() => {
    if (!isLoaded) {  // Solo intenta inicializar la conexión si no está cargada aún
      initConnection();
    }
  }, [isLoaded, tenantId]);

  return {
    isConnectionLoaded: isLoaded,
    connection: SignalRSingleton.getConnection(url),
    connectionId,
    initConnection,
    closeConnection: () => SignalRSingleton.getConnection(url)?.stop(),
  };
};

export default useSignalR;
