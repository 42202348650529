import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import mapValues from 'lodash/mapValues'

export function onboardingStep2ValidationSchema(
  t: TFunction
): yup.AnyObjectSchema {
  return yup
    .object()
    .shape({
      channels: yup.lazy((obj) =>
        yup
          .object(mapValues(obj, () => yup.boolean()))
          .test('testName', 'errorMessage', (obj) => {
            return (
              Boolean(obj) && Object.keys(obj).some((key) => obj[key] === true)
            )
          })
      ),
    })
    .required()
}
