import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import ReactPlayer from 'react-player'

import { Icon } from '@liveconnect/icons'
import { Loader } from '@liveconnect/components'

import useDirectories from '../../../core/directories/useDirectories'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import useIsMobile from '../../../utils/useIsMobile'

import useElements from '../../../core/elements/useElements'
import { Main } from '../../../components/Main'
import VerticalCard from '../../../components/VerticalCard'
import MultimediaCarrousel from '../../../components/MultimediaCarrousel'
import AdSlot from '../../../containers/AdSlot'
import { FetchError } from '../../../utils/fetch/types'

import './styles.scss'

const ElementDetailPage = () => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { getDirectoryNameAndDescription } = useDirectories()
  const { fetchElement, isLoaded, detail } = useElements()
  const [isMediaCarrouselVisible, setIsMediaCarrouselVisible] =
    useState<boolean>(false)
  const [initialSlideCarrousel, setInitialSlideCarrousel] = useState<number>(0)
  const [directoryData, setDirectoryData] = useState({
    name: '',
    description: '',
  })
  const { basePath } = useCustomRouter()
  const { elementId, directoryId } = useParams()

  const handleDetailMultimedia = (slideNumber: number) => {
    setInitialSlideCarrousel(slideNumber)
    setIsMediaCarrouselVisible(true)
  }

  const itemsMultimedia = useMemo(() => {
    if (
      detail &&
      detail.elementMultimediaSection &&
      detail.elementMultimediaSection.isVisible
    ) {
      return detail.elementMultimediaSection.elementMultimediaSectionContents.map(
        (multimedia) => ({
          multimediaUrl: multimedia.multimediaUrl,
          type: multimedia.type as 'Image' | 'Video',
          title: multimedia.translations[0].name,
        })
      )
    }
    return []
  }, [detail])

  const getDirectoryData = async () => {
    if (directoryId) {
      const response = await getDirectoryNameAndDescription(directoryId)
      if (response) {
        setDirectoryData(response)
      }
    }
  }

  const getElement = async (id: string) => {
    try {
      await fetchElement(id)
    } catch (error) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        navigate(`${basePath}/access-denied`)
      }
    }
  }

  useEffect(() => {
    if (elementId) {
      getElement(elementId)
    }
  }, [elementId])

  useEffect(() => {
    getDirectoryData()
  }, [directoryId])

  return (
    <Main ariaLabelledby="element-detail">
      {isLoaded && detail && directoryData.name ? (
        <>
          <div className="row align-items-center">
            <div className="col">
              <p className="h3">{directoryData.name}</p>
            </div>
            <div className="col-auto">
              <Link
                className="btn btn-link"
                to={`${basePath}/directories/${directoryId}`}
              >
                <Icon name="keyboard_arrow_left" />
                <span>
                  {t('directories.element.backToDirectory', {
                    directoryName: directoryData.name,
                  })}
                </span>
              </Link>
            </div>
          </div>

          <div className="element-banner">
            <div className="element-banner__image">
              <img src={detail.imageUrl} alt={detail.translations[0].name} />
            </div>
            <div className="element-banner__content">
              <h1 className="element-banner__title">
                {detail.translations[0].name}
              </h1>
              <p className="element-banner__description">
                {detail.translations[0].description}
              </p>
              {detail.url && (
                <a
                  className="btn btn-link"
                  href={detail.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>{t('directories.element.viewMore')}</span>
                  <Icon name="keyboard_arrow_right" />
                </a>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              {detail.informativeSections &&
                detail.informativeSections.length > 0 && (
                  <Accordion
                    className="mt-4"
                    defaultActiveKey={detail.informativeSections.map(
                      (_, index) => index.toString()
                    )}
                    alwaysOpen={true}
                  >
                    {detail.informativeSections.map((section, index) => (
                      <Accordion.Item
                        key={section.id}
                        eventKey={index.toString()}
                      >
                        <Accordion.Header as="div">
                          {section.translations[0].name}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: section.translations[0].description,
                            }}
                          />
                          {section.multimediaUrl &&
                            section.type === 'Video' && (
                              <div className="react-player-container">
                                <ReactPlayer
                                  url={section.multimediaUrl}
                                  width="100%"
                                  height="100%"
                                  controls
                                />
                              </div>
                            )}
                          {section.multimediaUrl &&
                            section.type === 'Image' && (
                              <img
                                className="element-img"
                                src={section.multimediaUrl}
                                loading="lazy"
                                alt={section.translations[0].name}
                              />
                            )}
                          {isMobile && index === 0 && (
                            <AdSlot
                              section="comunity365DirectoryDetail"
                              container="sideBarRight"
                              device="mobile"
                              id="directoryDetailMobile"
                              className="elements-detail-ad"
                            />
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                )}
              {detail.elementMultimediaSection &&
                detail.elementMultimediaSection.isVisible && (
                  <>
                    <div className="row mt-5">
                      {detail.elementMultimediaSection.elementMultimediaSectionContents.map(
                        (multimedia, index) => (
                          <div
                            key={multimedia.id}
                            className="col-lg-4 col-md-6"
                          >
                            <div
                              style={{ cursor: 'pointer' }}
                              role="presentation"
                              onClick={() => handleDetailMultimedia(index)}
                              onKeyDown={() => handleDetailMultimedia(index)}
                            >
                              <VerticalCard
                                type={multimedia.type as 'Image' | 'Video'}
                                title={multimedia.translations[0].name}
                                multimediaUrl={multimedia.multimediaUrl}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <MultimediaCarrousel
                      initialSlide={initialSlideCarrousel}
                      items={itemsMultimedia}
                      show={isMediaCarrouselVisible}
                      onHide={() => setIsMediaCarrouselVisible(false)}
                    />
                  </>
                )}
            </div>
            <div className="col-md-3">
              {!isMobile && (
                <AdSlot
                  section="comunity365DirectoryDetail"
                  container="sideBarRight"
                  device="desktop"
                  id="directoryDetailDesktop"
                />
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Main>
  )
}

export default ElementDetailPage
