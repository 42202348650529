import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseTopicsResult, Topic } from './types'
import { setList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useTopics = (): UseTopicsResult => {
  const dispatch = useAppDispatch()
  const { detail, list, isLoaded } = useAppSelector((state) => state.topics)
  const { get } = useFetch()

  const endpoint = 'topic-families'

  const getTopics = async (language?: string): Promise<Topic[] | undefined> => {
    dispatch(showLoader())
    const response: Topic[] | undefined = await get({
      endpoint,
      params: { language },
    })
    dispatch(hideLoader())
    return response
  }

  const fetchTopics = async () => {
    dispatch(showLoader())
    const response: Topic[] | undefined = await getTopics()
    if (response) dispatch(setList(response))
    dispatch(hideLoader())
  }

  return {
    topic: detail,
    topicList: list,
    isLoaded,
    fetchTopics,
    getTopics,
  }
}

export default useTopics
