import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { TermCondition, TermsConditionsState } from './types'

const initialState: TermsConditionsState = {
  termsConditions: null,
  isLoaded: false,
  isSigned: false,
  isVisible: false,
  isReadOnly: true,
}

export const slice = createSlice({
  name: 'termsCons',
  initialState,
  reducers: {
    show: (state) => {
      state.isVisible = true
    },
    hide: (state) => {
      state.isVisible = false
    },
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setIsReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload
    },
    setList: (state, action: PayloadAction<TermCondition[]>) => {
      state.termsConditions = action.payload
    },
    setSignState: (state, action: PayloadAction<boolean>) => {
      state.isSigned = action.payload
    },
  },
})

export const {
  show,
  hide,
  showLoader,
  hideLoader,
  setIsReadOnly,
  setList,
  setSignState,
} = slice.actions

export default slice.reducer
