import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  Member,
  MemberChannel,
  MemberProfile,
  MemberState,
  MemberTheme,
} from './types'

const initialState: MemberState = {
  detail: null,
  memberProfile: null,
  isLoaded: false,
  themes: [],
}

export const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setDetail: (state, action: PayloadAction<Member>) => {
      state.detail = action.payload
    },
    setValidateDataState: (state, action: PayloadAction<boolean>) => {
      if (!state.detail) return
      state.detail.changeToValidate = action.payload
    },
    activateChannelState: (
      state,
      action: PayloadAction<Partial<MemberChannel>>
    ) => {
      if (!state.detail) return
      state.detail.channels = (state.detail.channels || []).map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, isEnabled: true }
        }
        return { ...item, isEnabled: false }
      })
    },
    setThemes: (state, action: PayloadAction<MemberTheme[]>) => {
      state.themes = action.payload
    },
    setMemberProfile: (state, action: PayloadAction<MemberProfile | null>) => {
      state.memberProfile = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setDetail,
  setValidateDataState,
  activateChannelState,
  setThemes,
  setMemberProfile,
} = memberSlice.actions

export const selectTopics = (state: RootState) => state.topics

export default memberSlice.reducer
