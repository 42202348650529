import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import {
  ActivityDetail,
  ActivitiesState,
  ActivitiesResult,
  BrandSpace,
  CommentPayload,
  DeleteCommentActivityPayload,
} from './types'

const initialState: ActivitiesState = {
  detail: null,
  brandSpaces: [],
  list: {
    activities: [],
    recommendedActivities: [],
    interestingActivities: [],
    search: undefined,
  },
  isLoaded: false,
}

export const activitiesSlice = createSlice({
  name: 'activitiesInfo',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<ActivitiesResult>) => {
      state.list = action.payload
      state.list.search = action.payload.search
    },
    setDetail: (state, action: PayloadAction<ActivityDetail | null>) => {
      state.detail = action.payload
    },
    setBrands: (state, action: PayloadAction<BrandSpace[]>) => {
      state.brandSpaces = action.payload
    },
    addComment: (state, action: PayloadAction<CommentPayload>) => {
      if (typeof action.payload.comment === 'undefined') return
      if (state.detail) {
        state.detail.comments.unshift(action.payload.comment)
      }
    },
    updateComment: (state, action: PayloadAction<CommentPayload>) => {
      if (typeof action.payload.comment === 'undefined') return
      if (state.detail) {
        const commentIndex = state.detail.comments.findIndex(
          (comment) =>
            action.payload.comment && comment.id === action.payload.comment.id
        )
        state.detail.comments[commentIndex] = action.payload.comment
      }
    },
    deleteComment: (
      state,
      action: PayloadAction<DeleteCommentActivityPayload>
    ) => {
      if (state.detail) {
        const commentIndex = state.detail.comments.findIndex(
          (comment) => comment.id === action.payload.commentId
        )
        state.detail.comments.splice(commentIndex, 1)
      }
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setList,
  setDetail,
  setBrands,
  addComment,
  updateComment,
  deleteComment,
} = activitiesSlice.actions

export const selectActivities = (state: RootState) => state.activities

export default activitiesSlice.reducer
