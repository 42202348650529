import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PrivacyPolicy, PrivacyPoliciesState } from './types'

const initialState: PrivacyPoliciesState = {
  privacyPolicies: null,
  isLoaded: false,
  isSigned: false,
  isVisible: false,
  isReadOnly: true,
}

export const slice = createSlice({
  name: 'privacyPolicies',
  initialState,
  reducers: {
    show: (state) => {
      state.isVisible = true
    },
    hide: (state) => {
      state.isVisible = false
    },
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setIsReadOnly: (state, action: PayloadAction<boolean>) => {
      state.isReadOnly = action.payload
    },
    setList: (state, action: PayloadAction<PrivacyPolicy[]>) => {
      state.privacyPolicies = action.payload
    },
    setSignState: (state, action: PayloadAction<boolean>) => {
      state.isSigned = action.payload
    },
  },
})

export const {
  show,
  hide,
  showLoader,
  hideLoader,
  setIsReadOnly,
  setList,
  setSignState,
} = slice.actions

export default slice.reducer
