import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import { useHtmlToString } from '../../../utils/useHtmlToString'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    themesIds: yup
      .array()
      .label(t('post.create.theme.label'))
      .min(1, ({ label }) => t('validations.required', { label }))
      .required('required'),
    message: yup
      .string()
      .label(t('post.create.message.label'))
      .required(({ label }) => t('validations.required', { label }))
      .test(
        'post.create.message.label',
        ({ label }) => t('validations.maxLength', { label, maxLength: 5000 }),
        (val) => useHtmlToString(val ?? '').length <= 5000
      ),
  })
}
