import { FC, useEffect, useState, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FormSelect } from '@liveconnect/components'

import { Main } from '../../components/Main'
import ConfirmModal from './ConfirmModal'
import SuccessModal from './SuccessModal'
import Step1 from './step1'
import Step2 from './step2'
import { useCustomRouter } from '../../utils/useCustomRouter'
import useSnackBars from '../../utils/notifications/useNotifications'
import useMember from '../../core/member/useMember'
import useAuth, { postLogoutRedirectPathKey } from '../../core/auth/useAuth'
import Snackbar from '../../containers/Snackbar'
import { Step1Form, Step2Form } from './types'
import { DEFAULT_LANG } from '../../i18n/config'

import useConfiguration from '../../core/configuration/useConfiguration'
import useLocalizations from '../../core/Localizations/useLocalizations'
import { Localization } from '../../core/Localizations/types'
import ConfirmationModal from '../../containers/ConfirmationModal'
import useGTM from '../../utils/useGTM'
import { Error409, FetchError } from '../../utils/fetch/types'
import useUi from '../../core/ui/useUi'

import './styles.scss'

const OnboardingScreen: FC = () => {
  const { t } = useTranslation()
  const { login, logout, loaded, isLoggedIn } = useAuth()
  const { insertMember, member, changeLocalMemberLang } = useMember()
  const { getLocalizations } = useLocalizations()
  const notify = useSnackBars()
  const [currentStep, setCurrentStep] = useState(1)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [first, setFirst] = useState(true)
  const [localizationsState, setLocalizations] = useState<Localization[]>([])
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const [step1Form, setStep1Form] = useState<Step1Form>()
  const htmlRef = useRef<HTMLInputElement>(null)
  const { pushDataLayer } = useGTM()
  const { showConfirmation } = useUi()
  const { tenantId } = useParams()
  const {
    isLoaded: onboardingIsLoaded,
    onboarding,
    fetchOnboardingConfiguration,
  } = useConfiguration()

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      language: member?.isoCode ? member.isoCode : DEFAULT_LANG,
    },
  })

  const { control, watch } = methods
  const watchLang = watch('language')

  const nextStep = (member: Step1Form) => {
    setStep1Form(member)
    setCurrentStep(2)
  }

  const confirmLogout = () => {
    sessionStorage.setItem(postLogoutRedirectPathKey, `/t/${tenantId}`)
    logout()
  }

  const submitMember = async (step2Form: Step2Form) => {
    const channels: string[] = Object.keys(step2Form.channels).filter(
      (key) => step2Form.channels[key]
    )
    setIsSubmitting(true)
    try {
      if (!step1Form) throw new Error('Step 1 cannot be empty')

      await insertMember({
        ...step1Form,
        ...step2Form,
        channels,
        imageUrl: step1Form.imageUrl ?? null,
        isoCode: watchLang,
      })

      pushDataLayer({
        event: 'user_onboarding_success',
        category: 'interaction',
        action: 'onboarding_ok',
        extraParams: {
          user_profile_channels: channels.join('|'),
          user_job: step1Form.jobTitle,
          user_company: step1Form.company,
        },
      })

      navigate(`${basePath}/home`)
    } catch (error) {
      const fetchError = error as FetchError

      if (
        fetchError.status === 409 &&
        fetchError.body.some(
          (item: { message: Error409 }) => item.message === Error409.Age
        )
      ) {
        showConfirmation({
          title: t('footer.legal'),
          subtitle: t('onboarding.younger'),
          iconName: 'report_problem',
          hideCancel: true,
          onConfirm: () => {
            confirmLogout()
          },
        })
      } else {
        notify.error(t('member.edit.unknownError'))
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  const fetchLocalizations = async () => {
    const localizationsRes = await getLocalizations()
    localizationsRes && setLocalizations(localizationsRes)
  }

  const changeLang = async () => {
    changeLocalMemberLang(watchLang)
  }

  const configurationOnboarding = useMemo(() => {
    if (onboarding) {
      return (
        onboarding.translations.find(
          (translation) => translation.language === watchLang
        ) ?? {
          title: '',
          description: '',
        }
      )
    }
    return {
      title: '',
      description: '',
    }
  }, [watchLang, onboarding])

  useEffect(() => {
    if (!isLoggedIn) {
      login()
    }
  }, [loaded, isLoggedIn, login, member])

  useEffect(() => {
    if (member?.isOnboardingComplete) navigate(`${basePath}/home`)
    htmlRef.current?.scrollIntoView()
    fetchLocalizations()
    fetchOnboardingConfiguration()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    !first && changeLang()
    first && setFirst(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchLang])

  if (!isLoggedIn) return <></>

  return (
    <div className="onboarding">
      <span ref={htmlRef} />
      <div className="onboarding__header">
        <div className="onboarding__snackbar">
          <Snackbar />
        </div>
        {/* <div className="onboarding__header-background"></div> */}
        {onboardingIsLoaded && (
          <Main ariaLabelledby="onboarding">
            <div className="row">
              <div className="col-md-6 onboarding__header__text">
                {onboarding?.logoUrl && (
                  <img
                    src={onboarding.logoUrl}
                    alt="onboarding"
                    className="onboarding__header-logo"
                  />
                )}
                <h1>{configurationOnboarding.title}</h1>
                <p>{configurationOnboarding.description}</p>
              </div>
              <div className="col-md-6 onboarding__image-container">
                {onboarding?.imageUrl && (
                  <div className="onboarding__image">
                    <img src={onboarding.imageUrl} alt="onboarding" />
                  </div>
                )}
              </div>
            </div>
          </Main>
        )}
      </div>
      <Main ariaLabelledby="onboarding">
        <div className="row">
          <div className="formformSteps">
            <div className={currentStep === 1 ? 'step selected' : 'step'}>
              <div className="circ">1</div>
              <div className="name">{t('onboarding.option1')}</div>
            </div>
            <div className={currentStep === 2 ? 'step selected' : 'step'}>
              <div className="circ">2</div>
              <div className="name">{t('onboarding.option2')}</div>
            </div>
          </div>
          <div className="contentSelect">
            <FormSelect
              control={control}
              label=""
              name="language"
              options={localizationsState.map((item) => ({
                label: t(`localizations.${item.isoCode}`),
                value: item.isoCode,
              }))}
              noOptionsMessage={t('select.empty')}
              menuPlacement="bottom"
              isClearable={false}
            />
          </div>
        </div>

        <div className="row onboarding__generalContent">
          <div className="col-12">
            {currentStep === 1 ? (
              <Step1
                email={member?.email}
                setShowConfirmModal={setShowConfirmModal}
                nextStep={nextStep}
              />
            ) : (
              <Step2
                isSubmitting={isSubmitting}
                setShowConfirmModal={setShowConfirmModal}
                finalStep={submitMember}
                language={watchLang}
              />
            )}
          </div>
        </div>
        {showConfirmModal && (
          <ConfirmModal
            onClose={() => {
              setShowConfirmModal(false)
            }}
          />
        )}
        {showSuccessModal && (
          <SuccessModal
            onClose={() => {
              setShowSuccessModal(false)
            }}
          />
        )}
      </Main>
      <ConfirmationModal />
    </div>
  )
}

export default OnboardingScreen
