import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { TermCondition, UseTermsConditionsResult } from './types'
import {
  setList,
  setSignState,
  showLoader,
  hideLoader,
  show,
  hide,
  setIsReadOnly,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const useTermsConditions = (): UseTermsConditionsResult => {
  const dispatch = useAppDispatch()
  const { isVisible, isReadOnly, termsConditions, isSigned, isLoaded } =
    useAppSelector((state) => state.termsConditions)
  const { get, post } = useFetch()

  const endpoint = 'termsconditions'

  const showModal = () => {
    dispatch(show())
  }

  const hideModal = () => {
    dispatch(hide())
  }

  const setReadOnly = (readonly: boolean) => {
    dispatch(setIsReadOnly(readonly))
  }

  const fetchTermsConditions = async () => {
    const response: TermCondition[] | undefined = await get({ endpoint })
    response && dispatch(setList(response))
  }

  const checkTermsConditions = async () => {
    dispatch(showLoader())
    const response: boolean | undefined = await get({
      endpoint: `${endpoint}/check`,
    })
    response && dispatch(setSignState(response))
    dispatch(hideLoader())
  }

  const signTermsConditions = async () => {
    const response: boolean | undefined = await post({
      endpoint: `${endpoint}/sign`,
      body: {},
    })
    response && dispatch(setSignState(response))
  }

  return {
    termsConditions,
    isSigned,
    isVisible,
    isReadOnly,
    showModal,
    hideModal,
    setReadOnly,
    fetchTermsConditions,
    checkTermsConditions,
    signTermsConditions,
    isLoaded,
  }
}

export default useTermsConditions
