import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'

import { ModulesStatus, SettingsResponse, SettingsState } from './types'

const initialState: SettingsState = {
  settings: null,
  modulesStatus: {
    isLoaded: false,
    modulesStatus: {
      moduleStatus: false,
      tenantStatus: false,
    },
  },
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<SettingsResponse>) => {
      state.settings = action.payload
    },
    setModulesStatusLoaded: (state, action: PayloadAction<boolean>) => {
      state.modulesStatus.isLoaded = action.payload
    },
    setModulesStatus: (state, action: PayloadAction<ModulesStatus>) => {
      state.modulesStatus.modulesStatus = action.payload
    },
  },
})

export const { setSettings, setModulesStatus, setModulesStatusLoaded } =
  settingsSlice.actions

export const selectSettings = (state: RootState) => state.settings

export default settingsSlice.reducer
