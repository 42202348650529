import { FC, useEffect } from 'react'
import {
  Route,
  Navigate,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'

import ChannelsScreen from '../pages/Channels'
import HomeScreen from '../pages/Home'
import MemberRouter from './MemberRouter'
import LocalizationScreen from '../pages/Configuration/Localization'
import EventsList from '../pages/Home/EventsList'
import PeopleScreen from '../pages/People'
import BrandsRouter from './BrandsRouter'
import ContactRequestsScreen from '../pages/ContactRequests'
import NetworkingRouter from './NetworkRouter'
import ChatRouter from './ChatRouter'
import useChat from '../core/chat/useChat'
import OnboardingScreen from '../pages/Onboarding'
import useMember from '../core/member/useMember'
import VirtualPageView from '../containers/VirtualPageView'
import PrivateRoute from './PrivateRoute'
import ActivitiesRouter from './ActivitiesRouter'
import DirectoriesRouter from './DirectoriesRouter'
import usePermissions from '../core/permissions/usePermissions'
import useTermsConditions from '../core/termsConditions/useTermsConditions'
import usePrivacyPolicies from '../core/privacyPolicies/usePrivacyPolicies'
import ConfigurationRouter from './ConfigurationRouter'
import SelectChannelsScreen from '../pages/Channels/SelectChannels'
import { useCustomRouter } from '../utils/useCustomRouter'
import useSettings from '../core/settings/useSettings'
import NotAvailable from '../pages/Error/NotAvailable'
import { AccessDenied } from '../pages/AccessDenied'

const CommunitiesRouter: FC = () => {
  const { member } = useMember()
  const location = useLocation()
  const { modulesStatus } = useSettings()

  const inOnboarding = location.pathname.includes('onboarding')

  if (
    !modulesStatus.modulesStatus.tenantStatus ||
    !modulesStatus.modulesStatus.moduleStatus
  )
    return <NotAvailable />

  return member && !member.isOnboardingComplete && !inOnboarding ? (
    <Navigate to="onboarding" replace />
  ) : (
    <>
      <VirtualPageView />
      <Routes>
        <Route path="onboarding" element={<OnboardingScreen />} />
        <Route path="/*" element={<CommunitiesAuthRouter />} />
      </Routes>
    </>
  )
}

const CommunitiesAuthRouter: FC = () => {
  const navigate = useNavigate()
  const { isLoaded: hasPermissionsLoaded, fetchList: fetchPermissions } =
    usePermissions()
  const { member } = useMember()
  const { initChat } = useChat()
  const { basePath } = useCustomRouter()
  const {
    checkTermsConditions,
    isLoaded: isTermsConditionsLoaded,
    isSigned: isTermsConditionsSigned,
  } = useTermsConditions()
  const { checkPrivacyPolicies } = usePrivacyPolicies()

  const initAuthData = async () => {
    if (!hasPermissionsLoaded) {
      try {
        await fetchPermissions()
      } catch (e) {
        navigate(`${basePath}/access-denied`)
      }
    } else {
      initChat()
      if (member && !member.channels.length) {
        navigate(`${basePath}/select-channels`)
      }
      checkTermsConditions()
    }
  }

  useEffect(() => {
    if (!member) return
    initAuthData()
  }, [member, hasPermissionsLoaded])

  useEffect(() => {
    if (isTermsConditionsLoaded && isTermsConditionsSigned) {
      checkPrivacyPolicies()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTermsConditionsLoaded, isTermsConditionsSigned])

  return (
    <Routes>
      <Route path="/access-denied" element={<AccessDenied />} />
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Navigate to="home" />} />
        <Route path="home" element={<HomeScreen />} />
        <Route path="member/*" element={<MemberRouter />} />
        <Route path="channels" element={<ChannelsScreen />} />
        <Route path="brands/*" element={<BrandsRouter />} />
        <Route path="configuration/*" element={<ConfigurationRouter />} />
        <Route path="localization" element={<LocalizationScreen />} />
        <Route path="events" element={<EventsList />} />
        <Route path="people" element={<PeopleScreen />} />
        <Route path="activities/*" element={<ActivitiesRouter />} />
        <Route path="networking/*" element={<NetworkingRouter />} />
        <Route path="contact-requests" element={<ContactRequestsScreen />} />
        <Route path="chat/*" element={<ChatRouter />} />
        <Route path="select-channels" element={<SelectChannelsScreen />} />
        <Route path="directories/*" element={<DirectoriesRouter />} />
      </Route>
    </Routes>
  )
}

export default CommunitiesRouter
