import { FC } from 'react'
import { Link, useMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Icon, IconName } from '@liveconnect/icons'
import { Loader } from '@liveconnect/components'

import { navbarItems } from './items'
import { useCustomRouter } from '../../utils/useCustomRouter'

import useDirectories from '../../core/directories/useDirectories'

import './styles.scss'

type NavbarProps = {
  rootClassName?: string
}

const Navbar: FC<NavbarProps> = ({ rootClassName = 'Menu' }) => {
  const { main, isLoaded } = useDirectories()
  const { t } = useTranslation()

  return isLoaded ? (
    <ul className={rootClassName}>
      {navbarItems.map((item, index) => {
        return (
          <li
            key={`static_${index}`}
            className={`${rootClassName}__static__${index}`}
          >
            <NavbarLink
              rootClassName={rootClassName}
              name={t(item.trnsKey)}
              path={item.path}
              iconName={item.icon as IconName}
              isExact={item.exact}
            />
          </li>
        )
      })}
      {main.map((item, index) => (
        <li
          key={`dynamic_${item}`}
          className={`${rootClassName}__dynamic__${index}`}
        >
          <NavbarLink
            rootClassName={rootClassName}
            name={item.name}
            path={`/directories/${item.id}`}
            imageUrl={item.imageUrl}
          />
        </li>
      ))}
    </ul>
  ) : (
    <Loader />
  )
}

interface NavbarItemProps {
  rootClassName: string
  name: string
  path: string
  iconName?: IconName
  imageUrl?: string
  isExact?: boolean
  isDisabled?: boolean
}

const NavbarLink: FC<NavbarItemProps> = ({
  rootClassName,
  name,
  path,
  iconName,
  imageUrl,
  isExact = false,
  isDisabled = false,
}) => {
  const { basePath } = useCustomRouter()
  const finalPath = `${basePath}${path}`
  const active = useMatch({ path: finalPath, end: isExact })

  return (
    <Link
      to={finalPath}
      className={classNames(`${rootClassName}__item`, {
        active,
        disabled: isDisabled,
      })}
    >
      <div className={`${rootClassName}__icon`}>
        {iconName && <Icon name={iconName} />}
        {imageUrl && <img src={imageUrl} alt={name} className="image" />}
      </div>
      <div className={`${rootClassName}__text`}>{name}</div>
    </Link>
  )
}
export default Navbar
