import useFetch from '../../utils/fetch/useFetch'
import { UseLocalizationsResult, Localization } from './types'

const useLocalizations = (): UseLocalizationsResult => {
  const { get } = useFetch()

  const endpoint = 'localizations'

  const getLocalizations = async () => {
    const response: Localization[] | undefined = await get({ endpoint })
    return response
  }

  return {
    getLocalizations,
  }
}

export default useLocalizations
