import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Icon } from '@liveconnect/icons'
import { useNavigate } from 'react-router-dom'

import { useCustomRouter } from '../../../utils/useCustomRouter'

import './styles.scss'

type CreatePostProps = {
  onClose: () => void
}

const SuccessModal: FC<CreatePostProps> = ({ onClose }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()

  return (
    <Modal show={true} onHide={onClose} className="SuccessModal">
      <Modal.Body>
        <div className="row">
          <div className="col-12 text-center content">
            <Icon name="check_circle_filled" />
            <p className="title">{t('success.modal.title')}</p>
            <p>{t('success.modal.text')}</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => navigate(`${basePath}/home`)}
        >
          {t('success.modal.button')}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default SuccessModal
