import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { UseBrandsResult, Brand } from './types'
import { setList, showLoader, hideLoader, setBrand } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useBrands = (): UseBrandsResult => {
  const dispatch = useAppDispatch()
  const { detail, list, isLoaded } = useAppSelector((state) => state.brands)
  const { get } = useFetch()

  const endpoint = 'brand-spaces'

  const dispatchLoaders = async (asynCall: () => Promise<unknown>) => {
    dispatch(showLoader())
    try {
      await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const getBrands = async (): Promise<Brand[] | undefined> => {
    const response: Brand[] | undefined = await get({ endpoint })
    return response
  }

  const fetchBrands = async (): Promise<void> =>
    dispatchLoaders(async () => {
      const response: Brand[] | undefined = await getBrands()
      dispatch(setList(response ?? []))
    })

  const fetchBrandDetail = async (brandId: string): Promise<void> => {
    dispatchLoaders(async () => {
      const response: Brand | undefined = await get({
        endpoint: `${endpoint}/${brandId}`,
      })
      if (response) dispatch(setBrand(response))
    })
  }

  const setCurrentBrand = (brand: Brand): void => {
    dispatch(setBrand(brand))
  }

  return {
    brand: detail,
    brandList: list,
    isLoaded,
    fetchBrandDetail,
    fetchBrands,
    setCurrentBrand,
  }
}

export default useBrands
