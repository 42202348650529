import { format, Locale } from 'date-fns'
import { enGB, es } from 'date-fns/esm/locale'

import useMember from '../../core/member/useMember'
import { IsoCode } from '../../core/member/types'
import { DEFAULT_LANG } from '../../i18n/config'
import dates from './dates.json'
import { DateType } from './types'

const useCulture = () => {
  const { member } = useMember()
  const isoCode: IsoCode = member?.isoCode || DEFAULT_LANG

  const formatDate = (value: Date, dateFormat: DateType): string =>
    format(value, dates[isoCode][dateFormat], {
      locale: getLocaleFromIsoCode(isoCode),
    })

  const formatNumber = (value: number): string =>
    new Intl.NumberFormat(isoCode).format(value)

  const getLocaleFromIsoCode = (isoCode: IsoCode): Locale => {
    switch (isoCode) {
      case 'es-ES':
        return es
      case 'en-GB':
      default:
        return enGB
    }
  }

  return {
    formatDate,
    formatNumber,
  }
}

export default useCulture
