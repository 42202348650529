import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RecomendedMemberListPayload, RecomendedMembersState } from './types'

const initialState: RecomendedMembersState = {
  list: [],
  page: 1,
  pageSize: 6,
  availablePages: 1,
  availableItems: 0,
  search: '',
  isLoaded: false,
}

export const slice = createSlice({
  name: 'recomendedMembers',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    clearList: () => initialState,
    setList: (state, action: PayloadAction<RecomendedMemberListPayload>) => {
      state.list = action.payload.list
      state.page = action.payload.page
      state.pageSize = action.payload.pageSize
      state.availablePages = action.payload.availablePages
      state.availableItems = action.payload.availableItems
      state.search = action.payload.search
    },
  },
})

export const { showLoader, hideLoader, clearList, setList } = slice.actions

export default slice.reducer
