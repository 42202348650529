import { FC, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'
import {
  ContextMenu,
  PublisherProfile,
  TypePublishEnum,
} from '@liveconnect/communities-ui'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import {
  Comment,
  DataEditProps,
  Publisher,
  PublishersSponsored,
  Reactions,
  TransLangs,
  TranslatePost,
} from '../../core/posts/types'
import { Theme } from '../../core/brandSpaces/types'
import useSnackBars from '../../utils/notifications/useNotifications'
import usePosts from '../../core/posts/usePosts'
import useMember from '../../core/member/useMember'
import useCulture from '../../utils/culture'
import { useCustomRouter } from '../../utils/useCustomRouter'
import useUi from '../../core/ui/useUi'
import { CommentComponent } from './Comment'
import { MakeCommentComponent } from './MakeComment'
import { ReactionComponent } from './Reaction'
import { calculateShowMore, EllapsedPeriod, showRemainingTime } from './utils'
import VideoPlayer from '../../components/VideoPlayer'
import ArrowButton from '../../components/Buttons/ArrowButton'
import useGTM from '../../utils/useGTM'

import './index.scss'

interface PostTrans {
  title: string
  message: string
}

interface PostContainerProps {
  id: string
  publisher: Publisher
  title: string
  message: string
  image: string
  video: string
  themes: Theme[]
  comments: Comment[]
  channels: { id: string }[]
  totalComments: number
  reaction: Reactions
  isEditable: boolean
  isDeletable: boolean
  goDetail: boolean
  publishedAt: string
  originalLanguage: string
  isSponsored: boolean
  sponsor: PublishersSponsored | null
  openEdit?: (dataEdit: DataEditProps) => void
  isHighlighted?: boolean
}

export const PostContainer: FC<PostContainerProps> = ({
  id,
  publisher,
  title,
  message,
  image,
  video,
  themes,
  channels,
  comments = [],
  totalComments,
  reaction,
  isEditable,
  isDeletable,
  isSponsored,
  goDetail,
  publishedAt,
  sponsor,
  originalLanguage,
  openEdit,
  isHighlighted = false,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const notify = useSnackBars()
  const { showConfirmation } = useUi()
  const { formatDate } = useCulture()
  const {
    deletePost,
    fetchPosts,
    getTranslatePost,
    fetchPostComments,
    viewMorePostSponsored,
    viewPostSponsor,
  } = usePosts()
  const { member } = useMember()
  const isoTrans = member?.isoCode.slice(0, 2)
  const textContainerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLDivElement>(null)
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showMakeComment, setShowMakeComment] = useState<boolean>(false)
  const [showTwoComments, setShowTwoComments] = useState<boolean>(false)
  const [showAllComments, setShowAllComments] = useState<boolean>(false)
  const [showTrans, setShowTrans] = useState<boolean>(false)
  const [textTrans, setTextTrans] = useState<PostTrans | undefined>(undefined)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialRender, setInitialRender] = useState<boolean>(false)
  const { pushDataLayer } = useGTM()

  const showInitialComments = async () => {
    if (totalComments > 0 && comments.length === 0) await fetchPostComments(id)
    setShowTwoComments(!showTwoComments)
    setShowMakeComment(!showMakeComment)
    setShowAllComments(false)
  }

  const handleDeletePost = async () => {
    try {
      await deletePost(id)
      await fetchPosts()
      notify.success(t('post.delete.succeessFeedback'))
    } catch (e) {
      notify.error(t('post.delete.unknownError'))
    }
  }

  const handleShowMore = async (show: boolean) => {
    setShowComplete(show)
    if (show) {
      if (isSponsored) {
        try {
          await viewMorePostSponsored(id)
        } catch (error) {}
      }
      pushDataLayer({
        event: 'content_open_community',
        category: 'interaction',
        action: 'content_open',
        extraParams: {
          content_channel: channels.map((c) => c.id).join(','),
          content_topic: themes.map((t) => t.id).join(','),
          content_highlight: isHighlighted,
          content_date: format(new Date(publishedAt), 'yyyy-MM-dd'),
        },
      })
    }
  }

  const formatRemainingTime = (utcDate: Date): string => {
    const remainingTime = showRemainingTime(utcDate)
    switch (remainingTime.periodType) {
      case EllapsedPeriod.NONE:
        return t('post.comment.ellapsed.now')
      case EllapsedPeriod.MINUTES:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.minute')
        return t('post.comment.ellapsed.minutes', {
          minutes: remainingTime.period,
        })
      case EllapsedPeriod.HOURS:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.hour')
        return t('post.comment.ellapsed.hours', { hours: remainingTime.period })
      case EllapsedPeriod.DAYS:
      default:
        return formatDate(remainingTime.period as Date, 'LONG_DATE')
    }
  }

  const showExpand = useMemo(
    (): boolean =>
      calculateShowMore(textRef.current, textContainerRef.current, message),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textContainerRef.current, textRef.current, message]
  )

  const postActions = useMemo(() => {
    const actions = []
    if (isEditable && openEdit)
      actions.push({
        label: t('post.actions.edit'),
        onClick: () => {
          const dataPost: DataEditProps = {
            title,
            id,
            message,
            image,
            themes,
            video,
          }
          openEdit(dataPost)
        },
      })
    if (isDeletable)
      actions.push({
        label: t('post.actions.delete'),
        onClick: () => {
          showConfirmation({
            title: t('post.actions.delete'),
            subtitle: t('post.confirmation.delete.subtitle'),
            text: t('post.confirmation.delete.text'),
            iconName: 'report_problem',
            onConfirm: handleDeletePost,
          })
        },
      })
    return actions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable, isDeletable, title, message, image, themes, id, video])

  const navigateDetail = async () => {
    if (publisher.isAnonymousMember) return
    if (isSponsored && sponsor) {
      window.open(sponsor.url, '_blank')
      try {
        await viewPostSponsor(id)
      } catch (error) {}
    } else {
      let route = ''
      if (publisher.type !== TypePublishEnum.Brand) {
        if (member?.id !== publisher.externalId) {
          route = 'networking'
          return navigate(`${basePath}/${route}/${publisher.externalId}`)
        }
        route = 'member'
        return navigate(`${basePath}/${route}`)
      }
      route = 'brands'
      return navigate(`${basePath}/${route}/${publisher.externalId}`)
    }
  }

  const showButtonTranslate = originalLanguage && originalLanguage !== isoTrans

  const sendLang = () => {
    if (isoTrans === TransLangs.ES) {
      return TransLangs.ES
    }
    return TransLangs.EN
  }

  const translateText = async () => {
    try {
      if (!showTrans && !textTrans) {
        const responseTrans: TranslatePost | undefined = await getTranslatePost(
          id,
          sendLang()
        )
        if (responseTrans) setTextTrans({ ...responseTrans })
      }
      setShowTrans(!showTrans)
    } catch (e) {
      notify.error(t('post.translate.error'))
    }
  }

  useEffect(() => {
    setInitialRender(true)
  }, [])

  useEffect(() => {
    if (showTrans) {
      setTextTrans(undefined)
      setShowTrans(false)
    }
  }, [title, message])

  const postData = useMemo(() => {
    return {
      content_title: title,
      content_channel: channels.map((c) => c.id).join('|'),
      content_topic: themes.map((c) => c.id).join('|'),
      content_highlight: 0,
      content_date: format(new Date(), 'yyyy-MM-dd'),
      content_author: member?.id ?? '',
    }
  }, [channels, themes, member, title])

  return (
    <div className="PostComponent">
      <PublisherProfile
        onClick={navigateDetail}
        type={publisher.type as TypePublishEnum}
        redirect={goDetail && !publisher.isAnonymousMember}
        publishedAt={formatRemainingTime(new Date(publishedAt))}
        title={isSponsored && sponsor ? sponsor.name : publisher.title}
        image={isSponsored && sponsor ? sponsor.image : publisher.image}
        description={
          isSponsored && sponsor ? sponsor.slogan : publisher.description
        }
        badges={publisher.badges}
        isSponsored={isSponsored}
        sponsoredText={t('post.sponsored')}
        texts={[
          t('badges.more', {
            number: publisher.badges.length - 1,
          }),
          t('badges.more.singular'),
        ]}
      />
      {postActions.length > 0 && (
        <div className="PostComponent__actions">
          <ContextMenu items={postActions} />
        </div>
      )}
      <h5 className="PostComponent__title">{title}</h5>
      {message && (
        <div
          className={classNames('PostComponent__text', { full: showComplete })}
          ref={textRef}
        >
          <div
            className="PostComponent__text__container"
            ref={textContainerRef}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        </div>
      )}
      {showExpand && (
        <div className="PostComponent__link">
          <ArrowButton
            onClick={() => handleShowMore(!showComplete)}
            label={showComplete ? t('post.view_less') : t('post.view_more')}
            icon={showComplete ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          />
        </div>
      )}
      {showTrans && textTrans && textTrans.title && (
        <h5 className="PostComponent__title">{textTrans.title}</h5>
      )}
      {showTrans && textTrans && (
        <div
          className="PostComponent__transText"
          dangerouslySetInnerHTML={{
            __html: textTrans.message,
          }}
        />
      )}
      {showButtonTranslate && (
        <div className={showTrans ? 'upButtonContainer' : ''}>
          <ArrowButton
            onClick={translateText}
            label={
              showTrans ? t('post.close_translate') : t('post.open_tranlate')
            }
            icon={showTrans ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          />
        </div>
      )}

      {themes && (
        <div className="row PostComponent__themes">
          {themes.map((theme: Theme) => {
            return (
              <div className="contLabel Chip" key={theme.id}>
                {theme.name}
              </div>
            )
          })}
        </div>
      )}
      {image && (
        <img className="PostComponent__img" alt="example" src={image} />
      )}
      {video && <VideoPlayer url={video} className="PostComponent__video" />}
      <div className="PostComponent__reactions">
        <div className="reactions">
          <ReactionComponent
            isPost={true}
            takenId={id}
            {...reaction}
            postData={postData}
          />
        </div>
        <div className="commentButton">
          <button onClick={showInitialComments} title={t('post.showComments')}>
            <Icon name="mode_comment" />
            {totalComments > 0 && (
              <span className="numberOfComments">{` ${totalComments}`}</span>
            )}
          </button>
        </div>
      </div>
      {showMakeComment && (
        <MakeCommentComponent takenId={id} postData={postData} />
      )}
      {showTwoComments && comments.length > 0 && (
        <div className="PostComponent__commentList">
          {comments.slice(0, 2).map((comment) => (
            <CommentComponent
              key={comment.id}
              {...comment}
              takenId={id}
              originalLangComment={comment.originalLanguage}
              isoTransProp={isoTrans}
            />
          ))}
          {comments.length > 2 && !showAllComments && (
            <button
              className="PostComponent__commentList__link"
              onClick={() => setShowAllComments(true)}
            >
              {t('posts.comment.viewMore')}
            </button>
          )}
        </div>
      )}
      {showAllComments &&
        comments
          .slice(2)
          .map((comment) => (
            <CommentComponent
              key={comment.id}
              {...comment}
              takenId={id}
              originalLangComment={comment.originalLanguage}
              isoTransProp={isoTrans}
            />
          ))}
      {comments.length > 2 && showAllComments && (
        <button
          className="PostComponent__commentList__link"
          onClick={() => setShowAllComments(false)}
        >
          {t('posts.comment.viewLess')}
        </button>
      )}
    </div>
  )
}
