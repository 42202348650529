import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import MemberScreen from '../pages/Member'
import EditMember from '../pages/Member/Edit'

const MemberRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MemberScreen />} />
      <Route path="edit" element={<EditMember />} />
    </Routes>
  )
}

export default MemberRouter
