import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UsePermissionsResult,
  Permission,
  ComplimentType,
  PermissionListResponse,
} from './types'
import { setList } from './store'
import useFetch from '../../utils/fetch/useFetch'
import { useNavigate } from 'react-router'
import { useCustomRouter } from '../../utils/useCustomRouter'

const usePermissions = (): UsePermissionsResult => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { basePath } = useCustomRouter()
  const { list, isLoaded } = useAppSelector((state) => state.permissions)
  const { get } = useFetch()

  const endpoint = 'permissions'

  const getList = async () => {
    const response: PermissionListResponse | undefined = await get({
      endpoint,
    })
    return response
  }

  const fetchList = async () => {
    const response: PermissionListResponse | undefined = await getList()
    if (!response?.hasProfile) navigate(`${basePath}/access-denied`)
    response && dispatch(setList({ list: response.actions }))
  }

  const checkPermissions = (to: Permission[], mustComply?: ComplimentType) => {
    if (to.length === 0) return true
    if (mustComply === ComplimentType.all) {
      return to.every((item) => list.includes(item))
    }
    return to.some((item) => list.includes(item))
  }

  return {
    list,
    isLoaded,
    fetchList,
    checkPermissions,
  }
}

export default usePermissions
