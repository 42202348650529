import { Fragment, useEffect, useMemo, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FilterButton, SearchInput } from '@liveconnect/communities-ui'
import { Loader } from '@liveconnect/components'

import { ElementListParams } from '../../../core/elements/types'
import useElements from '../../../core/elements/useElements'
import useDirectories from '../../../core/directories/useDirectories'

import { Main } from '../../../components/Main'
import EmptyState from '../../../components/EmptyState'
import VerticalCard from '../../../components/VerticalCard'
import DirectoryDetailFilters from './Filters'
import ArrowButton from '../../../components/Buttons/ArrowButton'
import AdSlot from '../../../containers/AdSlot'
import useIsMobile from '../../../utils/useIsMobile'
import { FetchError } from '../../../utils/fetch/types'
import { useCustomRouter } from '../../../utils/useCustomRouter'

import './styles.scss'

const DirectoryDetailPage = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { getDirectoryNameAndDescription } = useDirectories()
  const { list, isLoaded, fetchElements } = useElements()
  const isMobile = useIsMobile()
  const { basePath } = useCustomRouter()
  const [isOpenedFilters, setIsOpenedFilters] = useState<boolean>(false)
  const [directoryData, setDirectoryData] = useState({
    name: '',
    description: '',
  })

  const [listParams, setListParams] = useState<ElementListParams>({
    directoryId: id ?? '',
    page: 1,
    page_size: 12,
    search: '',
    channelIds: [],
    isHighLighted: false,
    IsNoHighLighted: false,
  })

  const filtersActive = useMemo(() => {
    return (
      listParams.channelIds.length > 0 ||
      listParams.isHighLighted ||
      listParams.IsNoHighLighted
    )
  }, [listParams])

  const handleChangeQuery = (query: string) => {
    setListParams({
      ...listParams,
      page: 1,
      search: query,
    })
  }

  const handleSubmit = ({
    channelsIds,
    isHighLighted,
    IsNoHighLighted,
  }: {
    channelsIds: string[]
    isHighLighted: boolean
    IsNoHighLighted: boolean
  }) => {
    setListParams({
      ...listParams,
      page: 1,
      channelIds: channelsIds,
      isHighLighted,
      IsNoHighLighted,
    })
    setIsOpenedFilters(false)
  }

  const handleNextPage = async () => {
    setListParams({ ...listParams, page: listParams.page + 1 })
  }

  const getDirectoryData = async () => {
    if (id) {
      const response = await getDirectoryNameAndDescription(id)
      if (response) {
        setDirectoryData(response)
      }
    }
  }

  const getElementsDirectory = async (params: ElementListParams) => {
    try {
      await fetchElements(params)
    } catch (error) {
      const fetchError = error as FetchError
      if (fetchError.status === 409) {
        navigate(`${basePath}/access-denied`)
      }
    }
  }

  useEffect(() => {
    getDirectoryData()
    if (id) {
      setListParams({
        directoryId: id,
        page: 1,
        page_size: 12,
        search: '',
        channelIds: [],
        isHighLighted: false,
        IsNoHighLighted: false,
      })
    }
  }, [id])

  useEffect(() => {
    getElementsDirectory(listParams)
  }, [listParams])

  return (
    <Main ariaLabelledby="directory-detail">
      <div className="row">
        <div className="col-lg-9">
          <h1>{directoryData.name}</h1>
          <p>{directoryData.description}</p>
          <div className="row align-items-center mb-4 mt-4">
            <div className="col">
              <SearchInput
                placeholder={t('common.search')}
                onSearch={handleChangeQuery}
                defaultValue=""
              />
            </div>
            <div className="col-auto">
              <FilterButton
                isActive={filtersActive}
                label={t('home.filters')}
                onClick={() => setIsOpenedFilters(true)}
              />
            </div>
          </div>
          {isLoaded ? (
            <div>
              {list && list.availableItems > 0 ? (
                <>
                  <div className="row">
                    {list.items.map((item, index) => (
                      <Fragment key={item.elementId}>
                        <div className="col-lg-4 col-md-6">
                          <Link
                            to={`elements/${item.elementId}`}
                            style={{ textDecoration: 'none' }}
                          >
                            <VerticalCard
                              title={item.name}
                              description={item.description}
                              multimediaUrl={item.image}
                              isHightlighted={item.isHighlight}
                            />
                          </Link>
                        </div>
                        {isMobile &&
                          (index === 2 ||
                            (list.items.length < 3 &&
                              index === list.items.length - 1)) && (
                            <AdSlot
                              section="comunity365DirectoyList"
                              container="sideBarRight"
                              device="mobile"
                              id="directoryListMobile"
                              className="elements-list-ad"
                            />
                          )}
                      </Fragment>
                    ))}
                  </div>
                  {list.availablePages !== list.page && (
                    <div className="mt-4 d-flex justify-content-center">
                      <ArrowButton
                        onClick={handleNextPage}
                        label={t('common.viewMore')}
                        icon="keyboard_arrow_down"
                      />
                    </div>
                  )}
                </>
              ) : (
                <>
                  <EmptyState text={t('directories.detail.emptyState')} />
                  {isMobile && (
                    <AdSlot
                      section="comunity365DirectoyList"
                      container="sideBarRight"
                      device="mobile"
                      id="directoryListMobile"
                      className="elements-list-ad"
                    />
                  )}
                </>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <div className="col-lg-3">
          {!isMobile && (
            <AdSlot
              section="comunity365DirectoyList"
              container="sideBarRight"
              device="desktop"
              id="directoryListDesktop"
            />
          )}
        </div>
      </div>
      <DirectoryDetailFilters
        onClose={() => setIsOpenedFilters(false)}
        onSubmit={handleSubmit}
        isOpened={isOpenedFilters}
      />
    </Main>
  )
}

export default DirectoryDetailPage
