import classNames from 'classnames'
import { FC } from 'react'
import ReactPlayer from 'react-player'

import './styles.scss'

interface VerticalCardProps {
  title?: string
  description?: string
  isHightlighted?: boolean
  type?: 'Image' | 'Video'
  multimediaUrl: string
}

const VerticalCard: FC<VerticalCardProps> = ({
  title,
  description,
  isHightlighted = false,
  type = 'Image',
  multimediaUrl,
}) => {
  return (
    <div className="vertical-card">
      <div className="vertical-card__image">
        {type === 'Video' && (
          <ReactPlayer
            url={multimediaUrl}
            light={true}
            width="100%"
            height="100%"
          />
        )}
        {type === 'Image' && (
          <img src={multimediaUrl} loading="lazy" alt={title} />
        )}
      </div>
      <div className="vertical-card__body">
        {isHightlighted && (
          <span className="vertical-card__highlighted">Destacado</span>
        )}
        {title && (
          <h2
            className={classNames('vertical-card__title', {
              'mb-0': !description,
            })}
          >
            {title}
          </h2>
        )}
        {description && <p className="vertical-card__text">{description}</p>}
      </div>
    </div>
  )
}

export default VerticalCard
