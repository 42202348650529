import { useTranslation } from 'react-i18next'
import { TypeActivity } from '../../core/activities/types'

const useTypes = () => {
  const { t } = useTranslation()
  const transformType = (type: TypeActivity) => {
    if (type === TypeActivity.Video) {
      return t('activities.card.type.video')
    }
    if (type === TypeActivity.FaceToFace) {
      return t('activities.filter.type.faceToFace')
    }
    if (type === TypeActivity.AskAuthor) {
      return t('activities.filter.type.askAuthor')
    }
    if (type === TypeActivity.RoundTable) {
      return t('activities.filter.type.roundTable')
    }
    if (type === TypeActivity.Challenge) {
      return t('activities.filter.type.challenge')
    }
    return type
  }

  return {
    transformType,
  }
}

export default useTypes
