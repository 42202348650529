import classNames from 'classnames'
import { FC } from 'react'

import './styles.scss'

type ChipProps = {
  isActive: boolean
  onClick: () => void
  label: string
}

const Chip: FC<ChipProps> = ({ isActive, onClick, label }) => {
  return (
    <div
      className={classNames('Chip', { 'Chip--active': isActive })}
      onClick={onClick}
      aria-hidden="true"
    >
      {label}
    </div>
  )
}

export default Chip
