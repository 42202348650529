import { useAppSelector, useAppDispatch } from '../reduxProvider'
import useFetch from '../../utils/fetch/useFetch'
import {
  setMain,
  showLoader,
  hideLoader,
  setChannel,
  setBrandSpace,
} from './store'
import {
  ChannelFilterListResponse,
  DirectoryData,
  DirectoryListItem,
  MainDirectoryListItem,
} from './types'

const useDirectories = () => {
  const dispatch = useAppDispatch()

  const { main, channel, brandSpace, isLoaded } = useAppSelector(
    (state) => state.directories
  )
  const { get } = useFetch()

  const endpoint = 'directories'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchMainDirectories = async () => {
    await dispatchLoaders(async () => {
      const response: MainDirectoryListItem[] | undefined = await get({
        endpoint: `${endpoint}/main-type`,
      })
      if (response) dispatch(setMain(response))
    })
  }

  const fetchChannelDirectories = async () => {
    await dispatchLoaders(async () => {
      const response: DirectoryListItem[] | undefined = await get({
        endpoint: `${endpoint}/channel-type`,
      })
      if (response) {
        dispatch(setChannel(response))
      }
    })
  }

  const fetchBrandSpaceDirectories = async (id: string) => {
    await dispatchLoaders(async () => {
      const response: DirectoryListItem[] | undefined = await get({
        endpoint: `${endpoint}/brand-space-type/${id}`,
      })
      if (response) {
        dispatch(setBrandSpace(response))
      }
    })
  }

  const getChannelFilterList = async (
    id: string
  ): Promise<ChannelFilterListResponse | undefined> => {
    const response: ChannelFilterListResponse | undefined = await get({
      endpoint: `${endpoint}/${id}/channel-filter-list`,
    })
    return response
  }

  const getDirectoryNameAndDescription = async (
    id: string
  ): Promise<DirectoryData | undefined> => {
    const response: DirectoryData | undefined = await get({
      endpoint: `${endpoint}/${id}/name`,
    })
    return response
  }

  return {
    main,
    channel,
    brandSpace,
    isLoaded,
    fetchMainDirectories,
    fetchChannelDirectories,
    fetchBrandSpaceDirectories,
    getChannelFilterList,
    getDirectoryNameAndDescription,
  }
}

export default useDirectories
