import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, useFieldArray } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

import { Icon } from '@liveconnect/icons'
import {
  TextControl,
  FormDate,
  FormTextInput,
  FormProvider,
  CheckboxControl,
  Loader,
  AvatarUploadControl,
  FormRichText,
} from '@liveconnect/components'

import { Main } from '../../../components/Main'
import useSnackBars from '../../../utils/notifications/useNotifications'
import { Member, MemberToUpdate } from '../../../core/member/types'
import FormActions from '../../../components/FormActions'
import useMember from '../../../core/member/useMember'
import { buildValidationSchema } from './validations'
import { ConfirmationDeleteModal } from './ConfirmationDeleteModal'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import useUi from '../../../core/ui/useUi'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'

import './styles.scss'

const EditMember: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useSnackBars()
  const { showConfirmation } = useUi()
  const { uploadMemberImage, updateMember, member } = useMember()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [imageUrl, setImageUrl] = useState<string | null | undefined>(
    member?.imageUrl
  )
  const [
    isConfirmationDeleteModalVisible,
    setIsConfirmationDeleteModalVisible,
  ] = useState(false)
  const { basePath } = useCustomRouter()

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: {
      ...member,
      dateOfBirth: member?.dateOfBirth ? new Date(member?.dateOfBirth) : '',
      jobsArray: [
        {
          company: '',
          jobTitle: '',
          isHighlighted: false,
          id: '',
        },
      ],
    },
  })

  const {
    control,
    watch,
    handleSubmit,
    reset,
    trigger,
    getValues,
    formState: { isValid, isDirty },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'jobsArray',
  })

  const watchJobs = watch('jobsArray')

  const handleCheck = (newValue: boolean, position: number) => {
    const copyJobs = watchJobs
    copyJobs[position].isHighlighted = newValue
    reset({
      ...getValues(),
      jobsArray: copyJobs,
    })
  }

  const toggleConfirmationDeleteModal = () => {
    setIsConfirmationDeleteModalVisible(!isConfirmationDeleteModalVisible)
  }

  const onSubmit = async (member: Member) => {
    setIsSubmitting(true)
    try {
      if (imageUrl) {
        if (imageUrl !== member.imageUrl) {
          try {
            const memberImage = await uploadMemberImage(imageUrl)
            member.imageUrl = memberImage
          } catch (e) {
            showConfirmation({
              title: t('common.warning'),
              subtitle: t('explicitImage.subtitle'),
              text: t('explicitImage.text'),
              confirmText: t('common.accept'),
              iconName: 'report_problem',
              hideCancel: true,
            })
            return
          }
        }
      } else {
        member.imageUrl = null
      }

      const jobsTransform = watchJobs.map((job) => {
        if (job.id === '') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { id, ...restoDelObjeto } = job
          return restoDelObjeto
        } else {
          return job
        }
      })

      const memberUpdate: MemberToUpdate = {
        name: member.name,
        surname: member.surname,
        description: member.description,
        imageUrl: member.imageUrl,
        positions: jobsTransform,
        dateOfBirth: member.dateOfBirth,
      }

      await updateMember(memberUpdate)
      notify.success(t('member.edit.succeessFeedback'))
      reset()
      navigate(`${basePath}/member`)
    } catch (e) {
      notify.error(t('member.edit.unknownError'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const isCheckDisabled = (position: number) => {
    const copyJobs = [...watchJobs]
    if (copyJobs.length > 1) {
      copyJobs.splice(position, 1)
      return copyJobs.some((job) => job.isHighlighted === true)
    }
    return false
  }

  const validateSave = () => {
    const copyJobs = [...watchJobs]
    return copyJobs.some((job) => job.company === '' || job.jobTitle === '')
  }

  const validateCheck = () => {
    const copyJobs = [...watchJobs]
    if (copyJobs.length === 0) {
      return false
    }
    return copyJobs.every((job) => job.isHighlighted === false)
  }

  useEffect(() => {
    if (member && member.positions) {
      reset({
        ...getValues(),
        jobsArray: member.positions,
      })
    }
  }, [member])

  return (
    <Main ariaLabelledby="member EditMember">
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="h3">{t('member.edit.title')}</h1>
          <p>{t('member.edit.hint')}</p>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <h4>{t('member.edit.main_data')}</h4>
        </div>
      </div>
      <FormProvider methods={methods}>
        <div className="row">
          <div className="col-xs-12 col-md-2">
            <AvatarUploadControl
              control={control}
              title={t('member.edit.image')}
              label={t('common.addImage')}
              name="imageUrl"
              value={imageUrl ?? ''}
              onChange={(url) => setImageUrl(url || '')}
              required={false}
              accept="image/png,image/jpeg"
              maxSizeInMb={1}
              selectFilesError={() => {}}
              selectFilesSuccess={() => {}}
            />
          </div>
          <div className="col-xs-12 col-md-8">
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <FormTextInput
                  control={control}
                  name="name"
                  label={t('member.edit.name.label')}
                  placeholder={t('member.edit.name.placeholder')}
                  type="text"
                  required={true}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormTextInput
                  control={control}
                  name="surname"
                  label={t('member.edit.surname.label')}
                  placeholder={t('member.edit.surname.placeholder')}
                  type="text"
                  required={true}
                />
              </div>
            </div>
            <div className="row mt4">
              <div className="col-xs-12 col-md-6">
                <TextControl
                  name="email"
                  label={t('member.edit.email')}
                  type="text"
                  value={member?.email ?? ''}
                  onChange={() => {
                    return false
                  }}
                  disabled={true}
                />
              </div>
              <div className="col-xs-12 col-md-6">
                <FormDate
                  control={control}
                  name="dateOfBirth"
                  label={t('member.edit.dateOfBirth')}
                  max={new Date()}
                  isUTC={false}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-12">
            <h4>{t('member.edit.personal_info')}</h4>
          </div>
        </div>
        <div className="row mb-5">
          <div className="col-12 col-md-10">
            <FormRichText
              control={control}
              name="description"
              placeholder={t('member.edit.description.placeholder')}
              label={t('member.edit.about.label')}
              required={true}
            />
          </div>
        </div>

        <h4>{t('member.edit.deleteUser.title')}</h4>
        <p>{t('member.edit.deleteUser.subtitle')}</p>
        <button
          type="button"
          className="btn btn-outline-primary"
          onClick={toggleConfirmationDeleteModal}
        >
          {t('member.edit.deleteUser.button')}
        </button>
        <ConfirmationDeleteModal
          isVisible={isConfirmationDeleteModalVisible}
          onHide={toggleConfirmationDeleteModal}
        />

        <div className="row mt-5">
          <div className="col-12">
            <h4>{t('member.list.jobs')}</h4>
          </div>
          <div className="col-12">
            <p>{t('member.list.jobs.text')}</p>
          </div>
        </div>
        <div
          className={`row d-none EditMember__leyend ${
            watchJobs.length === 0 ? 'd-none ' : 'd-sm-block'
          }`}
        >
          <p className="textLeyend">{t('member.list.job.default')}</p>
        </div>
        <div className="row EditMember__jobs">
          {fields.map((item, index) => (
            <div key={`${item.id}-job`}>
              <div className="col-12 col-md-11">
                <div className="row">
                  <div className="d-flex d-sm-none EditMember__jobs__onlyMobile">
                    <div className="contentText col-6">
                      <p>{t('member.list.job.default')}</p>
                    </div>
                    <div className="contentActions col-6">
                      <CheckboxControl
                        control={control}
                        name={`jobsArray.${index}.isHighlighted`}
                        value={watchJobs[index].isHighlighted || false}
                        key={`${watchJobs}`}
                        onChange={(value) => handleCheck(value, index)}
                      />
                      <button
                        className="non-styled-btn deleteButtonMobile"
                        onClick={() => remove(index)}
                      >
                        <Icon name="delete_outline" />
                      </button>
                    </div>
                  </div>
                  <div className="col-12 col-md-5">
                    <FormTextInput
                      control={control}
                      name={`jobsArray.${index}.jobTitle`}
                      label={t('member.edit.job.label')}
                      placeholder={t('member.edit.job.placeholder')}
                      type="text"
                      onChange={() => trigger('jobsArray')}
                    />
                  </div>
                  <div className="col-12 col-md-5">
                    <FormTextInput
                      control={control}
                      name={`jobsArray.${index}.company`}
                      label={t('member.edit.company.label')}
                      placeholder={t('member.edit.company.placeholder')}
                      type="text"
                      onChange={() => trigger('jobsArray')}
                    />
                  </div>
                  <div className="col-12 d-block d-sm-none">
                    <hr />
                  </div>
                  <div className="contActions col-2 d-none d-sm-block">
                    <div className="row">
                      <div className="col-6">
                        <button
                          className="non-styled-btn deleteButton"
                          onClick={() => remove(index)}
                        >
                          <Icon name="delete_outline" />
                        </button>
                      </div>

                      <div className="col-6">
                        <div className="contCheck">
                          <CheckboxControl
                            control={control}
                            name={`jobsArray.${index}.isHighlighted`}
                            value={watchJobs[index].isHighlighted}
                            onChange={(value) => handleCheck(value, index)}
                            disabled={isCheckDisabled(index)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-11">
            <button
              className="moreButton"
              onClick={() =>
                append({
                  company: '',
                  jobTitle: '',
                  isHighlighted: false,
                  id: '',
                })
              }
            >
              {t('member.list.job.add')}
              <Icon name={'add'} />
            </button>
          </div>
        </div>
        <FormActions>
          <div className="EditMember__actions">
            <button
              type="button"
              onClick={() => navigate(`${basePath}/member`)}
              className="btn btn-outline-primary mr-2"
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn-primary"
              disabled={!isValid || validateSave() || validateCheck()}
              onClick={handleSubmit(onSubmit)}
            >
              {isSubmitting ? <Loader /> : t('common.save')}
            </button>
          </div>
        </FormActions>
      </FormProvider>
    </Main>
  )
}

export default EditMember
