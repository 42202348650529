import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isNullOrEmpty } from '@liveconnect/communities-ui'
import {
  FormTextarea,
  FormProvider,
  Loader,
  Avatar,
} from '@liveconnect/components'

import usePosts from '../../../core/posts/usePosts'
import useMember from '../../../core/member/useMember'
import useActivities from '../../../core/activities/useActivities'
import { CommentPostInterface } from '../../../core/posts/types'
import { buildValidationSchema } from './validations'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'
import useGTM from '../../../utils/useGTM'

import './styles.scss'

interface PostData {
  content_title: string
  content_channel: string
  content_topic: string
  content_highlight: number
  content_author: string
  content_date: string
}
export interface MakeCommentProps {
  takenId: string
  inActivity?: boolean
  postData?: PostData | undefined
}

export const MakeCommentComponent: FC<MakeCommentProps> = ({
  takenId,
  inActivity,
  postData,
}): JSX.Element => {
  const { t } = useTranslation()
  const { createComment } = usePosts()
  const { createCommentActivity } = useActivities()
  const { member } = useMember()
  const { pushDataLayer } = useGTM()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const methods = useForm<{ message: string }>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid, isDirty },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)
  const watchMessage = watch('message')

  const handlePublish = async (
    takenId: string,
    comment: CommentPostInterface
  ) => {
    try {
      if (inActivity) {
        await createCommentActivity(takenId, comment)
      } else {
        await createComment(takenId, comment)
        pushDataLayer({
          event: 'comment_add_community',
          category: 'interaction',
          action: 'comment_add',
          extraParams: postData,
        })
      }

      setErrorMessage(undefined)
    } catch (error: unknown) {
      setErrorMessage((error as unknown as { message: string })?.message ?? '')
    }
  }

  const handleClick = async (content: { message: string }) => {
    setIsSubmitting(true)
    try {
      await handlePublish(takenId, { message: content.message })
      reset({ message: '' })
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="MakeComment">
      <div className="MakeComment__publisherImage">
        {member && (
          <Avatar
            name={member.name}
            surname={member.surname}
            imageUrl={member.imageUrl || undefined}
            size="md"
          />
        )}
      </div>
      <div className="MakeComment__container">
        <div className="MakeComment__container__body">
          <FormProvider methods={methods}>
            <FormTextarea
              control={control}
              name="message"
              label=""
              rows={2}
              placeholder={t('post.comment.create.message.placeholder')}
            />
          </FormProvider>
        </div>
        <div className="MakeComment__container__actions">
          {!isNullOrEmpty(watchMessage) && (
            <button
              className="btn btn-primary"
              onClick={handleSubmit(handleClick)}
              disabled={!isValid || isSubmitting || isNullOrEmpty(watchMessage)}
            >
              {isSubmitting ? <Loader /> : t('post.comment.publish')}
            </button>
          )}

          <span
            className={classNames('error', {
              show: !isNullOrEmpty(errorMessage),
            })}
          >
            {errorMessage}
          </span>
        </div>
      </div>
    </div>
  )
}
