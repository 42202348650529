import { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { getDateWithTimezoneDiff } from '@liveconnect/communities-ui'
import { Avatar } from '@liveconnect/components'

import useCulture from '../../utils/culture'
import { BadgeRecomendedMember } from '../../core/recomendedMembers/types'
import ViewBadges from '../ViewBadges'
import { useCustomRouter } from '../../utils/useCustomRouter'
import { returnHypphen } from '../../utils/showHyppen/showHyppen'

import './styles.scss'

interface UserSummaryProps {
  id: string
  creationDate?: string
  name: string
  surname: string
  imageUrl: string
  jobTitle: string
  company: string
  notClick?: boolean
  badges?: BadgeRecomendedMember[] | null
}

export enum EllapsedPeriod {
  NONE,
  MINUTES,
  HOURS,
  DAYS,
}
export interface RemainingTime {
  periodType: EllapsedPeriod
  period?: number | Date
}

const UserSummary: FC<UserSummaryProps> = ({
  id,
  creationDate,
  name,
  surname,
  imageUrl,
  company,
  jobTitle,
  badges,
  notClick,
}) => {
  const { t } = useTranslation()
  const { formatDate } = useCulture()
  const { basePath } = useCustomRouter()

  const showRemainingTime = (utcDate: Date): RemainingTime => {
    const date = getDateWithTimezoneDiff(utcDate)
    const ellapsedSeconds =
      (new Date().getTime() - new Date(date).getTime()) / 1000
    if (ellapsedSeconds < 60) return { periodType: EllapsedPeriod.NONE }
    const ellapsedMinutes = Math.floor(ellapsedSeconds / 60)
    if (ellapsedMinutes < 60) {
      return { periodType: EllapsedPeriod.MINUTES, period: ellapsedMinutes }
    }
    const ellapsedHours = Math.floor(ellapsedMinutes / 60)
    if (ellapsedHours < 24) {
      return { periodType: EllapsedPeriod.HOURS, period: ellapsedHours }
    }

    return {
      periodType: EllapsedPeriod.DAYS,
      period: getDateWithTimezoneDiff(date),
    }
  }

  const formatRemainingTime = (utcDate: Date): string => {
    const remainingTime = showRemainingTime(utcDate)
    switch (remainingTime.periodType) {
      case EllapsedPeriod.NONE:
        return t('post.comment.ellapsed.now')
      case EllapsedPeriod.MINUTES:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.minute')
        return t('post.comment.ellapsed.minutes', {
          minutes: remainingTime.period,
        })
      case EllapsedPeriod.HOURS:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.hour')
        return t('post.comment.ellapsed.hours', { hours: remainingTime.period })
      case EllapsedPeriod.DAYS:
      default:
        return formatDate(remainingTime.period as Date, 'LONG_DATE')
    }
  }

  return (
    <div className="UserSummary ">
      {notClick ? (
        <div className="d-flex">
          <Avatar name={name} surname={surname} imageUrl={imageUrl} size="lg" />
          <div className="UserSummary__info">
            {creationDate && (
              <div className="UserSummary__date">
                {formatRemainingTime(new Date(creationDate))}
              </div>
            )}
            <div className="UserSummary__name">
              {name} {surname}
            </div>
            <div className="UserSummary__job">
              {jobTitle} {returnHypphen(jobTitle, company)} {company}
            </div>
            {badges && badges.length > 0 && <ViewBadges badges={badges} />}
          </div>
        </div>
      ) : (
        <Link to={`${basePath}/networking/${id}`}>
          <div className="d-flex">
            <Avatar
              name={name}
              surname={surname}
              imageUrl={imageUrl}
              size="lg"
            />
            <div className="UserSummary__info">
              {creationDate && (
                <div className="UserSummary__date">
                  {formatRemainingTime(new Date(creationDate))}
                </div>
              )}
              <div className="UserSummary__name">
                {name} {surname}
              </div>
              <div className="UserSummary__job">
                {jobTitle} {returnHypphen(jobTitle, company)} {company}
              </div>
              {badges && badges.length > 0 && <ViewBadges badges={badges} />}
            </div>
          </div>
        </Link>
      )}
    </div>
  )
}

export default UserSummary
