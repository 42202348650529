import { FC, useEffect, useState } from 'react'

import { EventSummary } from '../../../core/events/types'
import useEvents from '../../../core/events/useEvents'

import './styles.scss'
import { EventsBannerComponent } from '../../../components/EventsBanner'

const EventsList: FC = (): JSX.Element => {
  const { getSummary } = useEvents()
  const [summary, setSummary] = useState<EventSummary | undefined>()

  const fetchSummary = async () => {
    const result = await getSummary()
    setSummary(result)
  }

  useEffect(() => {
    fetchSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <EventsBannerComponent summary={summary} />
}

export default EventsList
