import { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Icon } from '@liveconnect/icons'
import { Tooltip } from '@liveconnect/components'
import { FilterButton } from '@liveconnect/communities-ui'

import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
// eslint-disable-next-line import/no-unresolved
import { NavigationOptions } from 'swiper/types'
import { Navigation, Swiper as SwiperType } from 'swiper'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Chip from '../../../components/Chip'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import FilterPanel from '../FilterPanel'
import useMember from '../../../core/member/useMember'
import { MemberChannel } from '../../../core/member/types'
import { useSwiperRef } from '../../../utils/useSwiperRef'

import './styles.scss'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css'
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation'

const ChannelsFilter: FC = () => {
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const [showPanel, setShowPanel] = useState<boolean>(false)
  const { member, memberThemes, activateChannel } = useMember()
  const [nextEl, nextElRef] = useSwiperRef<HTMLElement>()
  const [prevEl, prevElRef] = useSwiperRef<HTMLElement>()
  const swiperRef = useRef<SwiperType | null>(null)

  const activeThemesCount = useMemo(
    () => memberThemes.filter((item) => item.isActive).length,
    [memberThemes]
  )
  const initialSlide = useMemo<number>(() => {
    if (!member) return 0
    const active = member.channels.find((c) => c.isEnabled)
    if (!active) return 0
    return member.channels.indexOf(active)
  }, [member])

  const settings: SwiperProps = {
    slidesPerView: 'auto' as const,
    modules: [Navigation],
    navigation: {
      nextEl,
      prevEl,
    } as NavigationOptions,
    direction: 'horizontal' as const,
    onSwiper: (swiper: SwiperType) => (swiperRef.current = swiper),
    initialSlide,
  }

  return (
    <>
      <div className="row ChannelsFilters d-none d-md-flex mb-4">
        <div className="col-md-2">
          <h5>
            {t('home.my_channels')}
            <Tooltip content={t('Channels_filter.tooltip')}>
              <Icon name="info_outline" tabIndex={0} />
            </Tooltip>
          </h5>
        </div>
        <div className="col-md-7 ChannelsFilters__filters">
          {member?.channels && (
            <>
              <span className="swiper-left-arrow" ref={prevElRef}>
                <Icon name="chevron_left" />
              </span>
              <Swiper {...settings}>
                {member.channels.map((channel: MemberChannel) => (
                  <SwiperSlide key={channel.id}>
                    <Chip
                      label={channel.name}
                      isActive={channel.isEnabled || false}
                      onClick={() => {
                        if (channel.isEnabled) return
                        activateChannel(channel.id)
                      }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <span className="swiper-right-arrow" ref={nextElRef}>
                <Icon name="chevron_right" />
              </span>
            </>
          )}
        </div>
        <div className="col-md-3 ChannelsFilters__actions">
          <FilterButton
            isActive={activeThemesCount > 0}
            label={t('home.filters')}
            onClick={() => setShowPanel(true)}
          />

          <Link className="btn btn-link" to={`${basePath}/channels`}>
            {t('home.go_to_channels')}
            <Icon name="keyboard_arrow_right" />
          </Link>
        </div>
      </div>
      <div className="ChannelsFilters mb-4 ChannelsMobile d-flex d-md-none">
        <div className="d-flex align-items-center">
          <h5>
            {t('home.my_channels')}
            <Tooltip content={t('Channels_filter.tooltip')}>
              <Icon name="info_outline" tabIndex={0} />
            </Tooltip>
          </h5>
        </div>
        <div className="d-flex ChannelsMobile__icons">
          <FilterButton
            isActive={activeThemesCount > 0}
            label={t('home.filters')}
            onClick={() => setShowPanel(true)}
          />
          <Link to={`${basePath}/channels`} className="more">
            <Icon name="add" />
          </Link>
        </div>
      </div>
      <div className="ChannelsFiltMobile mb-4  d-flex d-md-none row">
        {member?.channels && (
          <Swiper {...settings}>
            {member.channels.map((channel: MemberChannel) => (
              <SwiperSlide key={channel.id}>
                <Chip
                  key={channel.id}
                  label={channel.name}
                  isActive={channel.isEnabled || false}
                  onClick={() => {
                    if (channel.isEnabled) return
                    activateChannel(channel.id)
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <FilterPanel isOpen={showPanel} onClose={() => setShowPanel(false)} />
    </>
  )
}

export default ChannelsFilter
