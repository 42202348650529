/* eslint-disable jsx-a11y/anchor-has-content */
import { FC, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FormCheckbox, Loader } from '@liveconnect/components'

import { yupResolver } from '@hookform/resolvers/yup'

import useTopics from '../../../core/topicFamilies/useTopicFamilies'
import { Topic } from '../../../core/topicFamilies/types'
import FormActions from '../../../components/FormActions'
import { Step2Form } from '../types'
import { onboardingStep2ValidationSchema } from './validations'
import { IsoCode } from '../../../core/member/types'

import topicPlaceholder from '../../../../assets/images/topic-placeholder.png'

import './styles.scss'

interface Step2Props {
  isSubmitting: boolean
  setShowConfirmModal: (v: boolean) => void
  finalStep: (step2: Step2Form) => void
  language: IsoCode
}

const Step2: FC<Step2Props> = ({
  isSubmitting,
  setShowConfirmModal,
  finalStep,
  language,
}) => {
  const { t } = useTranslation()
  const { getTopics, isLoaded } = useTopics()
  const [topics, setTopics] = useState<Topic[]>()

  const methods = useForm<Step2Form>({
    mode: 'onChange',
    resolver: yupResolver(onboardingStep2ValidationSchema(t)),
  })
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = methods

  const fetchTopics = async () => {
    const response = await getTopics(language)
    setTopics(response)
  }

  useEffect(() => {
    fetchTopics()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <div className="formContainer Step2">
      <div className="row d-block d-md-none">
        <div className="col-12 Step2__textMobile">
          <span>*</span>
          {t('common.mandatory_fields')}
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-9">
          <div className="sectionTitles">{t('onboarding.select_channels')}</div>
        </div>
        <div className="col-md-3 mandatory d-none d-md-block">
          <span>*</span>
          {t('common.mandatory_fields')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <p>{t('onboarding.join_channels')}</p>
        </div>
      </div>
      {!isLoaded ? (
        <Loader />
      ) : (
        <div className="row topics">
          <div className="col-12">
            <div className="Step2__list d-flex flex-wrap">
              {(topics || []).map((topic, index) => (
                <div className="Step2__list__topic" key={index}>
                  <img src={topic.imageUrl || topicPlaceholder} alt="title" />
                  <div className="Step2__list__topic__content">
                    <div className="Step2__list__topic__content__title">
                      {topic.name}
                    </div>
                    <div className="Step2__list__topic__content__channels">
                      {topic.channels.map((channel) => (
                        <FormCheckbox
                          key={channel.id}
                          control={control}
                          name={`channels.${channel.id}`}
                          label={channel.name}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <FormActions>
        <div className="formContainer__actions">
          <button
            type="button"
            onClick={() => setShowConfirmModal(true)}
            className="btn btn-outline-primary"
          >
            {t('common.cancel')}
          </button>
          <button
            className="btn btn-primary"
            disabled={!isValid}
            onClick={handleSubmit(finalStep)}
          >
            {isSubmitting ? <Loader /> : t('common.continue')}
          </button>
        </div>
      </FormActions>
    </div>
  )
}

export default Step2
