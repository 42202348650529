import { TypePublishEnum } from '@liveconnect/communities-ui'

export interface Post {
  id: string
  tenantId: string
  title: string
  message: string
  image: string
  video: string
  publisher: Publisher
  channels: PostChannel[]
  themes: PostTheme[]
  comments: Comment[]
  totalComments: number
  createdAt: Date
  publishedAt: string
  reaction: Reactions
  isEditable: boolean
  isDeletable: boolean
  originalLanguage: string
  isSponsored: boolean
  sponsor: PublishersSponsored | null
}

export interface PublishersSponsored {
  image: string
  name: string
  slogan: string
  url: string
}

export interface Comment {
  id: string
  publisher: Publisher
  creationDate: string
  message: string
  reaction: Reactions
  isEditable: boolean
  isDeletable: boolean
  originalLanguage: string
  sponsor: PublishersSponsored | null
}

export interface CommentWithDate extends Omit<Comment, 'creationDate'> {
  creationDate: Date
}

export interface Reactions {
  fingerUpCount: number
  likeCount: number
  myReaction: ReactionTypeEnum
}

export enum ReactionTypeEnum {
  NONE = 'None',
  FINGERUP = 'FingerUp',
  LIKE = 'Like',
}

export interface BadgePublisher {
  id: string
  title: string
  imageUrl: string
}

export interface Publisher {
  title: string
  description: string
  image: string
  id: string
  externalId: string
  type: TypePublishEnum
  badges: BadgePublisher[]
  isAnonymousMember: boolean
}

export interface CreatePostInterface {
  title: string
  message: string
  image: string
  imagePath: string
  publisherType: TypePublishEnum
  publisherId: string
  themesIds: string[]
  channelsIds: string[]
  video: string
}

export interface CommentPostInterface {
  message: string
}

export interface CommentPutInterface {
  id: string
  message: string
}

export interface PostParams {
  page?: number
  page_size?: number
}

export interface PostListResponse {
  items: Post[]
  page: number
  pageSize: number
  availableItems: number
  availablePages: number
}

export interface PostListPayload {
  list: Post[]
  page: number
  pageSize: number
  hasNextPage: boolean
}

export interface PostImage {
  uri: string
}
export interface PostTheme {
  id: string
  name: string
}
export interface PostChannel {
  id: string
}

export interface PostsState {
  detail: Post | null
  list: Post[]
  page: number
  pageSize: number
  hasNextPage: boolean
  isLoaded: boolean
}

export interface SetPostCommentsPayload {
  postId: string
  list: Comment[]
}

export interface DeleteCommentPayload {
  postId: string
  commentId: string
}

export interface UpdateCommentPayload {
  postId: string
  comment?: Comment
}

export interface UsePostsResult {
  post: Post | null
  postList: Post[]
  isLoaded: boolean
  hasNextPostsPage: boolean
  fetchPosts: (params?: PostParams) => Promise<void>
  fetchNextPagePosts: (params?: PostParams) => Promise<void>
  createPost: (body: CreatePostInterface) => Promise<void>
  deletePost: (id: string) => Promise<void>
  uploadPostImage: (image: string) => Promise<PostImage | undefined>
  fetchPostComments: (postId: string) => void
  createComment: (
    postId: string,
    commentToSave: CommentPostInterface
  ) => Promise<void>
  deleteComment: (postId: string, id: string) => Promise<void>
  updatePostReaction: (
    postId: string,
    reaction: ReactionTypeEnum
  ) => Promise<Reactions | undefined>
  updateCommentReaction: (
    postId: string,
    commentId: string,
    reaction: ReactionTypeEnum
  ) => Promise<Reactions | undefined>
  updatePost: (
    postId: string,
    body: CreatePostInterface
  ) => Promise<Post | undefined>
  updateComment: (
    postId: string,
    body: CommentPutInterface
  ) => Promise<Comment | undefined>
  getTranslatePost: (
    brandId: string,
    lang: string
  ) => Promise<TranslatePost | undefined>
  getTranslateComment: (
    commentId: string,
    lang: string
  ) => Promise<TranslatePost | undefined>
  viewMorePostSponsored: (postId: string) => Promise<void>
  viewPostSponsor: (postId: string) => Promise<void>
}

export interface DataEditProps {
  id: string
  title: string
  message: string
  image: string
  video: string
  themes: Theme[] | MemberTheme[]
}

export interface Theme {
  id?: string
  name: string
}

export interface MemberTheme {
  id: string
  name: string
  isActive: boolean
}

export interface TranslatePost {
  title: string
  message: string
}

export enum TransLangs {
  ES = 'es',
  EN = 'en',
}
