import { useMemo } from 'react'
import TagManager from 'react-gtm-module'
import useMember from '../core/member/useMember'
import useTenantConfig from '../core/tenantConfig/useTenantConfig'

interface PushDataLayerProps {
  event: string
  category: 'interaction' | 'navigation' | 'conversion'
  action?: string
  pageDigitalActive?: string
  extraParams?: any
}

const useGTM = () => {
  const { member } = useMember()
  const { tenantConfig } = useTenantConfig()

  const userProfileChannels = useMemo(() => {
    return (member?.channels ?? []).map((channel) => channel.id).join('|')
  }, [member])

  const pageChannel = useMemo(() => {
    return (member?.channels ?? []).find((channel) => channel.isEnabled)?.id
  }, [member])

  const pushDataLayer = ({
    event,
    category,
    action,
    pageDigitalActive = 'community',
    extraParams,
  }: PushDataLayerProps) => {
    if (member && tenantConfig?.googleTagManagerAccount) {
      TagManager.dataLayer({
        dataLayer: {
          event,
          category,
          action,
          page_digital_active: pageDigitalActive,
          page_channel: pageChannel,
          user_id: member.id,
          user_profile_channels: userProfileChannels,
          user_language: member.isoCode,
          user_job: member.positions.map((p) => p.jobTitle).join(','),
          user_company: member.positions.map((p) => p.company).join(','),
          user_type: 'user',
          ...extraParams,
        },
      })
    }
  }

  return {
    pushDataLayer,
  }
}

export default useGTM
