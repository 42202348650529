import useFetch from '../../utils/fetch/useFetch'
import { EventSummary, UseEventsResult } from './types'

const useLocalizations = (): UseEventsResult => {
  const { get } = useFetch()

  const endpoint = 'events/summary'

  const getSummary = async () => {
    const response: EventSummary | undefined = await get({ endpoint })
    return response
  }

  const getSummaryBrand = async (code: string, id?: string) => {
    const route = `events/${code}/summary`
    const response: EventSummary | undefined = await get({
      endpoint: route,
      params: { brandSpaceId: id },
    })
    return response
  }

  return {
    getSummary,
    getSummaryBrand,
  }
}

export default useLocalizations
