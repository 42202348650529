import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import ProfileScreen from '../pages/Networking/Detail'

const NetworkingRouter: FC = () => {
  return (
    <Routes>
      <Route path="/:profileId" element={<ProfileScreen />} />
    </Routes>
  )
}

export default NetworkingRouter
