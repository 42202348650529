import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import { Topic, TopicsState } from './types'

const initialState: TopicsState = {
  detail: null,
  list: [],
  isLoaded: false,
}

export const topicsSlice = createSlice({
  name: 'topics',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<Array<Topic>>) => {
      state.list = action.payload
    },
  },
})

export const { showLoader, hideLoader, setList } = topicsSlice.actions

export const selectTopics = (state: RootState) => state.topics

export default topicsSlice.reducer
