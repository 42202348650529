import { FC, Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Navigation } from 'swiper'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Icon } from '@liveconnect/icons'
import { Loader, Avatar, TagLink } from '@liveconnect/components'

import { useCustomRouter } from '../../utils/useCustomRouter'
import { Main } from '../../components/Main'
import { PostContainer } from '../../containers/Post'
import ChannelsFilter from './ChannelsFilter'
import useMember from '../../core/member/useMember'
import usePosts from '../../core/posts/usePosts'
import CreatePost from './CreatePost'
import { DataEditProps } from '../../core/posts/types'
import EventsList from './EventsList'
import useUi from '../../core/ui/useUi'
import useDirectories from '../../core/directories/useDirectories'
import AdSlot from '../../containers/AdSlot'
import useIsMobile from '../../utils/useIsMobile'

import './styles.scss'

const HomeScreen: FC = () => {
  const { t } = useTranslation()
  const { member, memberThemes, fetchMemberThemes } = useMember()
  const { basePath } = useCustomRouter()
  const {
    fetchChannelDirectories,
    isLoaded: directoriesAreLoaded,
    channel,
  } = useDirectories()
  const { postList, fetchPosts, fetchNextPagePosts, hasNextPostsPage } =
    usePosts()
  const [showCreatePostModal, setShowCreatePostModal] = useState<boolean>(false)
  const [dataPostState, setDataPostState] = useState<
    DataEditProps | undefined
  >()
  const { showConfirmation } = useUi()
  const isMobile = useIsMobile()
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const activeChannel = useMemo(
    () => (member?.channels || []).filter((item) => item.isEnabled)[0],
    [member?.channels]
  )
  const openPostModal = (dataPost?: DataEditProps) => {
    dataPost ? setDataPostState(dataPost) : setDataPostState(undefined)
    setShowCreatePostModal(true)
  }

  const handleCreate = async (themesIds: string[]) => {
    const isAllSelected = memberThemes.every((theme) => !theme.isActive)
    const someThemeNotSelected = memberThemes.some(
      (theme) => !theme.isActive && themesIds.includes(theme.id)
    )
    if (!isAllSelected && someThemeNotSelected) {
      await fetchMemberThemes()
      showConfirmation({
        title: t('common.warning'),
        subtitle: t('post.alertThemeSelected.subtitle'),
        text: t('post.alertThemeSelected.text'),
        confirmText: t('common.accept'),
        iconName: 'report_problem',
        hideCancel: true,
      })
    }
    fetchPosts()
  }

  useEffect(() => {
    fetchChannelDirectories()
  }, [activeChannel])

  useEffect(() => {
    if (member === null) return
    fetchPosts()
  }, [memberThemes])

  return (
    <Main ariaLabelledby="home">
      <ChannelsFilter />
      {activeChannel && (
        <div className="row">
          <div className="col-lg-2 d-none d-lg-block">
            <EventsList />
          </div>
          <div className="col-lg-7">
            {directoriesAreLoaded ? (
              channel.length > 0 && (
                <div className="swiper-custom-navigation">
                  <span className="swiper-left-arrow" ref={navigationPrevRef}>
                    <Icon name="chevron_left" />
                  </span>
                  <Swiper
                    style={{ zIndex: 0 }}
                    slidesPerView={'auto'}
                    spaceBetween={16}
                    freeMode
                    modules={[Navigation]}
                    navigation={{
                      prevEl: navigationPrevRef.current,
                      nextEl: navigationNextRef.current,
                    }}
                    className="mb-4"
                  >
                    {channel.map((directory) => (
                      <SwiperSlide key={directory.id} style={{ width: 'auto' }}>
                        <TagLink
                          navigateTo={`${basePath}/directories/${directory.id}`}
                        >
                          {directory.name}
                        </TagLink>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                  <span className="swiper-right-arrow" ref={navigationNextRef}>
                    <Icon name="chevron_right" />
                  </span>
                </div>
              )
            ) : (
              <Loader />
            )}
            <button
              className="Home__create-post"
              onClick={() => openPostModal()}
            >
              <div className="Home__create-post__avatar">
                {member && (
                  <Avatar
                    name={member.name}
                    surname={member.surname}
                    imageUrl={member.imageUrl || undefined}
                    size="md"
                  />
                )}
              </div>
              <div className="Home__create-post__fakeInput">
                {t('post.create_text')}
              </div>
            </button>
            <InfiniteScroll
              dataLength={postList.length}
              next={() => fetchNextPagePosts()}
              hasMore={hasNextPostsPage}
              loader={<Loader />}
              className="Home__content"
              scrollableTarget="scrollable"
            >
              {postList &&
                postList.map((post, index) => (
                  <Fragment key={post.id}>
                    <PostContainer
                      goDetail={true}
                      {...post}
                      openEdit={openPostModal}
                      originalLanguage={post.originalLanguage}
                    />
                    {isMobile &&
                      (index === 2 ||
                        (postList.length < 3 &&
                          index === postList.length - 1)) && (
                        <AdSlot
                          context={activeChannel.id}
                          section="comunity365Wall"
                          container="sideBarRight"
                          device="mobile"
                          id="homeMobile"
                          className="Home__list__adSlot"
                        />
                      )}
                  </Fragment>
                ))}
              {isMobile && postList.length === 0 && (
                <AdSlot
                  context={activeChannel.id}
                  section="comunity365Wall"
                  container="sideBarRight"
                  device="mobile"
                  id="homeMobileEmpty"
                  className="Home__list__adSlot"
                />
              )}
            </InfiniteScroll>
          </div>
          <div className="col-lg-3 d-none d-md-block">
            {!isMobile && (
              <AdSlot
                context={activeChannel.id}
                section="comunity365Wall"
                container="sideBarRight"
                device="desktop"
                id="homeDesktop"
              />
            )}
          </div>
        </div>
      )}
      {showCreatePostModal && (
        <CreatePost
          onClose={() => {
            setShowCreatePostModal(false)
          }}
          onCreate={(themesIds) => handleCreate(themesIds)}
          dataPost={dataPostState}
        />
      )}
    </Main>
  )
}

export default HomeScreen
