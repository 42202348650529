import { CheckboxControl, SelectControl, Loader } from '@liveconnect/components'
import { FC, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import useAuth, { postLogoutRedirectPathKey } from '../../core/auth/useAuth'
import useLocalizations from '../../core/Localizations/useLocalizations'
import useMember from '../../core/member/useMember'
import useTermsConditions from '../../core/termsConditions/useTermsConditions'
import useUi from '../../core/ui/useUi'
import { DEFAULT_LANG } from '../../i18n/config'

import './styles.scss'

interface Option {
  value: string
  label: string
}

const TermsConditionsModal: FC = () => {
  const { getLocalizations } = useLocalizations()
  const { tenantId } = useParams()
  const { t } = useTranslation()
  const { member } = useMember()
  const { logout } = useAuth()
  const { showConfirmation } = useUi()
  const [localizations, setLocalizations] = useState<Option[]>()
  const [isoCode, setIsoCode] = useState<string>(
    member && member.isoCode ? member.isoCode : DEFAULT_LANG
  )

  const [isAccepted, setIsAccepted] = useState<boolean>(false)

  const {
    isVisible,
    isReadOnly,
    termsConditions,
    isSigned,
    isLoaded,
    showModal,
    hideModal,
    setReadOnly,
    fetchTermsConditions,
    signTermsConditions,
  } = useTermsConditions()

  const handleShow = () => {
    showModal()
  }

  const handleHide = () => {
    hideModal()
  }

  const handleLogout = () => {
    sessionStorage.setItem(postLogoutRedirectPathKey, `/t/${tenantId}`)
    logout()
  }

  const handleCancel = () => {
    handleHide()
    showConfirmation({
      title: t('common.warning'),
      subtitle: t('termsConditionsModal.cancelModal.text'),
      iconName: 'report_problem',
      onConfirm: () => handleShow(),
      onCancel: () => handleLogout(),
    })
  }

  const handleConfirm = async () => {
    signTermsConditions()
    handleHide()
  }

  const handleChangeLocalization = (value: string) => {
    setIsoCode(value)
  }

  const fetchLocalizations = async () => {
    const localizations = await getLocalizations()
    setLocalizations(
      (localizations ?? []).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }))
    )
  }

  const getTermsConditions = () => {
    if (member && termsConditions != null) {
      const termIsoCode = termsConditions?.find(
        (term) => term.isoCode == isoCode
      )
      if (termIsoCode) return termIsoCode.value
    }

    return ''
  }

  useEffect(() => {
    if (isVisible) {
      fetchLocalizations()
      if (termsConditions == null) fetchTermsConditions()
    }
  }, [isVisible])

  useEffect(() => {
    if (isLoaded && !isSigned) {
      setReadOnly(false)
      handleShow()
    } else {
      setReadOnly(true)
    }
  }, [isSigned, isLoaded])

  return (
    <Modal
      show={isVisible}
      size="lg"
      onHide={handleHide}
      className="TermsConsModal"
      keyboard={isReadOnly}
      backdrop={isReadOnly ? undefined : 'static'}
      backdropClassName="TermsConsModalBackdrop"
    >
      <Modal.Header className="justify-content-around">
        <Modal.Title>{t('termsConditionsModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {localizations && (
          <div className="d-flex justify-content-end">
            <SelectControl
              className="no-borders"
              name="isoCode"
              value={isoCode}
              onChange={handleChangeLocalization}
              label=""
              hiddenLabel
              noOptionsMessage=""
              options={localizations}
              isClearable={false}
            />
          </div>
        )}

        <div className="scrollable-content">
          {termsConditions ? (
            <div
              dangerouslySetInnerHTML={{ __html: getTermsConditions() }}
            ></div>
          ) : (
            <Loader />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="privacy-policies-modal__footer">
        {!isReadOnly && (
          <CheckboxControl
            label={t('termsConditionsModal.checkbox')}
            name={'accept'}
            value={isAccepted}
            onChange={(newValue) => setIsAccepted(newValue)}
          />
        )}
        <div className="privacy-policies-modal__footer-actions">
          {isReadOnly && (
            <button className="btn btn-primary flex-none" onClick={handleHide}>
              {t('common.close')}
            </button>
          )}
          {!isReadOnly && (
            <>
              <button
                className="btn btn-outline-primary"
                onClick={handleCancel}
              >
                {t('common.cancel')}
              </button>
              <button
                className="btn btn-primary"
                onClick={handleConfirm}
                disabled={!isAccepted}
              >
                {t('common.accept')}
              </button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default TermsConditionsModal
