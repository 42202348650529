import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { LogoImage } from '../../components/LogoImage'

import useTermsConditions from '../../core/termsConditions/useTermsConditions'
import usePrivacyPolicies from '../../core/privacyPolicies/usePrivacyPolicies'
import useConfiguration from '../../core/configuration/useConfiguration'
import useTenantConfig from '../../core/tenantConfig/useTenantConfig'

import { FooterSocialMedia } from '../../core/configuration/types'
import { CustomIconName } from '../../components/CustomIcon/types'
import CustomIcon from '../../components/CustomIcon'
import useMember from '../../core/member/useMember'

import './styles.scss'

const Footer: FC = () => {
  const { t } = useTranslation()
  const { showModal: showModalTermsConditions } = useTermsConditions()
  const { showModal: showModalPrivacyPolicies } = usePrivacyPolicies()
  const { footer, fetchFooterConfiguration } = useConfiguration()
  const { member } = useMember()
  const { tenantConfig } = useTenantConfig()

  useEffect(() => {
    fetchFooterConfiguration()
  }, [member?.isoCode])

  return (
    <footer className="footer">
      <div className="footer-general">
        <div className="footer-general__container container-fluid-xxl">
          <div className="footer-general__wrapper-links">
            <div className="footer-general__content">
              <h4 className="mb-4">
                {footer?.footerGeneralInfo?.sectionTitle}
              </h4>
              <div className="qa-footer-aboutifema-links footer-general__links--row">
                {footer?.footerGeneralInfo &&
                  footer?.footerGeneralInfo.pages.map(
                    ({ url, title }, index) => (
                      <FooterLink
                        key={`${url}-${index}`}
                        href={url}
                        title={title}
                      />
                    )
                  )}
              </div>
            </div>
            <div className="footer-general__content">
              <h4 className="mb-4">
                {footer?.footerContactInfo?.sectionTitle}
              </h4>
              <div className="qa-footer-contact-links footer-general__links--row">
                {footer?.footerContactInfo &&
                  footer?.footerContactInfo.pages.map(
                    ({ url, title }, index) => (
                      <FooterLink
                        key={`${url}-${index}`}
                        href={url}
                        title={title}
                      />
                    )
                  )}
              </div>
            </div>
            <div className="footer-general__content footer-general__content--social">
              <h4 className="mb-4">
                {footer?.footerSocialMedia?.sectionTitle}
              </h4>
              <div className="qa-footer-social-links footer-general__links footer-general__links--row">
                {footer && footer.footerSocialMedia && (
                  <FooterSocialMediaIcons {...footer.footerSocialMedia} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-legal">
        <div className="footer-legal__container container-fluid-xxl">
          {tenantConfig?.tenantLogoUrl && (
            <LogoImage
              className="footer-legal__logo pe-lg-4 pe-0"
              src={tenantConfig?.tenantLogoUrl}
              alt="Ifema"
            />
          )}
          <div className="footer-legal__links">
            {footer?.footerLegalInfo &&
              footer?.footerLegalInfo.pages.map(({ url, title }, index) => (
                <FooterLink key={`${url}-${index}`} href={url} title={title} />
              ))}
            <a
              tabIndex="0"
              role="button"
              className="footer__text-link footer__text-link--legal"
              onClick={showModalTermsConditions}
            >
              {t('footer.conditions')}
            </a>
            <a
              tabIndex="0"
              role="button"
              className="footer__text-link footer__text-link--legal"
              onClick={showModalPrivacyPolicies}
            >
              {t('footer.privacy')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

interface FooterLinkProps {
  href: string
  title: string
}

const FooterLink: FC<FooterLinkProps> = ({ href, title }) => {
  if (href.includes(window.location.host)) {
    return (
      <Link className="footer__text-link" to={href}>
        {title}
      </Link>
    )
  } else {
    return (
      <a
        href={href}
        target="_blank"
        className="footer__text-link"
        rel="noopener noreferrer"
      >
        {title}
      </a>
    )
  }
}

const FooterSocialMediaIcons: FC<FooterSocialMedia> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { language, sectionTitle, children, ...socialLinks } = props
  const icons: Record<string, CustomIconName> = {
    linkedinUrl: 'linkedin',
    twitterUrl: 'twitter',
    youtubeUrl: 'youtube',
    facebookUrl: 'facebook',
    instagramUrl: 'instagram',
  }
  return (
    <>
      {Object.entries(socialLinks).map(
        ([key, value]) =>
          value && (
            <a key={key} href={value} target="_blank" rel="noreferrer">
              <CustomIcon name={icons[key]} />
            </a>
          )
      )}
    </>
  )
}

export default Footer
