import useTenantConfig from '../tenantConfig/useTenantConfig'
import { UseAdManagerResult } from './types'

const MOCK_SIZE = ['fluid', [288, 480], [320, 500]]
const MOCK_APPLICATION = 'community365'

const useAdManager = (): UseAdManagerResult => {
  const { tenantConfig } = useTenantConfig()
  const getContainerSize = (container: string) => {
    return MOCK_SIZE
  }

  return {
    accountId: tenantConfig?.googleAdManagerAccount,
    application: MOCK_APPLICATION,
    getContainerSize,
  }
}

export default useAdManager
