import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'

import {
  ConfigurationFooterResponse,
  ConfigurationOnboardingResponse,
  ConfigurationState,
} from './types'

const initialState: ConfigurationState = {
  isLoaded: false,
  footer: null,
  onboarding: null,
}

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setFooterConfiguration: (
      state,
      action: PayloadAction<ConfigurationFooterResponse>
    ) => {
      state.footer = action.payload
    },
    setOnboardingConfiguration: (
      state,
      action: PayloadAction<ConfigurationOnboardingResponse>
    ) => {
      state.onboarding = action.payload
    },
  },
})

export const {
  showLoader,
  hideLoader,
  setFooterConfiguration,
  setOnboardingConfiguration,
} = configurationSlice.actions

export const selectConfiguration = (state: RootState) => state.configuration

export default configurationSlice.reducer
