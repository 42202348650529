import { FC, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Pagination, Loader, ButtonAsync } from '@liveconnect/components'

import EmptyState from '../../../components/EmptyState'
import UserSummary from '../../../components/UserSummary'
import AdSlot from '../../../containers/AdSlot'
import { ContactRequest } from '../../../core/contactRequests/types'
import useContactRequests from '../../../core/contactRequests/useContactRequests'
import useSettings from '../../../core/settings/useSettings'
import useUi from '../../../core/ui/useUi'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useGTM from '../../../utils/useGTM'
import useIsMobile from '../../../utils/useIsMobile'
import useScroll from '../../../utils/useScroll'
import BoxText from '../BoxText'

const ReceivedList: FC = () => {
  const { t } = useTranslation()
  const notify = useSnackBars()
  const { showConfirmation } = useUi()
  const {
    contactRequestList,
    page,
    availablePages,
    availableItems,
    pageSize,
    isLoaded,
    fetchContactRequests,
    clearContactRequestList,
    rejectContactRequest,
    acceptContactRequest,
  } = useContactRequests('Received')
  const { scrollTop } = useScroll()
  const { pushDataLayer } = useGTM()
  const isMobile = useIsMobile()
  const { settings } = useSettings()

  const handleConfirmIgnore = (id: string, name: string) => {
    showConfirmation({
      title: t('contacts.receivedList.confirm.title'),
      subtitle: t('contacts.receivedList.confirm.subtitle', { name }),
      text: t('contacts.receivedList.confirm.text'),
      confirmText: t('common.ignore'),
      iconName: 'report_problem',
      onConfirm: () => handleIgnore(id),
    })
  }

  const handleIgnore = async (id: string) => {
    try {
      await rejectContactRequest(id)
      pushDataLayer({
        event: 'contact_ignore_community',
        category: 'interaction',
        action: 'contact_ignore',
      })
      notify.success(t('contacts.receivedList.ignore.successFeedback'))
    } catch (e) {
      notify.error(t('contacts.receivedList.ignore.unknownError'))
    } finally {
      fetchContactRequests({ page: 1 })
    }
  }

  const handleAccept = async (id: string) => {
    try {
      await acceptContactRequest(id)
      pushDataLayer({
        event: 'contact_accept_community',
        category: 'interaction',
        action: 'contact_accept',
      })
      notify.success(t('contacts.receivedList.accept.successFeedback'))
    } catch (e) {
      notify.error(t('contacts.receivedList.accept.unknownError'))
    } finally {
      fetchContactRequests({ page: 1 })
    }
  }

  useEffect(() => {
    scrollTop()
  }, [page])

  useEffect(() => {
    fetchContactRequests({ page: 1 })
    return clearContactRequestList
  }, [])

  return (
    <div>
      {!isLoaded ? (
        <Loader />
      ) : (
        <section className="mt-3">
          {!contactRequestList.length ? (
            <>
              <EmptyState text={t('contacts.receivedList.empty.title')} />
              {isMobile && (
                <AdSlot
                  context={settings?.environment}
                  section="comunity365Networking"
                  container="sideBarRight"
                  device="mobile"
                  id="receivedMobileEmpty"
                  className="ContactRequests__list__adSlot"
                />
              )}
            </>
          ) : (
            <div className="ContactRequests__list">
              {contactRequestList.map((item: ContactRequest, index: number) => (
                <Fragment key={item.id}>
                  <div className="ContactRequests__item">
                    <div className="actions">
                      <UserSummary
                        creationDate={item.creationDate}
                        {...item.user}
                        badges={item.user.badges}
                        notClick={!item.user.isRegister}
                      />
                      <div>
                        <button
                          className="btn btn-outline-primary"
                          onClick={() =>
                            handleConfirmIgnore(
                              item.id,
                              `${item.user.name} ${item.user.surname}`
                            )
                          }
                        >
                          {t('common.ignore')}
                        </button>

                        <ButtonAsync
                          className="btn btn-primary"
                          onClick={() => handleAccept(item.id)}
                        >
                          {t('common.accept')}
                        </ButtonAsync>
                      </div>
                    </div>
                    {item.message && <BoxText message={item.message} />}
                  </div>
                  {isMobile &&
                    (index === 2 ||
                      (contactRequestList.length < 3 &&
                        index === contactRequestList.length - 1)) && (
                      <AdSlot
                        context={settings?.environment}
                        section="comunity365Networking"
                        container="sideBarRight"
                        device="mobile"
                        id="receivedMobile"
                        className="ContactRequests__list__adSlot"
                      />
                    )}
                </Fragment>
              ))}
              <Pagination
                currentPage={page - 1}
                totalPages={availablePages}
                totalItems={availableItems}
                pageSize={pageSize}
                singlePageText={(count) =>
                  t('common.pager.singlePage', { count })
                }
                multiplePageText={(start, end, total) =>
                  t('common.pager.multiplePage', { start, end, total })
                }
                onChange={(pageIndex: number) =>
                  fetchContactRequests({ page: pageIndex + 1 })
                }
              />
            </div>
          )}
        </section>
      )}
    </div>
  )
}

export default ReceivedList
