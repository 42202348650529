import { FC } from 'react'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import useCulture from '../../../utils/culture'
import { Brand } from '../../../core/brandSpaces/types'

import PlaceholderImage from '../../../../assets/images/event-placeholder.svg'
import PlaceholderImageHome from '../../../../assets/images/event-placeholder-home.svg'
import './styles.scss'

interface EventProps {
  name: string
  linkUrl: string
  from: string
  to: string
  imageUrl?: string
  withButton: boolean
  className?: string
  brand?: Brand
  isHome?: boolean
}

const EventBannerItemComponent: FC<EventProps> = ({
  name,
  linkUrl,
  from,
  to,
  imageUrl,
  withButton,
  className,
  brand,
  isHome = true,
}): JSX.Element => {
  const { t } = useTranslation()
  const { formatDate } = useCulture()
  const fromString = formatDate(new Date(from), 'SHORT_DATE')
  const toString = formatDate(new Date(to), 'SHORT_DATE')

  return (
    <div className={classNames('Event', className)}>
      {imageUrl != undefined && (
        <a
          href={linkUrl}
          title={linkUrl}
          target="_blank"
          rel="noreferrer"
          className={classNames({
            'Event__image-container': true,
            'Event__image-container--rampant': brand,
          })}
        >
          {imageUrl && (
            <img
              className="Event__image"
              src={imageUrl}
              alt={t('event.image-alt')}
            />
          )}
          {imageUrl == null && (
            <img
              className="Event__image"
              src={isHome ? PlaceholderImageHome : PlaceholderImage}
              alt={t('event.image-alt')}
            />
          )}
          {brand?.profileImageUrl && (
            <div className="Event__brand-image">
              <img src={brand?.profileImageUrl} alt={brand?.title} />
            </div>
          )}
        </a>
      )}

      <a
        className="btn btn-link text-start"
        href={linkUrl}
        title={linkUrl}
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </a>
      <div className="Event__dates">
        <Icon name="event" className="icon--disabled" />
        &nbsp;{t('event.dates', { from: fromString, to: toString })}
      </div>
      {withButton && (
        <div className="Event__button">
          <a
            className="btn btn-primary"
            href={linkUrl}
            title={t('events.join-event')}
            target="_blank"
            rel="noreferrer"
          >
            {t('events.join-event')}
          </a>
        </div>
      )}
    </div>
  )
}

export default EventBannerItemComponent
