import { FC, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { Loader } from '@liveconnect/components'

import { Main } from '../../components/Main'
import { ExternalLink } from '../../components/ExternalLink'
import BackButton from '../../components/Buttons/BackButton'
import useBrands from '../../core/brandSpaces/useBrandSpaces'
import { Section, Sponsor } from '../../core/brandSpaces/types'

import './styles.scss'
const SponsorsScreen: FC = () => {
  const { brand, fetchBrandDetail, isLoaded: isLoadedBrand } = useBrands()
  const { brandId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    brandId && fetchBrandDetail(brandId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId])

  const navigateBack = () => {
    navigate('../')
  }

  const renderSponsor = (sponsor: Sponsor) => {
    return (
      <div key={sponsor.id} className="col-6 col-lg-3 colImg">
        <div className="sponsors_body_contImg">
          {sponsor.websiteUrl !== '' ? (
            <ExternalLink href={sponsor.websiteUrl}>
              <img
                alt={sponsor.name}
                title={sponsor.name}
                src={sponsor.logoUrl}
              />
            </ExternalLink>
          ) : (
            <img
              alt={sponsor.name}
              title={sponsor.name}
              src={sponsor.logoUrl}
            />
          )}
        </div>
      </div>
    )
  }
  return (
    <Main ariaLabelledby="sponsors" className="sponsors">
      <div className="row d-block d-lg-none">
        <BackButton label={t('common.back')} onClick={navigateBack} />
      </div>
      <div className="row sponsors_header">
        {!isLoadedBrand ? (
          <Loader />
        ) : (
          <div className="sponsors_body">
            {brand &&
              brand.sections &&
              ([...brand.sections] || [])
                .sort((a, b) => a.index - b.index)
                .map((section: Section) => (
                  <div key={section.id} className="col-12">
                    <h3>{section.name}:</h3>
                    <div className="row">
                      {section &&
                        ([...section.sponsors] || [])
                          .sort((a, b) => a.index - b.index)
                          .map((sponsor: Sponsor) => renderSponsor(sponsor))}
                    </div>
                    {section.subSections &&
                      ([...section.subSections] || [])
                        .sort((a, b) => a.index - b.index)
                        .map((subsection) => (
                          <div key={subsection.id} className="col-12">
                            <h4>{subsection.name}:</h4>
                            <div className="row">
                              {subsection &&
                                ([...subsection.sponsors] || [])
                                  .sort((a, b) => a.index - b.index)
                                  .map((sponsor) => renderSponsor(sponsor))}
                            </div>
                          </div>
                        ))}
                  </div>
                ))}
          </div>
        )}
      </div>
    </Main>
  )
}

export default SponsorsScreen
