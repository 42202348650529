import { createSlice } from '@reduxjs/toolkit'

import { Confirmation, UiState } from './types'

const initialConfirmation: Confirmation = {
  isVisible: false,
  title: '',
  subtitle: '',
  text: '',
  confirmText: '',
  cancelText: '',
  onConfirm: null,
  onCancel: null,
  hideCancel: false,
}

const initialState: UiState = {
  isPanelVisibleRight: false,
  isPanelVisibleLeft: false,
  confirmation: initialConfirmation,
}

export const UiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showPanelRight: (state) => {
      state.isPanelVisibleRight = true
    },
    hidePanelRight: (state) => {
      state.isPanelVisibleRight = false
    },
    showPanelLeft: (state) => {
      state.isPanelVisibleLeft = true
    },
    hidePanelLeft: (state) => {
      state.isPanelVisibleLeft = false
    },
    showConfirmation: (state, action) => {
      state.confirmation = { ...action.payload, isVisible: true }
    },
    hideConfirmation: (state) => {
      state.confirmation = initialConfirmation
    },
  },
})

export const {
  showPanelRight,
  hidePanelRight,
  showPanelLeft,
  hidePanelLeft,
  showConfirmation,
  hideConfirmation,
} = UiSlice.actions

export default UiSlice.reducer
