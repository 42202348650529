import * as yup from 'yup'
import { TFunction } from 'react-i18next'
import useCulture from '../../../utils/culture'

export function onboardingStep1ValidationSchema(
  t: TFunction
): yup.AnyObjectSchema {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatDate } = useCulture()
  return yup
    .object()
    .shape({
      name: yup
        .string()
        .label(t('member.edit.name.label'))
        .max(30, ({ label }) =>
          t('validations.maxLength', { label, maxLength: 30 })
        )
        .required(({ label }) => t('validations.required', { label })),
      surname: yup
        .string()
        .label(t('member.edit.surname.label'))
        .max(70, ({ label }) =>
          t('validations.maxLength', { label, maxLength: 70 })
        )
        .required(({ label }) => t('validations.required', { label })),
      positions: yup
        .array()
        .min(1)
        .of(
          yup.object({
            jobTitle: yup
              .string()
              .label(t('member.edit.job.label'))
              .max(200, ({ label }) =>
                t('validations.maxLength', { label, maxLength: 200 })
              )
              .test(
                'company-required',
                t('validations.required', {
                  label: t('member.edit.job.label').toLowerCase(),
                }),
                function (value, context) {
                  return (
                    (!!value && !!!context.parent.company) ||
                    (!!!value && !!context.parent.company) ||
                    (!!value && !!context.parent.company)
                  )
                }
              )
              .test(
                'jobs-company',
                t('validations.duplicateField', {
                  label: t('member.edit.job.label').toLowerCase(),
                }),
                (value, context) => duplicatedJobs(value, context)
              ),
            company: yup
              .string()
              .label(t('member.edit.company.label'))
              .max(200, ({ label }) =>
                t('validations.maxLength', { label, maxLength: 200 })
              )
              .test(
                'jobTitle-required',
                t('validations.required', {
                  label: t('member.edit.company.label').toLowerCase(),
                }),
                function (value, context) {
                  return (
                    (!!value && !!!context.parent.jobTitle) ||
                    (!!!value && !!context.parent.jobTitle) ||
                    (!!value && !!context.parent.jobTitle)
                  )
                }
              )
              .test(
                'jobs-company',
                t('validations.duplicateField', {
                  label: t('member.edit.company.label').toLowerCase(),
                }),
                (value, context) => duplicatedJobs(value, context)
              ),
          })
        ),
      description: yup
        .string()
        .max(1000, ({ label }) =>
          t('validations.maxLength', { label, maxLength: 1000 })
        )
        .label(t('member.edit.description.label'))
        .required(({ label }) => t('validations.required', { label })),
      dateOfBirth: yup
        .date()
        .default(new Date())
        .label(t('member.edit.dateOfBirth'))
        .typeError(t('validations.date.format'))
        .max(new Date(), ({ label }) =>
          t('validations.date.max', {
            label,
            date: formatDate(new Date(), 'LONG_DATE'),
          })
        )
        .required(({ label }) => t('validations.required', { label })),
      imageUrl: yup.string(),
    })
    .required()
}

const duplicatedJobs = (
  _: string | undefined,
  context: yup.TestContext
): boolean => {
  const currentValues = context.from[1].value.positions
  const names = currentValues.map(
    ({ jobTitle, company }: { jobTitle: string; company: string }) =>
      `c_${jobTitle.toLowerCase()}-e_${company.toLocaleLowerCase()}`
  )
  const duplicates = names.filter(
    (item: string, index: number) => names.indexOf(item.toLowerCase()) !== index
  )
  const _value = `c_${context.parent.jobTitle}-e_${context.parent.company}`
  return !duplicates.includes(_value?.toLowerCase())
}
