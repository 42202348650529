import { FC, DetailedHTMLProps, AnchorHTMLAttributes } from 'react'

import { sanitizeUrl } from '../../utils/urlSanitizer'

type ExternalLinkProps = DetailedHTMLProps<
  AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

export const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  href,
  ...props
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={sanitizeUrl(href as string)}
      {...props}
    >
      {children}
    </a>
  )
}
