import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ActivitiesScreen } from '../pages/Activities'
import { ActivityScreen } from '../pages/Activities/Activity'

const ActivitiesRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ActivitiesScreen />} />
      <Route path=":activityId" element={<ActivityScreen />} />
    </Routes>
  )
}

export default ActivitiesRouter
