import { use } from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from '../locales/es-ES.json'
import en from '../locales/en-GB.json'

export const DEFAULT_LANG = 'es-ES'

use(initReactI18next).init({
  fallbackLng: DEFAULT_LANG,
  resources: {
    es: { translation: es },
    en: { translation: en },
  },
  supportedLngs: ['es', 'en'],
  nonExplicitSupportedLngs: true,
  interpolation: {
    escapeValue: false,
  },
})
