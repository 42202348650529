import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  ContextMenu,
  isNullOrEmpty,
  getDateWithTimezoneDiff,
  TypePublishEnum,
} from '@liveconnect/communities-ui'
import {
  FormTextarea,
  FormProvider,
  Loader,
  Avatar,
} from '@liveconnect/components'

import useUi from '../../../core/ui/useUi'
import usePosts from '../../../core/posts/usePosts'
import useCulture from '../../../utils/culture'
import { buildValidationSchema } from './validation'
import { ReactionComponent } from '../Reaction'
import {
  Publisher,
  PublishersSponsored,
  Reactions,
  TransLangs,
  TranslatePost,
} from '../../../core/posts/types'
import {
  calculateShowMore,
  EllapsedPeriod,
  getTextWithEllipsis,
  showRemainingTime,
} from '../utils'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'

import useSnackBars from '../../../utils/notifications/useNotifications'
import useActivities from '../../../core/activities/useActivities'
import { TranslateComment } from '../../../core/activities/types'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import { useNavigate } from 'react-router-dom'
import useMember from '../../../core/member/useMember'
import ViewBadges from '../../../components/ViewBadges'
import ArrowButton from '../../../components/Buttons/ArrowButton'

import './styles.scss'

export interface CommentComponentProps {
  takenId: string
  id: string
  publisher: Publisher
  creationDate: string
  message: string
  reaction: Reactions
  isEditable: boolean
  isDeletable: boolean
  originalLangComment: string
  isoTransProp: string | undefined
  inActivity?: boolean
  sponsor: PublishersSponsored | null
}

export const CommentComponent: FC<CommentComponentProps> = ({
  id,
  creationDate,
  message,
  publisher,
  takenId,
  reaction,
  isEditable,
  isDeletable,
  originalLangComment,
  isoTransProp,
  inActivity,
  sponsor,
}): JSX.Element => {
  const { t } = useTranslation()
  const { showConfirmation } = useUi()
  const { formatDate } = useCulture()
  const { deleteComment, updateComment, getTranslateComment } = usePosts()
  const {
    deleteCommentActivity,
    updateCommentActivity,
    translateCommentActivity,
  } = useActivities()
  const [showEditComment, setShowEditComment] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [showComplete, setShowComplete] = useState<boolean>(false)
  const [showExpand, setShowExpand] = useState<boolean>(false)
  const [showTrans, setShowTrans] = useState<boolean>(false)
  const [textTrans, setTextTrans] = useState<string | undefined>(undefined)
  const notify = useSnackBars()
  const { basePath } = useCustomRouter()
  const { member } = useMember()
  const navigate = useNavigate()
  const textContainerRef = useRef<HTMLDivElement>(null)
  const textParentRef = useRef<HTMLDivElement>(null)
  const methods = useForm<{ message: string }>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: { message },
  })
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid, isDirty },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const showButtonTranslate =
    originalLangComment && originalLangComment !== isoTransProp

  const formatRemainingTime = (utcDate: Date): string => {
    const remainingTime = showRemainingTime(utcDate)
    switch (remainingTime.periodType) {
      case EllapsedPeriod.NONE:
        return t('post.comment.ellapsed.now')
      case EllapsedPeriod.MINUTES:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.minute')
        return t('post.comment.ellapsed.minutes', {
          minutes: remainingTime.period,
        })
      case EllapsedPeriod.HOURS:
        if (remainingTime.period === 1) return t('post.comment.ellapsed.hour')
        return t('post.comment.ellapsed.hours', { hours: remainingTime.period })
      case EllapsedPeriod.DAYS:
      default:
        return formatDate(remainingTime.period as Date, 'LONG_DATE')
    }
  }

  const handleUpdateComment = async (content: { message: string }) => {
    setIsSubmitting(true)
    setErrorMessage(undefined)
    try {
      if (inActivity) {
        await updateCommentActivity(takenId, {
          message: content.message,
          id,
        })
      } else {
        await updateComment(takenId, {
          message: content.message,
          id,
        })
      }
      setTextTrans(undefined)
      if (showTrans) setShowTrans(false)
      setErrorMessage(undefined)
      setShowEditComment(false)
      reset()
    } catch (error: unknown) {
      setErrorMessage((error as unknown as { message: string })?.message ?? '')
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleCancelEdit = () => {
    reset()
    setShowEditComment(false)
  }

  const confirmDelete = (takenId: string, id: string) => {
    if (inActivity) {
      return deleteCommentActivity(takenId, id)
    }
    return deleteComment(takenId, id)
  }

  const commentActions = useMemo(() => {
    const actions = []
    if (isEditable)
      actions.push({
        label: t('post.comment.actions.edit'),
        onClick: () => {
          setValue('message', message)
          setShowEditComment(true)
        },
      })
    if (isDeletable)
      actions.push({
        label: t('post.comment.actions.delete'),
        onClick: () => {
          showConfirmation({
            title: t('post.comment.actions.delete'),
            subtitle: t('post.comment.confirmation.delete.subtitle'),
            text: t('post.comment.confirmation.delete.text'),
            iconName: 'report_problem',
            onConfirm: () => confirmDelete(takenId, id),
          })
        },
      })
    return actions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable, isDeletable, message])

  const sendLang = () => {
    if (isoTransProp === TransLangs.ES) {
      return TransLangs.ES
    }
    return TransLangs.EN
  }

  const translateComment = async () => {
    try {
      if (!showTrans && !textTrans) {
        let responseTrans: TranslateComment | any | TranslatePost
        if (inActivity) {
          responseTrans = await translateCommentActivity(
            id,
            takenId,
            sendLang()
          )
        } else {
          responseTrans = await getTranslateComment(id, sendLang())
        }

        setTextTrans(responseTrans?.translateContent)
      }
      setShowTrans(!showTrans)
    } catch (e) {
      notify.error(t('post.translate.error'))
    }
  }

  const navigateToProfile = () => {
    if (sponsor) {
      window.open(sponsor.url, '_blank')
    } else {
      let route = ''
      if (publisher.type === TypePublishEnum.Tenant) return
      if (publisher.type !== TypePublishEnum.Brand) {
        if (member?.id !== publisher.externalId) {
          route = 'networking'
          return navigate(`${basePath}/${route}/${publisher.externalId}`)
        }
        route = 'member'
        return navigate(`${basePath}/${route}`)
      }
      route = 'brands'
      return navigate(`${basePath}/${route}/${publisher.externalId}`)
    }
  }

  useEffect(() => {
    if (textParentRef.current === null || textContainerRef.current === null)
      return
    const _showExpand = calculateShowMore(
      textParentRef.current,
      textContainerRef.current,
      message
    )

    if (!_showExpand) {
      setShowComplete(false)
    }

    setShowExpand(_showExpand)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textContainerRef.current, textParentRef.current, message])

  useEffect(() => {
    if (!textContainerRef?.current?.firstChild) return
    const textContainer = textContainerRef.current.firstChild
    if (showExpand && !showComplete)
      textContainer.textContent = getTextWithEllipsis(message)
    else textContainer.textContent = message
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, showComplete, showExpand, textContainerRef.current])

  return (
    <div id={id} className="Comment">
      <div className="Comment__publisherImage">
        <Avatar
          name={sponsor ? sponsor.name : publisher.title}
          surname={''}
          imageUrl={sponsor ? sponsor.image : publisher.image}
          size="md"
          alt={sponsor ? sponsor.name : publisher.title}
        />
      </div>
      <div className="Comment__container">
        <div className="Comment__container__body">
          <div className="Comment__container__body__meta">
            <div className="Comment__container__body__meta__publisher">
              <span
                className={`publisherName publisherName--${publisher.type}`}
                onClick={navigateToProfile}
                aria-hidden="true"
              >
                {sponsor ? sponsor.name : publisher.title}
              </span>
              <span className="publisherDescription">
                {sponsor ? sponsor.slogan : publisher.description}
              </span>
              {publisher.badges && publisher.badges.length > 0 && (
                <ViewBadges badges={publisher.badges} />
              )}

              <span className="d-block d-sm-none timePostMobile">
                {formatRemainingTime(new Date(creationDate))}
              </span>
            </div>
            <div
              className="Comment__container__body__meta__date"
              title={formatDate(
                getDateWithTimezoneDiff(new Date(creationDate)),
                'LONG_DATE_TIME'
              )}
            >
              <span className="d-none d-sm-block timePost">
                {formatRemainingTime(new Date(creationDate))}
              </span>
              {commentActions.length > 0 && !showEditComment && (
                <div className="Comment__container__body__meta__actions">
                  <ContextMenu items={commentActions} />
                </div>
              )}
            </div>
          </div>
          {!showEditComment && (
            <div
              ref={textParentRef}
              className={classNames('Comment__container__body__content', {
                full: showComplete || showTrans,
              })}
              title={message}
            >
              <div
                ref={textContainerRef}
                className="Comment__container__body__content__textContainer"
              >
                <p>{message}</p>
                {showExpand && (
                  <div className="containerLink">
                    <ArrowButton
                      onClick={() => setShowComplete(!showComplete)}
                      label={
                        showComplete ? t('post.view_less') : t('post.view_more')
                      }
                      icon={
                        showComplete
                          ? 'keyboard_arrow_up'
                          : 'keyboard_arrow_down'
                      }
                    />
                  </div>
                )}
                {showTrans && (
                  <div className="Comment__container__body__content__textContainer__transText">
                    {textTrans}
                  </div>
                )}
                {showButtonTranslate && (
                  <div
                    className={
                      showTrans ? 'upButtonContainer contArrow' : 'contArrow'
                    }
                  >
                    <ArrowButton
                      onClick={translateComment}
                      label={
                        showTrans
                          ? t('post.close_translate')
                          : t('post.open_tranlate')
                      }
                      icon={
                        showTrans ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {showEditComment && (
            <div className="Comment__container__body__editor">
              <div className="Comment__container__body__editor__body">
                <FormProvider methods={methods}>
                  <FormTextarea
                    control={control}
                    name="message"
                    label=""
                    rows={2}
                    required={true}
                    placeholder={t('post.comment.create.message.placeholder')}
                  />
                </FormProvider>
              </div>
              <div className="Comment__container__body__editor__actions">
                <button
                  className="btn btn-outline-primary"
                  onClick={handleCancelEdit}
                >
                  {t('common.cancel')}
                </button>
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit(handleUpdateComment)}
                  disabled={!isValid || isSubmitting}
                >
                  {isSubmitting ? <Loader /> : t('common.save_changes')}
                </button>
              </div>
              <span
                className={classNames('error', {
                  show: !isNullOrEmpty(errorMessage),
                })}
              >
                {errorMessage}
              </span>
            </div>
          )}
        </div>
        <div className="Comment__container__reactions">
          <ReactionComponent
            isPost={false}
            takenId={takenId}
            commentId={id}
            disabled={showEditComment}
            inActivity={inActivity}
            {...reaction}
          />
        </div>
      </div>
    </div>
  )
}
