import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import { Brand, BrandsState } from './types'

const initialState: BrandsState = {
  detail: null,
  list: [],
  isLoaded: false,
}

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<Brand[]>) => {
      state.list = action.payload
    },
    setBrand: (state, action: PayloadAction<Brand>) => {
      state.detail = action.payload
    },
  },
})

export const { showLoader, hideLoader, setList, setBrand } = brandsSlice.actions

export const selectBrands = (state: RootState) => state.brands

export default brandsSlice.reducer
