import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../reduxProvider'
import { ElementListResponse, ElementsState, ElementDetail } from './types'

const initialState: ElementsState = {
  list: null,
  detail: null,
  isLoaded: false,
}

export const slice = createSlice({
  name: 'elements',
  initialState,
  reducers: {
    showLoader: (state) => {
      state.isLoaded = false
    },
    hideLoader: (state) => {
      state.isLoaded = true
    },
    setList: (state, action: PayloadAction<ElementListResponse>) => {
      state.list = action.payload
    },
    setDetail(state, action: PayloadAction<ElementDetail>) {
      state.detail = action.payload
    },
  },
})

export const { showLoader, hideLoader, setList, setDetail } = slice.actions

export const selectElements = (state: RootState) => state.elements

export default slice.reducer
