import { FC } from 'react'

import './styles.scss'

const FormActions: FC = ({ children }) => {
  return (
    <div className="lc-sticky-bar">
      <div></div>
      <div className="lc-sticky-bar__actions">{children}</div>
    </div>
  )
}

export default FormActions
