import { FC, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { SearchInput } from '@liveconnect/communities-ui'
import { Pagination, Loader } from '@liveconnect/components'

import EmptyState from '../../../components/EmptyState'
import useUi from '../../../core/ui/useUi'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useContacts from '../../../core/contacts/useContacts'
import { Contact } from '../../../core/contacts/types'
import UserSummary from '../../../components/UserSummary'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import useChat from '../../../core/chat/useChat'
import useScroll from '../../../utils/useScroll'
import useGTM from '../../../utils/useGTM'
import AdSlot from '../../../containers/AdSlot'
import useIsMobile from '../../../utils/useIsMobile'
import useSettings from '../../../core/settings/useSettings'

import './styles.scss'

const Networking: FC = () => {
  const { t } = useTranslation()
  const notify = useSnackBars()
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const { showConfirmation } = useUi()
  const { initChat } = useChat()
  const { scrollTop } = useScroll()
  const { pushDataLayer } = useGTM()
  const isMobile = useIsMobile()
  const { settings } = useSettings()

  const {
    contactList,
    page,
    availablePages,
    availableItems,
    pageSize,
    isLoaded,
    fetchContacts,
    clearContactList,
    deleteContact,
  } = useContacts()

  const handleConfirmDelete = (id: string) => {
    showConfirmation({
      title: t('networking.confirmDelete.title'),
      subtitle: t('networking.confirmDelete.subtitle'),
      text: t('networking.confirmDelete.text'),
      confirmText: t('common.delete'),
      iconName: 'report_problem',
      onConfirm: () => handleDelete(id),
    })
  }

  const handleDelete = async (id: string) => {
    try {
      await deleteContact(id)
      pushDataLayer({
        event: 'contact_delete_community',
        category: 'interaction',
        action: 'contact_delete',
      })
      notify.success(t('networking.delete.successFeedback'))
    } catch (e) {
      notify.error(t('networking.delete.unknownError'))
    } finally {
      fetchContacts({ page: 1 })
    }
  }

  const handleConnect = (id: string) => {
    navigate(`${basePath}/chat/${id}`)
  }

  useEffect(() => {
    scrollTop()
  }, [page])

  useEffect(() => {
    fetchContacts({ page: 1 })
    initChat()
    return clearContactList
  }, [])

  return (
    <>
      <div className="Networking__list__search">
        <SearchInput
          onSearch={(s: string) => {
            fetchContacts({ page: 1, search: s })
          }}
          defaultValue=""
          placeholder={t('common.search')}
        />
      </div>
      {!isLoaded ? (
        <Loader />
      ) : (
        <section className="mt-3">
          {!contactList.length ? (
            <>
              <EmptyState text={t('networking.emptyList')} />
              {isMobile && (
                <AdSlot
                  context={settings?.environment}
                  section="comunity365Networking"
                  container="sideBarRight"
                  device="mobile"
                  id="networkingMobileEmpty"
                  className="UserList__list__adSlot"
                />
              )}
            </>
          ) : (
            <div className="Networking__list">
              {contactList.map((item: Contact, index: number) => (
                <Fragment key={item.id}>
                  <div className="Networking__item">
                    <UserSummary
                      {...item.user}
                      badges={item.user.badges}
                      notClick={!item.user.isRegister}
                    />
                    <div>
                      <button
                        className="btn btn-outline-primary"
                        onClick={() => handleConfirmDelete(item.id)}
                      >
                        {t('networking.deleteContact')}
                      </button>
                      <button
                        className="btn btn-primary ms-md-4"
                        onClick={() => handleConnect(item.user.id)}
                      >
                        {t('networking.sendMessage')}
                      </button>
                    </div>
                  </div>
                  {isMobile &&
                    (index === 2 ||
                      (contactList.length < 3 &&
                        index === contactList.length - 1)) && (
                      <AdSlot
                        context={settings?.environment}
                        section="comunity365Networking"
                        container="sideBarRight"
                        device="mobile"
                        id="networkingMobile"
                        className="UserList__list__adSlot"
                      />
                    )}
                </Fragment>
              ))}
              <Pagination
                currentPage={page - 1}
                totalPages={availablePages}
                totalItems={availableItems}
                pageSize={pageSize}
                singlePageText={(count) =>
                  t('common.pager.singlePage', { count })
                }
                multiplePageText={(start, end, total) =>
                  t('common.pager.multiplePage', { start, end, total })
                }
                onChange={(pageIndex: number) =>
                  fetchContacts({ page: pageIndex + 1 })
                }
              />
            </div>
          )}
        </section>
      )}
    </>
  )
}

export default Networking
