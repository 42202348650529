import { FC, Fragment, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { HtmlSanitizer } from '@liveconnect/communities-ui'
import { Icon } from '@liveconnect/icons'
import { Loader, TagLink } from '@liveconnect/components'

import { Main } from '../../components/Main'
import { Section, Sponsor, Subsection } from '../../core/brandSpaces/types'
import useBrands from '../../core/brandSpaces/useBrandSpaces'
import usePosts from '../../core/posts/usePosts'
import { PostContainer } from '../../containers/Post'
import useDirectories from '../../core/directories/useDirectories'

import { ExternalLink } from '../../components/ExternalLink'

import { useCustomRouter } from '../../utils/useCustomRouter'
import BackButton from '../../components/Buttons/BackButton'
import EventsListBrand from './EventsListBrand'
import AdSlot from '../../containers/AdSlot'
import useIsMobile from '../../utils/useIsMobile'

import './styles.scss'

const BrandScreen: FC = () => {
  const { t } = useTranslation()
  const { brand, fetchBrandDetail, isLoaded: isLoadedBrand } = useBrands()
  const { brandId } = useParams()
  const { basePath } = useCustomRouter()
  const {
    isLoaded: directoriesAreLoaded,
    fetchBrandSpaceDirectories,
    brandSpace,
  } = useDirectories()
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)
  const isMobile = useIsMobile()

  const navigate = useNavigate()
  const { fetchPosts, fetchNextPagePosts, hasNextPostsPage, postList } =
    usePosts(`brand-spaces/${brandId}`)
  const navigateBack = () => {
    navigate('../')
  }

  const renderSponsor = (sponsor: Sponsor) => {
    return (
      <div key={sponsor.id} className="col-6">
        <div className="contImage">
          {sponsor.websiteUrl !== '' ? (
            <ExternalLink href={sponsor.websiteUrl}>
              <img
                alt={sponsor.name}
                title={sponsor.name}
                src={sponsor.logoUrl}
              />
            </ExternalLink>
          ) : (
            <img
              alt={sponsor.name}
              title={sponsor.name}
              src={sponsor.logoUrl}
            />
          )}
        </div>
      </div>
    )
  }
  const sortSections = useMemo(() => {
    if (brand?.sections) {
      return ([...brand.sections] || [])
        .sort((a, b) => a.index - b.index)
        .map(
          (section: Section) =>
            section.isFeatured && (
              <div key={section.id}>
                <h5
                  className={
                    section.sponsors.length > 0 ? '' : 'withoutContent'
                  }
                >
                  {section.name}:
                </h5>
                <div className="row">
                  {section &&
                    ([...section.sponsors] || [])
                      .sort((a, b) => a.index - b.index)
                      .map((sponsor: Sponsor) => renderSponsor(sponsor))}
                </div>
                {section.subSections &&
                  ([...section.subSections] || [])
                    .sort((a, b) => a.index - b.index)
                    .map((subsection: Subsection) => (
                      <div key={subsection.id} className="col-12">
                        <h6
                          className={
                            subsection.sponsors.length > 0
                              ? ''
                              : 'withoutContent'
                          }
                        >
                          {subsection.name}:
                        </h6>
                        <div className="row">
                          {subsection &&
                            ([...subsection.sponsors] || [])
                              .sort((a, b) => a.index - b.index)
                              .map((sponsor: Sponsor) =>
                                renderSponsor(sponsor)
                              )}
                        </div>
                      </div>
                    ))}
              </div>
            )
        )
    }
  }, [brand])

  useEffect(() => {
    fetchBrandDetail(brandId as string)
    fetchPosts()
  }, [])

  useEffect(() => {
    if (brandId) fetchBrandSpaceDirectories(brandId)
  }, [brandId])

  return (
    <Main ariaLabelledby="brand" className="brand">
      <div className="row d-block d-lg-none">
        <BackButton label={t('common.back')} onClick={navigateBack} />
      </div>
      {!isLoadedBrand ? (
        <Loader />
      ) : (
        <>
          <div className="row brand_header">
            {/* ONLY MOBILE */}
            <div className="col-12 col-lg-6 brand_header_image d-block d-lg-none">
              <img
                className="imageMobile"
                alt={brand?.name}
                src={brand?.headerImageUrl}
              />
            </div>
            {/* END ONLY MOBILE */}
            <div className="col-12 col-lg-6">
              <h3 className="brand_header_title">{brand?.title}</h3>
              <HtmlSanitizer
                className="description"
                html={brand?.description ?? ''}
              />
            </div>
            <div className="col-12 col-lg-6 brand_header_image d-none d-lg-block">
              <img alt={brand?.name} src={brand?.headerImageUrl} />
            </div>
          </div>
          <div className="row brand_body">
            <div className="col-12 col-lg-3">
              <EventsListBrand key={brand?.id} brand={brand} />

              <div className="d-none d-lg-block">
                {!isMobile && (
                  <AdSlot
                    context={brandId}
                    section="comunity365Spaces"
                    container="sideBarLeft"
                    device="desktop"
                    id="brandDesktop"
                  />
                )}
              </div>
              <div className="d-block d-lg-none brand_body_collaborators">
                {sortSections}
                {brand && brand.anySponsors && (
                  <div className="brand_body_collaborators_actions col-12">
                    <Link
                      to={`${basePath}/brands/${brandId}/sponsors`}
                      className="btn btn-primary onlyMobile"
                    >
                      {t('brand.go.sponsors')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-7">
              {directoriesAreLoaded ? (
                brandSpace.length > 0 && (
                  <div className="swiper-custom-navigation">
                    <span className="swiper-left-arrow" ref={navigationPrevRef}>
                      <Icon name="chevron_left" />
                    </span>
                    <Swiper
                      style={{ zIndex: 0 }}
                      slidesPerView={'auto'}
                      spaceBetween={16}
                      freeMode
                      modules={[Navigation]}
                      navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                      }}
                      className="mb-4"
                    >
                      {brandSpace.map((directory) => (
                        <SwiperSlide
                          key={directory.id}
                          style={{ width: 'auto' }}
                        >
                          <TagLink
                            navigateTo={`${basePath}/directories/${directory.id}`}
                          >
                            {directory.name}
                          </TagLink>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                    <span
                      className="swiper-right-arrow"
                      ref={navigationNextRef}
                    >
                      <Icon name="chevron_right" />
                    </span>
                  </div>
                )
              ) : (
                <Loader />
              )}
              <InfiniteScroll
                dataLength={postList.length}
                next={fetchNextPagePosts}
                hasMore={hasNextPostsPage}
                loader={<h4>{t('home.posts.loading')}</h4>}
                scrollableTarget="scrollable"
              >
                {postList &&
                  postList.map((post, index) => (
                    <Fragment key={post.id}>
                      <PostContainer
                        goDetail={post.isSponsored}
                        {...post}
                        isHighlighted={true}
                      />
                      {isMobile &&
                        (index === 2 ||
                          (postList.length < 3 &&
                            index === postList.length - 1)) && (
                          <AdSlot
                            context={brandId}
                            section="comunity365Spaces"
                            container="sideBarLeft"
                            device="mobile"
                            id="brandMobile"
                            className="brand__list__adSlot"
                          />
                        )}
                    </Fragment>
                  ))}
                {isMobile && postList.length === 0 && (
                  <AdSlot
                    context={brandId}
                    section="comunity365Spaces"
                    container="sideBarLeft"
                    device="mobile"
                    id="brandMobileEmpty"
                    className="brand__list__adSlot"
                  />
                )}
              </InfiniteScroll>
            </div>
            <div className="col-12 col-lg-2 d-none d-lg-block contSponsors brand_body_collaborators">
              {sortSections}
              {brand && brand.anySponsors && (
                <div
                  className={
                    brand && brand.sections && brand.sections.length > 0
                      ? 'brand_body_collaborators_actions col-12 withContent'
                      : 'brand_body_collaborators_actions col-12'
                  }
                >
                  <Link
                    to={`${basePath}/brands/${brandId}/sponsors`}
                    className="btn btn-primary"
                  >
                    {t('brand.go.sponsors')}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Main>
  )
}

export default BrandScreen
