import {
  FormTextarea,
  FormProvider,
  ButtonAsync,
} from '@liveconnect/components'
import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { useTranslation } from 'react-i18next'
import { CreateContactPayload } from '../../core/contactRequests/types'
import { buildValidationSchema } from './validations'

import './styles.scss'

interface ContactModalProps {
  onContact: (message: string | undefined, id: string) => void
  onClose: () => void
  contact: CreateContactPayload
}

const ContactModal: FC<ContactModalProps> = ({
  onContact,
  contact,
  onClose,
}) => {
  const { t } = useTranslation()
  const methods = useForm<{ message: string }>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
  })
  const { control, handleSubmit } = methods
  const handleClick = async (content: { message: string }) => {
    if (content.message && content.message !== '') {
      return onContact(content.message, contact.id)
    }
    return onContact(undefined, contact.id)
  }

  return (
    <Modal show={true} size="lg" className="ContactModal">
      <Modal.Header>
        <Modal.Title>
          {t('recomendedMembers.modal.title', { contact: contact.name })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="ContactModal__body">
        <FormProvider methods={methods}>
          {t('recomendedMembers.modal.text')}
          <FormTextarea
            control={control}
            name="message"
            label={t('recomendedMembers.modal.label')}
            rows={3}
            placeholder={t('recomendedMembers.modal.placeholder')}
          />
          <span className="max">
            {t('recomendedMembers.modal.max', { max: 300 })}
          </span>
        </FormProvider>
      </Modal.Body>
      <Modal.Footer className="ContactModal__footer">
        <button className="btn btn-outline-primary" onClick={() => onClose()}>
          {t('common.cancel')}
        </button>
        <ButtonAsync
          className="btn btn-primary"
          onClick={handleSubmit(handleClick)}
        >
          {t('recomendedMembers.list.connect')}
        </ButtonAsync>
      </Modal.Footer>
    </Modal>
  )
}

export default ContactModal
