import { configureStore } from '@reduxjs/toolkit'

import auth from '../auth/store'
import ui from '../ui/store'
import topics from '../topicFamilies/store'
import member from '../member/store'
import posts from '../posts/store'
import tenant from '../tenant/store'
import brands from '../brandSpaces/store'
import settings from '../settings/store'
import recomendedMembers from '../recomendedMembers/store'
import contactRequests from '../contactRequests/store'
import contacts from '../contacts/store'
import chat from '../chat/store'
import activities from '../activities/store'
import permissions from '../permissions/store'
import termsConditions from '../termsConditions/store'
import privacyPolicies from '../privacyPolicies/store'
import directories from '../directories/store'
import elements from '../elements/store'
import configuration from '../configuration/store'
import tenantConfig from '../tenantConfig/store'

export const store = configureStore({
  reducer: {
    auth,
    ui,
    topics,
    member,
    posts,
    tenant,
    brands,
    settings,
    recomendedMembers,
    contactRequests,
    contacts,
    chat,
    activities,
    permissions,
    termsConditions,
    privacyPolicies,
    directories,
    elements,
    configuration,
    tenantConfig,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export * from './hooks'
