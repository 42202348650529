import { FC } from 'react'

export const ApplicationLogo: FC = () => {
  return (
    <svg
      width="167"
      viewBox="0 0 167 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 18.6752V3.30094C0 3.15044 0.073444 3.0752 0.228063 3.0752H1.39157C1.54619 3.0752 1.6235 3.15044 1.6235 3.30094V17.4118L1.7124 17.5029H9.77964C9.9304 17.5029 10.0077 17.5782 10.0077 17.7287V18.6792C10.0077 18.8297 9.9304 18.9049 9.77964 18.9049H0.228063C0.073444 18.9009 0 18.8297 0 18.6752Z"
        fill="black"
      />
      <path
        d="M13.8926 18.6752V3.30094C13.8926 3.15044 13.966 3.0752 14.1206 3.0752H15.288C15.4388 3.0752 15.5161 3.15044 15.5161 3.30094V18.6792C15.5161 18.8297 15.4388 18.9049 15.288 18.9049H14.1206C13.9699 18.9009 13.8926 18.8297 13.8926 18.6752Z"
        fill="black"
      />
      <path
        d="M24.3835 18.7228L19.4009 3.32079L19.3777 3.2297C19.3777 3.12673 19.4473 3.07129 19.5826 3.07129H20.8156C20.9509 3.07129 21.0437 3.1307 21.0901 3.25347L25.2493 16.505L25.3189 16.5485L25.3885 16.505L29.5477 3.25347C29.5903 3.13466 29.683 3.07129 29.8222 3.07129H31.0321C31.1983 3.07129 31.2601 3.15446 31.2138 3.32079L26.1886 18.7228C26.1423 18.8416 26.0495 18.905 25.9142 18.905H24.6579C24.5188 18.901 24.4299 18.8416 24.3835 18.7228Z"
        fill="black"
      />
      <path
        d="M44.9052 4.47322H36.4939L36.4011 4.5643V10.1485L36.4939 10.2396H42.3887C42.5395 10.2396 42.6168 10.3148 42.6168 10.4653V11.4158C42.6168 11.5663 42.5395 11.6415 42.3887 11.6415H36.4939L36.4011 11.7326V17.4079L36.4939 17.499H44.9052C45.0559 17.499 45.1332 17.5742 45.1332 17.7247V18.6752C45.1332 18.8257 45.0559 18.9009 44.9052 18.9009H35.0096C34.8588 18.9009 34.7815 18.8257 34.7815 18.6752V3.30094C34.7815 3.15044 34.8588 3.0752 35.0096 3.0752H44.9052C45.0559 3.0752 45.1332 3.15044 45.1332 3.30094V4.25143C45.1332 4.40193 45.0559 4.47322 44.9052 4.47322Z"
        fill="black"
      />
      <path
        d="M84.5726 18.497C83.7802 18.0891 83.1656 17.5149 82.7326 16.7663C82.2997 16.0178 82.0833 15.1624 82.0833 14.2V7.75247C82.0833 6.78614 82.2997 5.93861 82.7326 5.2099C83.1656 4.47722 83.7802 3.91089 84.5726 3.50297C85.365 3.09505 86.2773 2.89307 87.3132 2.89307C88.3337 2.89307 89.2421 3.09505 90.0345 3.49109C90.8269 3.89109 91.4415 4.45346 91.8745 5.17822C92.3074 5.90297 92.5277 6.73861 92.5277 7.68515V7.70891L92.4582 7.89109L92.2997 7.95841L91.5459 8.00198C91.3913 8.00198 91.314 7.92673 91.314 7.77624V7.70891C91.314 6.57624 90.9506 5.6693 90.2162 4.98416C89.4818 4.29901 88.5193 3.95445 87.3132 3.95445C86.0956 3.95445 85.1215 4.29901 84.3909 4.98416C83.6565 5.6693 83.2931 6.57624 83.2931 7.70891V14.2673C83.2931 15.4 83.6604 16.3109 84.4025 17.004C85.1408 17.697 86.1111 18.0455 87.3132 18.0455C88.5154 18.0455 89.4818 17.697 90.2162 17.004C90.9468 16.3109 91.314 15.396 91.314 14.2673V14.2238C91.314 14.0851 91.3913 14.0218 91.5459 14.0218L92.2997 14.0653C92.4504 14.0653 92.5277 14.1327 92.5277 14.2673C92.5277 15.2178 92.3074 16.0574 91.8745 16.7901C91.4415 17.5228 90.8269 18.0891 90.0345 18.497C89.2421 18.9049 88.3376 19.1069 87.3132 19.1069C86.2811 19.1069 85.365 18.901 84.5726 18.497Z"
        fill="black"
      />
      <path
        d="M103.278 17.3526C103.85 16.9091 104.217 16.3229 104.387 15.6021C104.495 15.1942 104.545 14.3942 104.545 13.2061C104.545 11.9982 104.499 11.1982 104.406 10.81C104.24 10.0734 103.869 9.48331 103.297 9.04371C102.725 8.60807 102.029 8.39024 101.206 8.39024C100.382 8.39024 99.6904 8.60807 99.1261 9.04371C98.5617 9.47935 98.1984 10.0695 98.0283 10.81C97.9201 11.2774 97.8659 12.0774 97.8659 13.2061C97.8659 14.3348 97.9201 15.1348 98.0283 15.6021C98.1945 16.3388 98.5617 16.9289 99.1261 17.3645C99.6904 17.8001 100.382 18.0219 101.206 18.0219C102.017 18.0219 102.706 17.8001 103.278 17.3526ZM98.4651 18.2477C97.7036 17.6893 97.1856 16.9407 96.9112 16.01C96.7411 15.4081 96.6599 14.4655 96.6599 13.1823C96.6599 11.8992 96.745 10.9685 96.9112 10.3784C97.1856 9.44371 97.6997 8.70311 98.4535 8.15262C99.2073 7.60212 100.135 7.32886 101.229 7.32886C102.296 7.32886 103.216 7.61005 103.981 8.16846C104.75 8.72688 105.264 9.46351 105.523 10.3823C105.616 10.7308 105.678 11.119 105.705 11.5467C105.736 11.9784 105.752 12.5249 105.752 13.1863C105.752 13.8794 105.736 14.4378 105.705 14.8576C105.674 15.2813 105.616 15.6655 105.523 16.01C105.264 16.9447 104.75 17.6893 103.981 18.2477C103.212 18.8061 102.288 19.0833 101.206 19.0833C100.143 19.0833 99.2305 18.8061 98.4651 18.2477Z"
        fill="black"
      />
      <path
        d="M117.653 8.40981C118.376 9.11872 118.74 10.0534 118.74 11.2138V18.6752C118.74 18.8257 118.662 18.9009 118.512 18.9009H117.758C117.603 18.9009 117.53 18.8257 117.53 18.6752V11.4395C117.53 10.5484 117.255 9.81971 116.706 9.25733C116.158 8.691 115.442 8.40981 114.557 8.40981C113.645 8.40981 112.907 8.68308 112.342 9.23357C111.778 9.78407 111.496 10.5009 111.496 11.392V18.6752C111.496 18.8257 111.422 18.9009 111.268 18.9009H110.514C110.359 18.9009 110.282 18.8257 110.282 18.6752V7.73258C110.282 7.58208 110.359 7.50684 110.514 7.50684H111.268C111.418 7.50684 111.496 7.58208 111.496 7.73258V8.84149L111.531 8.9207L111.585 8.88505C111.921 8.38604 112.362 8.00585 112.911 7.74446C113.459 7.47911 114.089 7.34842 114.805 7.34842C115.98 7.34446 116.927 7.7009 117.653 8.40981Z"
        fill="black"
      />
      <path
        d="M130.838 8.40981C131.561 9.11872 131.925 10.0534 131.925 11.2138V18.6752C131.925 18.8257 131.847 18.9009 131.697 18.9009H130.943C130.792 18.9009 130.715 18.8257 130.715 18.6752V11.4395C130.715 10.5484 130.44 9.81971 129.891 9.25733C129.343 8.691 128.627 8.40981 127.742 8.40981C126.83 8.40981 126.092 8.68308 125.527 9.23357C124.963 9.78407 124.681 10.5009 124.681 11.392V18.6752C124.681 18.8257 124.607 18.9009 124.453 18.9009H123.695C123.54 18.9009 123.463 18.8257 123.463 18.6752V7.73258C123.463 7.58208 123.54 7.50684 123.695 7.50684H124.449C124.6 7.50684 124.677 7.58208 124.677 7.73258V8.84149L124.712 8.9207L124.766 8.88505C125.102 8.38604 125.543 8.00585 126.092 7.74446C126.641 7.47911 127.271 7.34842 127.986 7.34842C129.165 7.34446 130.112 7.7009 130.838 8.40981Z"
        fill="black"
      />
      <path
        d="M137.29 12.5051L137.379 12.5962H143.753L143.846 12.5051C143.846 11.7526 143.807 11.1863 143.73 10.8061C143.564 10.0813 143.196 9.49915 142.632 9.05559C142.068 8.60807 141.376 8.38628 140.552 8.38628C139.729 8.38628 139.045 8.60411 138.496 9.03183C137.947 9.46351 137.599 10.0298 137.445 10.7388C137.375 11.0279 137.321 11.614 137.29 12.5051ZM142.605 17.5348C143.115 17.21 143.513 16.7625 143.803 16.1883C143.881 16.0496 143.977 16.014 144.101 16.0774L144.696 16.3704C144.835 16.4457 144.87 16.5447 144.812 16.6635C144.476 17.4001 143.946 17.9902 143.223 18.4298C142.501 18.8655 141.65 19.0833 140.676 19.0833C139.609 19.0833 138.701 18.8298 137.955 18.315C137.209 17.8041 136.699 17.0992 136.424 16.212C136.304 15.8358 136.219 15.412 136.173 14.9447C136.126 14.4774 136.103 13.8912 136.103 13.1823C136.103 12.4457 136.115 11.8873 136.138 11.511C136.161 11.1348 136.212 10.7863 136.285 10.4695C136.528 9.51896 137.027 8.75856 137.781 8.18826C138.535 7.61401 139.458 7.32886 140.556 7.32886C141.805 7.32886 142.802 7.64965 143.552 8.28727C144.298 8.92886 144.762 9.81203 144.947 10.9447C144.99 11.2298 145.021 11.5665 145.036 11.9506C145.052 12.3348 145.06 12.814 145.06 13.3843C145.06 13.5348 144.986 13.61 144.831 13.61H137.383L137.294 13.7011C137.294 14.6675 137.332 15.3091 137.406 15.6259C137.572 16.3625 137.951 16.9487 138.539 17.3764C139.126 17.8041 139.868 18.0219 140.765 18.0219C141.48 18.0219 142.095 17.8596 142.605 17.5348Z"
        fill="black"
      />
      <path
        d="M150.923 18.2475C150.17 17.6891 149.663 16.9405 149.404 16.0099C149.207 15.2851 149.107 14.3425 149.107 13.1821C149.107 12.0693 149.203 11.1306 149.404 10.3782C149.648 9.45936 150.15 8.71877 150.912 8.16431C151.673 7.6059 152.597 7.32471 153.68 7.32471C154.758 7.32471 155.693 7.59401 156.478 8.13659C157.263 8.67916 157.761 9.36035 157.974 10.1722C158.036 10.3821 158.082 10.596 158.113 10.8059V10.8495C158.113 10.9564 158.044 11.0237 157.908 11.0554L157.178 11.1465H157.131C157.023 11.1465 156.957 11.0752 156.927 10.9445L156.857 10.6277C156.718 9.99401 156.362 9.46332 155.782 9.03164C155.203 8.59995 154.503 8.38609 153.68 8.38609C152.841 8.38609 152.145 8.60391 151.588 9.03956C151.032 9.4752 150.676 10.0574 150.525 10.7821C150.386 11.3564 150.32 12.1604 150.32 13.2019C150.32 14.2554 150.39 15.0554 150.525 15.598C150.676 16.3346 151.032 16.9247 151.588 17.3604C152.145 17.796 152.841 18.0178 153.68 18.0178C154.503 18.0178 155.206 17.8039 155.794 17.3722C156.382 16.9405 156.733 16.4019 156.857 15.7564L156.88 15.6415C156.911 15.4752 157 15.4079 157.155 15.4396L157.866 15.5544C158.017 15.5861 158.086 15.6653 158.071 15.8039L157.978 16.2118C157.765 17.0554 157.267 17.7445 156.482 18.2792C155.697 18.8138 154.766 19.0831 153.683 19.0831C152.593 19.0831 151.677 18.8059 150.923 18.2475Z"
        fill="black"
      />
      <path
        d="M166.768 8.52493H164.097L164.004 8.61602V15.7606C164.004 16.5289 164.178 17.0675 164.53 17.3764C164.882 17.6853 165.446 17.8398 166.223 17.8398H166.749C166.9 17.8398 166.977 17.915 166.977 18.0655V18.6754C166.977 18.8259 166.903 18.9012 166.749 18.9012L166.065 18.9249C164.998 18.9249 164.194 18.723 163.653 18.315C163.111 17.9071 162.833 17.1626 162.818 16.0774V8.61206L162.725 8.52493H161.353C161.198 8.52493 161.125 8.44968 161.125 8.29919V7.73285C161.125 7.58236 161.198 7.50711 161.353 7.50711H162.725L162.814 7.41602V4.8378C162.814 4.68731 162.891 4.61206 163.042 4.61206H163.772C163.923 4.61206 164 4.68731 164 4.8378V7.41602L164.093 7.50711H166.764C166.915 7.50711 166.992 7.58236 166.992 7.73285V8.29919C167 8.44572 166.923 8.52493 166.768 8.52493Z"
        fill="black"
      />
      <path
        d="M77.4023 18.909L77.4062 18.8971C73.6335 17.6258 70.9702 14.5684 70.9277 10.9961C70.9702 7.41986 73.6335 4.36639 77.4062 3.09114L77.4023 3.08322C77.8701 2.96837 78.218 2.55253 78.218 2.05748C78.218 1.47134 77.7386 0.996094 77.1472 0.996094L76.9617 1.0159V1.01194C69.8299 2.19609 64.5728 6.21194 64.511 10.9961C64.5728 15.7763 69.8299 19.7961 76.9617 20.9803V20.9763L77.1472 20.9961C77.7386 20.9961 78.218 20.5208 78.218 19.9347C78.218 19.4397 77.8701 19.0238 77.4023 18.909Z"
        fill="#111D4E"
      />
      <path
        d="M49.8105 18.9089L49.8066 18.897C53.5793 17.6257 56.2426 14.5683 56.2852 10.996C56.2426 7.4198 53.5793 4.36634 49.8066 3.09109L49.8105 3.08317C49.3428 2.96832 48.9949 2.55248 48.9949 2.05743C48.9949 1.47525 49.4781 1 50.0695 1L50.2512 1.0198L50.255 1.01584C57.3868 2.2 62.6439 6.21584 62.7018 11C62.6439 15.7802 57.3829 19.8 50.255 20.9842L50.2512 20.9802L50.0656 21C49.4742 21 48.9949 20.5248 48.9949 19.9386C48.9949 19.4396 49.3428 19.0238 49.8105 18.9089Z"
        fill="#E32725"
      />
    </svg>
  )
}
