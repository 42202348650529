
import { Avatar } from '@liveconnect/components';
import useMember from '../../../core/member/useMember';
import { Message } from '../../../core/new-chat/types';
import './MessagesList.scss';


type MessagesListProps = {
    messages: Message[];
};

const formatDate = (date: Date): string => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) return "Hoy";
    if (date.toDateString() === yesterday.toDateString()) return "Ayer";
    return date.toLocaleDateString();
};

const formatTime = (date: Date | string): string => {
    // Si la fecha es un string, convertirla a Date
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    return dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  const MessagesList = ({ messages }: MessagesListProps) => {
    const { member } = useMember();
    const myId = 'user7';

    // Convertir sentAt de string a Date si es necesario
    const formattedMessages = messages.map((message) => {
        const sentAt = typeof message.sentAt === 'string' ? new Date(message.sentAt) : message.sentAt;
        return { ...message, sentAt };
    });

    const sortedMessages = formattedMessages.sort((a, b) => a.sentAt.getTime() - b.sentAt.getTime());

    const groupedMessages = sortedMessages.reduce((acc: Record<string, Message[]>, message) => {
        const date = formatDate(message.sentAt); // Obtener la fecha formateada
        if (!acc[date]) acc[date] = []; // Inicializar el grupo si no existe
        acc[date].push(message); // Añadir el mensaje al grupo
        return acc;
    }, {});

    return (
        <div className="messages-list">
            {Object.entries(groupedMessages).map(([date, dayMessages]) => (
                <div key={date} className="messages-list__date-group">
                    <div className="messages-list__date-group__date">{date}</div>
                    {dayMessages.map((message, index) => {
                        const isMine = message.sentBy.userId === myId;
                        const isNextByOther = index < dayMessages.length - 1 && dayMessages[index + 1].sentBy.userId !== myId; // Comprobar si el siguiente mensaje no es mío

                        const showAvatar =
                            !isMine &&
                            (index === 0 ||
                            dayMessages[index - 1].sentBy.chatParticipantId !== message.sentBy.chatParticipantId); // Mostrar avatar solo si no es mío y es el primer mensaje o cambia el remitente

                        const showTimestamp =
                            index === 0 || // Mostrar siempre el timestamp para el primer mensaje
                            isMine !== (dayMessages[index - 1].sentBy.userId === myId) || // Cambia de remitente/receptor
                            formatTime(message.sentAt) !== formatTime(dayMessages[index - 1].sentAt); // Mostrar si cambia la hora

                        const receivedClass = !isMine && !showAvatar ? "no-avatar" : "";

                        return (
                            <div
                                key={message.messageId}
                                className={`message-item ${
                                    isMine ? "message-item--mine" : `message-item--received ${receivedClass}`
                                } ${isMine && isNextByOther ? 'message-item--margin-bottom' : ''}`}
                            >
                                {showTimestamp && (
                                    <div
                                        className={`messages-list__hour-group__hour ${
                                            isMine
                                                ? "messages-list__hour-group__hour--right"
                                                : "messages-list__hour-group__hour--left"
                                        }`}
                                    >
                                        {formatTime(message.sentAt)}
                                    </div>
                                )}
                                <div className="message-item__content">
                                    {!isMine && showAvatar && (
                                        <Avatar name={message.sentBy.name} surname={message.sentBy.surname} imageUrl={message.sentBy.imageUrl} size="sm" />
                                    )}
                                    <div className="message-item__bubble">{message.content}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );
};



export default MessagesList;
