export interface GetParams<T> {
  endpoint: string
  params?: T
  isPublic?: boolean
}

export interface PostParams {
  endpoint: string
  body: unknown
  isPublic?: boolean
  isMultipart?: boolean
}

export interface PutParams {
  endpoint: string
  id?: string | number
  body: unknown
  isPublic?: boolean
  isMultipart?: boolean
}

export interface PatchParams {
  endpoint: string
  body: unknown
  isPublic?: boolean
  isMultipart?: boolean
}

export interface DeleteParams {
  endpoint: string
  id?: string | number
  isPublic?: boolean
  params?: Record<string, unknown>
}

export interface FetchError extends Error {
  status: number
  body: any[]
}

export enum Error409 {
  Age = 'Is under fourteen years of age',
}

export interface UseFetchResult {
  get<ParamType, ResultType>(
    params: GetParams<ParamType>
  ): Promise<ResultType | undefined>
  post<T>(params: PostParams): Promise<T | undefined>
  put<T>(params: PutParams): Promise<T | undefined>
  patch<T>(params: PatchParams): Promise<T | undefined>
  del<T>(params: DeleteParams): Promise<T | undefined>
}
