import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Avatar, Loader } from '@liveconnect/components'
import { HtmlSanitizer } from '@liveconnect/communities-ui'
import { Icon } from '@liveconnect/icons'

import { Main } from '../../../components/Main'
import { CommentComponent } from '../../../containers/Post/Comment'
import { MakeCommentComponent } from '../../../containers/Post/MakeComment'
import { ActivityDetail, Topic } from '../../../core/activities/types'
import useActivities from '../../../core/activities/useActivities'
import useMember from '../../../core/member/useMember'
import { RegisterComponent } from '../Register'
import ChannelsModal from '../ChannelsModal'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useUi from '../../../core/ui/useUi'
import VideoPlayer from '../../../components/VideoPlayer'
import useTypes from '../../../utils/activities/useTypes'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import useGTM from '../../../utils/useGTM'

import './styles.scss'

export const ActivityScreen: FC = () => {
  const { t } = useTranslation()
  const { state } = useLocation() as { state: { isInteresting: boolean } }
  const navigate = useNavigate()
  const { basePath } = useCustomRouter()
  const { transformType } = useTypes()
  const { fetchActivity, activity, subscribeActivity, unSubscribeActivity } =
    useActivities()
  const notify = useSnackBars()
  const { showConfirmation } = useUi()
  const { activityId } = useParams()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [detailTopics, setDetailTopics] = useState<Topic[]>([])
  const [showAllComments, setShowAllComments] = useState<boolean>(false)
  const [showChannels, setShowChannels] = useState<boolean>(false)
  const { member, updateMemberChannelsFromActivity, fetchMember } = useMember()
  const { pushDataLayer } = useGTM()

  const isoTrans = member?.isoCode.slice(0, 2)

  const parseDates = (activityParam: ActivityDetail) => {
    const endDate = new Date(activityParam.endDate).getTime()
    const today = new Date().getTime()
    if (today > endDate && activityParam.isRegistered) {
      return true
    }
    return false
  }
  const verifyModal = (topicsResponse: Topic[]) => {
    setDetailTopics(topicsResponse)
    if (state && !state.isInteresting) {
      setShowChannels(false)
      setIsSubmitting(false)
      loadActiv()
      return notify.success(t('activity.register.signup.success'))
    }
    setShowChannels(true)
  }

  const handleConfirmUnsubscribe = (activityId: string) => {
    showConfirmation({
      title: t('activity.unsubscribe.confirm.title'),
      subtitle: t('activity.unsubscribe.confirm.subtitle'),
      confirmText: t('common.unsubscribe'),
      iconName: 'report_problem',
      onConfirm: () => unSubscribe(activityId),
    })
  }

  const unSubscribe = async (activityId: string) => {
    try {
      await unSubscribeActivity(activityId)
      pushDataLayer({
        event: 'activity_unsubscribe',
        category: 'conversion',
        action: 'activity_unsubscribe',
        extraParams: {
          activity_title: activityId,
        },
      })
      notify.success(t('activity.unsubscribe.success'))
      fetchActivity(activityId)
    } catch (e) {
      notify.error(t('activity.unsubscribe.error'))
    }
  }

  const registerActivity = async (activityId: string) => {
    try {
      setIsSubmitting(true)
      const response = await subscribeActivity(activityId)
      pushDataLayer({
        event: 'activity_subscribe',
        category: 'conversion',
        action: 'activity_subscribe',
        extraParams: {
          activity_title: activityId,
        },
      })
      response && verifyModal(response)
    } catch (e) {
      notify.error(t('activity.register.signup.error'))
      setIsSubmitting(false)
    }
  }

  const closeModal = () => {
    setShowChannels(false)
    loadActiv()
    notify.success(t('activity.register.signup.success'))
    setIsSubmitting(false)
  }
  const signUpChannels = async (valuesToSend: string[]) => {
    setShowChannels(false)
    notify.success(t('activity.register.signup.success'))
    try {
      await updateMemberChannelsFromActivity(valuesToSend)
      notify.success(t('channels.list.succeessFeedback'))
      await fetchMember()
    } catch (e) {
      notify.error(t('channels.list.unknownError'))
    }
    loadActiv()
    setIsSubmitting(false)
  }

  const loadActiv = async () => {
    try {
      activityId && (await fetchActivity(activityId))
    } catch (e) {
      notify.error(t('activity.error.load'))
    } finally {
      setLoading(false)
    }
  }

  const detailBrand = () => {
    if (activity && activity.brandSpaceId) {
      return navigate(`${basePath}/brands/${activity.brandSpaceId}`)
    }
  }

  const handleDownloadPDF = () => {
    if (activity && new Date(activity.endDate) > new Date()) {
      fetch(activity.challengeTypeFile, {
        method: 'GET',
      })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]))
          const a = document.createElement('a')
          a.href = url
          a.download = activity.challengeTypeFileName
          document.body.appendChild(a)
          a.click()
          a.remove()
        })
    }
  }

  useEffect(() => {
    setLoading(true)
    loadActiv()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member])

  return (
    <Main ariaLabelledby="activities-title" className="pageActivity">
      {!activity || loading ? (
        <Loader />
      ) : (
        <>
          <div className="row">
            <div className="col-xs-12 col-md-6 pageActivity__contentImg">
              <img src={activity.imageUrl} alt="" />
            </div>
            <div className="col-xs-12 col-md-6 pageActivity__contentText">
              <div className="row">
                <div className="col-xs-12">
                  <span
                    onClick={detailBrand}
                    className="contentImg"
                    aria-hidden="true"
                  >
                    <img src={activity.brandLogoImageUrl} alt="" />
                  </span>
                </div>
                <div className="col-xs-12">
                  <h3>{activity.title}</h3>
                </div>
                <div className="col-xs-12">
                  <span className="type">{transformType(activity.type)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-9">
              <div className="row">
                <div className="col-xs-12 pageActivity__contentDesc">
                  <HtmlSanitizer
                    className="description"
                    html={activity.description ?? ''}
                  />
                </div>
                <div className="col-xs-12 pageActivity__contentModule d-block d-md-none">
                  <div className="pageActivity__contentModule__box">
                    <RegisterComponent
                      type={transformType(activity.type)}
                      start={activity.startDate}
                      end={activity.endDate}
                      language={activity.language}
                      attendees={activity.attendees}
                      onClick={() => registerActivity(activity.id)}
                      isRegistered={activity.isRegistered}
                      handleCancel={() => unSubscribe(activity.id)}
                      isSubmitting={isSubmitting}
                      videoUrl={activity.video}
                    />
                  </div>
                </div>
                {parseDates(activity) &&
                  activity.video &&
                  activity.video !== '' && (
                    <div className="col-xs-12 pageActivity__contentVideoPlayer">
                      <h4>{t('activity.finished.record')}</h4>
                      <VideoPlayer
                        url={activity.video}
                        className="pageActivity__contentVideoPlayer__video"
                      />
                    </div>
                  )}

                {activity.challengeTypeFile && (
                  <div className="col-12 mt-4 mt-md-2">
                    <button
                      className="download-challenge-pdf"
                      type="button"
                      disabled={new Date(activity.endDate) < new Date()}
                      onClick={handleDownloadPDF}
                    >
                      <span>{t('activity.downloadChallengePDF')}</span>
                      <Icon name="download" />
                    </button>
                  </div>
                )}

                {activity.challengeResult && (
                  <div className="col-12">
                    <div className="challenge-result">
                      <p className="challenge-result__title">
                        {t('activity.resultsChallenge')}
                      </p>
                      {activity.challengeResult.title && (
                        <p className="challenge-result__subtitle">
                          {activity.challengeResult.title}
                        </p>
                      )}
                      {activity.challengeResult.description && (
                        <p className="challenge-result__description">
                          {activity.challengeResult.description}
                        </p>
                      )}
                      {activity.challengeResult.title && (
                        <img
                          className="challenge-result__image"
                          src={activity.challengeResult.image}
                          alt={activity.challengeResult.title}
                        />
                      )}
                    </div>
                  </div>
                )}

                {activity.contributorInfo && (
                  <div className="col-12">
                    <div className="contributor-section">
                      <p className="contributor-section__title">
                        {activity.contributorInfo.contributorType}
                      </p>
                      <Swiper spaceBetween={32} slidesPerView="auto">
                        {activity.contributorInfo.contributors.map(
                          (contributor, index) => (
                            <SwiperSlide
                              key={index.toString()}
                              className="contributor-slide-container"
                            >
                              <div className="contributor-slide">
                                <Avatar
                                  name={contributor.name}
                                  surname={contributor.name}
                                  imageUrl={contributor.image}
                                  alt={contributor.name}
                                  size="md"
                                />
                                <p className="contributor-slide__title">
                                  {contributor.name}
                                </p>
                                {contributor.company && (
                                  <p className="contributor-slide__company">
                                    {contributor.company}
                                  </p>
                                )}
                                <p className="contributor-slide__description">
                                  {contributor.description}
                                </p>
                              </div>
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>
                  </div>
                )}

                <div className="col-xs-12 pageActivity__contentComments">
                  <h4 className="d-none d-md-block">
                    {t('activity.comments.title')}
                  </h4>
                  <div className="pageActivity__contentComments__box">
                    <MakeCommentComponent
                      takenId={activityId ? activityId : ''}
                      inActivity={true}
                    />
                    <div className="comments">
                      {activity.comments.slice(0, 2).map((comment) => (
                        <CommentComponent
                          key={comment.id}
                          {...comment}
                          takenId={activityId ? activityId : ''}
                          originalLangComment={comment.originalLanguage}
                          isoTransProp={isoTrans}
                          inActivity={true}
                        />
                      ))}
                      {activity.comments.length > 2 && !showAllComments && (
                        <button
                          className="PostComponent__commentList__link"
                          onClick={() => setShowAllComments(true)}
                        >
                          {t('posts.comment.viewMore')}
                        </button>
                      )}
                      {showAllComments &&
                        activity.comments
                          .slice(2)
                          .map((comment) => (
                            <CommentComponent
                              key={comment.id}
                              {...comment}
                              takenId={activityId ? activityId : ''}
                              originalLangComment={comment.originalLanguage}
                              isoTransProp={isoTrans}
                              inActivity={true}
                            />
                          ))}
                      {activity.comments.length > 2 && showAllComments && (
                        <button
                          className="PostComponent__commentList__link"
                          onClick={() => setShowAllComments(false)}
                        >
                          {t('posts.comment.viewLess')}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 pageActivity__contentModule d-none d-md-block">
              <div className="pageActivity__contentModule__box">
                <RegisterComponent
                  type={transformType(activity.type)}
                  start={activity.startDate}
                  end={activity.endDate}
                  language={activity.language}
                  attendees={activity.attendees}
                  onClick={() => registerActivity(activity.id)}
                  isRegistered={activity.isRegistered}
                  handleCancel={() => handleConfirmUnsubscribe(activity.id)}
                  isSubmitting={isSubmitting}
                  videoUrl={activity.video}
                  key={`${activity.isRegistered}`}
                />
              </div>
            </div>
          </div>
        </>
      )}
      {showChannels && detailTopics && (
        <ChannelsModal
          onClose={closeModal}
          topics={detailTopics}
          onSignUp={(values) => signUpChannels(values)}
        />
      )}
    </Main>
  )
}
