import { FC, useEffect, useState } from 'react'

import { EventSummary } from '../../../core/events/types'
import useEvents from '../../../core/events/useEvents'
import { Brand } from '../../../core/brandSpaces/types'
import { EventsBannerComponent } from '../../../components/EventsBanner'

import './styles.scss'

interface EventsListBrandProps {
  brand: Brand | null
}

const EventsListBrand: FC<EventsListBrandProps> = ({ brand }): JSX.Element => {
  const { getSummaryBrand } = useEvents()
  const [summary, setSummary] = useState<EventSummary | undefined>(undefined)

  const fetchSummary = async () => {
    if (brand?.code) {
      const result = await getSummaryBrand(brand?.code, brand.id)
      setSummary(result)
    }
  }

  useEffect(() => {
    fetchSummary()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return brand ? (
    <EventsBannerComponent summary={summary} isHome={false} brand={brand} />
  ) : (
    <></>
  )
}

export default EventsListBrand
