import { FC } from 'react'
import { HtmlSanitizer } from '@liveconnect/communities-ui'

import { Brand } from '../../../../core/brandSpaces/types'

import './styles.scss'

interface BrandItemProps {
  brand: Brand
  onBrandClick: (brand: Brand) => void
}

export const BrandItem: FC<BrandItemProps> = (props: BrandItemProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus
    <div
      className="brandItem"
      onClick={() => props.onBrandClick(props.brand)}
      role="button"
    >
      <img alt={props.brand.title} src={props.brand.profileImageUrl} />
      <div className="brandItem__content">
        <h5 className="brandItem__content__title">{props.brand.name}</h5>
        <HtmlSanitizer
          className="brandItem__content__body"
          html={props.brand.shortDescription}
        />
      </div>
    </div>
  )
}
