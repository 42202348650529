import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FilterButton, SearchInput } from '@liveconnect/communities-ui'
import { Loader } from '@liveconnect/components'

import { Main } from '../../components/Main'
import Carousel from '../../components/Carousel'
import { TypeCard } from '../../components/Carousel/types'

import FilterActivities from './FilterActivities'
import useActivities from '../../core/activities/useActivities'

import './styles.scss'

export const ActivitiesScreen: FC = () => {
  const { t } = useTranslation()
  const { fetchActivities, activities, isLoaded, fetchBrandSpaces } =
    useActivities()
  const navigate = useNavigate()
  const [showPanel, setShowPanel] = useState<boolean>(false)
  const [withFilters, setWithFilters] = useState<boolean>(false)

  useEffect(() => {
    fetchActivities()
    fetchBrandSpaces()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Main ariaLabelledby="activities-title" className="pageActivities">
      <div className="row">
        <div className="col-xs-12">
          <h1 id="activities-title" className="h3">
            {t('activities.title')}
          </h1>
          <p>{t('activities.subtitle')}</p>
        </div>
      </div>
      <div className="row pageActivities__search align-items-center">
        <div className="col-9 col-lg-11">
          <SearchInput
            onSearch={(s: string) => {
              fetchActivities({ search: s })
            }}
            defaultValue=""
            placeholder={t('common.search')}
          />
        </div>
        <div className="col-3 col-lg-1">
          <FilterButton
            isActive={withFilters}
            label={t('home.filters')}
            onClick={() => setShowPanel(true)}
          />
        </div>
      </div>
      <div className="row pageActivities__myActivities">
        <h1 id="activities-title" className="h3">
          {t('activities.myActivities')}
        </h1>
        {!isLoaded ? (
          <Loader />
        ) : (
          <>
            {activities.activities.length === 0 ? (
              <div className="col-12">
                <div className="contentEmpty">
                  <p className="title">
                    {t('activities.myActivities.empty.title')}
                  </p>
                  <p className="text">
                    {t('activities.myActivities.empty.text')}
                  </p>
                </div>
              </div>
            ) : (
              <Carousel
                elements={activities.activities}
                type={TypeCard.Activity}
                onClick={(id) =>
                  navigate(id, { state: { isInteresting: false } })
                }
              />
            )}
          </>
        )}
      </div>
      <div className="row pageActivities__recomended">
        <h1 id="activities-title" className="h3">
          {t('activities.recommended.title')}
        </h1>
        <p>{t('activities.recommended.text')}</p>
        {!isLoaded ? (
          <Loader />
        ) : (
          <>
            {activities.recommendedActivities.length === 0 ? (
              <div className="col-12">
                <div className="contentEmpty">
                  <p className="title">{t('activities.myActivities.empty')}</p>
                </div>
              </div>
            ) : (
              <Carousel
                elements={activities.recommendedActivities}
                type={TypeCard.Activity}
                onClick={(id) =>
                  navigate(id, { state: { isInteresting: false } })
                }
              />
            )}
          </>
        )}
      </div>
      <div className="row pageActivities__interesting">
        <h1 id="activities-title" className="h3">
          {t('activities.interesting')}
        </h1>
        {!isLoaded ? (
          <Loader />
        ) : (
          <>
            {activities.interestingActivities.length === 0 ? (
              <div className="col-12">
                <div className="contentEmpty">
                  <p className="title">{t('activities.myActivities.empty')}</p>
                </div>
              </div>
            ) : (
              <Carousel
                elements={activities.interestingActivities}
                type={TypeCard.Activity}
                onClick={(id) =>
                  navigate(id, { state: { isInteresting: true } })
                }
              />
            )}
          </>
        )}
      </div>
      <FilterActivities
        isOpen={showPanel}
        onClose={() => setShowPanel(false)}
        withParams={(param) => setWithFilters(param)}
      />
    </Main>
  )
}
