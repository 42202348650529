import { useEffect, FC } from 'react'
import TagManager from 'react-gtm-module'
import { Outlet, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useAuth, { postLogoutRedirectPathKey } from '../core/auth/useAuth'
import useMember from '../core/member/useMember'
import useSnackBars from '../utils/notifications/useNotifications'
import usePrivacyPolicies from '../core/privacyPolicies/usePrivacyPolicies'
import useTermsConditions from '../core/termsConditions/useTermsConditions'
import Layout from '../containers/Layout'
import useTenantConfig from '../core/tenantConfig/useTenantConfig'
import { useCustomRouter } from '../utils/useCustomRouter'

const PrivateRoute: FC = () => {
  const navigate = useNavigate()
  const { login, logout, loaded, isLoggedIn, user } = useAuth()
  const { tenantId } = useParams()
  const { member, isLoaded, visibleMember, fetchMember } = useMember()
  const notify = useSnackBars()
  const { t } = useTranslation()
  const { basePath } = useCustomRouter()
  const { isSigned: isSignedPrivacy } = usePrivacyPolicies()
  const { isSigned: isSignedTerms } = useTermsConditions()
  const { fetchTenantConfig, tenantConfig } = useTenantConfig()

  const visibleUser = async (memberId: string) => {
    try {
      await visibleMember(memberId)
    } catch (e) {
      notify.error(t('common.unknownError'))
    }
  }

  useEffect(() => {
    if (!isLoggedIn) {
      login()
    }
    if (user) {
      fetchMember()
    }
    if (isLoaded && !member) {
      navigate(`${basePath}/access-denied`)
    }
  }, [loaded, isLoggedIn, login, user])

  useEffect(() => {
    if (isLoggedIn) {
      fetchTenantConfig()
    }
  }, [])

  useEffect(() => {
    if (
      member &&
      isSignedPrivacy &&
      isSignedTerms &&
      member.isOnboardingComplete
    ) {
      !member.isVisible && visibleUser(member.id as string)
    }
  }, [member, isSignedPrivacy, isSignedTerms])

  useEffect(() => {
    if (tenantConfig && tenantConfig.googleTagManagerAccount) {
      const tagManagerArgs = {
        gtmId: tenantConfig.googleTagManagerAccount,
      }
      TagManager.initialize(tagManagerArgs)
    }
  }, [tenantConfig])

  if (user && tenantId && user.profile.tenant_id !== tenantId) {
    sessionStorage.setItem(postLogoutRedirectPathKey, `/t/${tenantId}`)
    logout()
  }

  return isLoggedIn && member ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : null
}
export default PrivateRoute
