import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SearchInput } from '@liveconnect/communities-ui'
import { Pagination, Loader } from '@liveconnect/components'

import EmptyState from '../../../components/EmptyState'
import UserSummary from '../../../components/UserSummary'
import { CreateContactPayload } from '../../../core/contactRequests/types'
import { RecomendedMember } from '../../../core/recomendedMembers/types'
import useRecomendedMembers from '../../../core/recomendedMembers/useRecomendedMembers'
import useGTM from '../../../utils/useGTM'
import useScroll from '../../../utils/useScroll'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useContactRequests from '../../../core/contactRequests/useContactRequests'
import useCulture from '../../../utils/culture'
import useUi from '../../../core/ui/useUi'
import useChat from '../../../core/chat/useChat'
import ContactModal from '../../../components/ContactModal'
import useIsMobile from '../../../utils/useIsMobile'
import AdSlot from '../../../containers/AdSlot'
import useSettings from '../../../core/settings/useSettings'

const Recommended = () => {
  const { t } = useTranslation()
  const { scrollTop } = useScroll()
  const { initChat } = useChat()
  const notify = useSnackBars()
  const { formatDate } = useCulture()
  const { showConfirmation } = useUi()
  const { createContactRequest } = useContactRequests()
  const {
    recomendedMemberList,
    page,
    availablePages,
    availableItems,
    pageSize,
    isLoaded,
    fetchRecomendedMembers,
    clearRecomendedMemberList,
  } = useRecomendedMembers()
  const [showModalContact, setShowModalContact] = useState<boolean>(false)
  const [memberContact, setMemberContact] =
    useState<CreateContactPayload | null>(null)
  const { pushDataLayer } = useGTM()
  const isMobile = useIsMobile()
  const { settings } = useSettings()

  const handleConnect = async (id: string, name: string) => {
    setMemberContact({ id: id, name: name })
    setShowModalContact(true)
  }

  const handleCreateContact = async (
    message: string | undefined,
    id: string
  ) => {
    try {
      if (message) {
        await createContactRequest(id, message)
      } else {
        await createContactRequest(id)
      }
      pushDataLayer({
        event: 'contact_request_community',
        category: 'interaction',
        action: 'contact_sent',
      })
      notify.success(t('recomendedMembers.list.successFeedback'))
    } catch (error: any) {
      if (error?.body?.[0]?.code === 'CONTACT_REQUEST_EXCEEDED') {
        const date = formatDate(
          new Date(error?.body?.[0]?.errorContext[0].value),
          'LONG_DATE_TIME_VERBOSE'
        )
        showConfirmation({
          title: t('common.warning'),
          subtitle: t('recomendedMembers.error.subtitle'),
          text: t('recomendedMembers.error.text', { date }),
          confirmText: t('common.accept'),
          iconName: 'report_problem',
          hideCancel: true,
        })
      } else {
        notify.error(t('recomendedMembers.list.unknownError'))
      }
    } finally {
      setShowModalContact(false)
      fetchRecomendedMembers({ page: 1 })
    }
  }

  useEffect(() => {
    scrollTop()
  }, [page])

  useEffect(() => {
    initChat()
    fetchRecomendedMembers({ page: 1 })
    return clearRecomendedMemberList
  }, [])

  return (
    <>
      <div className="UserList__list__search">
        <SearchInput
          onSearch={(s: string) => {
            fetchRecomendedMembers({ page: 1, search: s })
          }}
          defaultValue=""
          placeholder={t('common.search')}
        />
      </div>
      {!isLoaded ? (
        <Loader />
      ) : (
        <section className="mt-3">
          {!recomendedMemberList.length ? (
            <>
              <EmptyState text={t('recomendedMembers.list.empty.title')} />
              {isMobile && (
                <AdSlot
                  context={settings?.environment}
                  section="comunity365Networking"
                  container="sideBarRight"
                  device="mobile"
                  id="peopleMobileEmpty"
                  className="UserList__list__adSlot"
                />
              )}
            </>
          ) : (
            <div className="UserList__list">
              {recomendedMemberList.map(
                (member: RecomendedMember, index: number) => (
                  <Fragment key={member.id}>
                    <div className="UserList__item d-none d-sm-flex">
                      <UserSummary {...member} badges={member.badges} />
                      <div className="contentBut">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleConnect(member.id, member.name)}
                        >
                          {t('recomendedMembers.list.connect')}
                        </button>
                      </div>
                    </div>
                    {/* ONLY IN MOBILE */}
                    <div className="UserList__item d-block d-sm-none row">
                      <UserSummary {...member} badges={member.badges} />
                      <div className="contentBut">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleConnect(member.id, member.name)}
                        >
                          {t('recomendedMembers.list.connect')}
                        </button>
                      </div>
                    </div>
                    {/* END ONLY IN MOBILE */}
                    {isMobile &&
                      (index === 2 ||
                        (recomendedMemberList.length < 3 &&
                          index === recomendedMemberList.length - 1)) && (
                        <AdSlot
                          context={settings?.environment}
                          section="comunity365Networking"
                          container="sideBarRight"
                          device="mobile"
                          id="peopleMobile"
                          className="UserList__list__adSlot"
                        />
                      )}
                  </Fragment>
                )
              )}
              <Pagination
                currentPage={page - 1}
                totalPages={availablePages}
                totalItems={availableItems}
                pageSize={pageSize}
                singlePageText={(count) =>
                  t('common.pager.singlePage', { count })
                }
                multiplePageText={(start, end, total) =>
                  t('common.pager.multiplePage', { start, end, total })
                }
                onChange={(pageIndex) =>
                  fetchRecomendedMembers({ page: pageIndex + 1 })
                }
              />
            </div>
          )}
        </section>
      )}
      {showModalContact && memberContact && (
        <ContactModal
          contact={memberContact}
          onContact={(message, id) => handleCreateContact(message, id)}
          onClose={() => setShowModalContact(false)}
        />
      )}
    </>
  )
}

export default Recommended
