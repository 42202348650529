import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseMemberResult,
  Member,
  MemberImage,
  MemberToSend,
  MemberTheme,
  ActivateMember,
  MemberProfile,
  MemberToUpdate,
  IsoCode,
} from './types'
import {
  setDetail,
  showLoader,
  hideLoader,
  activateChannelState,
  setThemes,
  setMemberProfile,
  setValidateDataState,
} from './store'
import useFetch from '../../utils/fetch/useFetch'
import { ChangeLocalizationForm } from '../Localizations/types'

const useMember = (): UseMemberResult => {
  const dispatch = useAppDispatch()
  const { detail, memberProfile, isLoaded, themes } = useAppSelector(
    (state) => state.member
  )
  const { get, put, post, patch } = useFetch()

  const endpoint = 'members'

  const getMember = async () => {
    const response: Member | undefined = await get({ endpoint })
    return response
  }

  const fetchMember = async () => {
    dispatch(showLoader())
    try {
      const response: Member | undefined = await getMember()
      if (response) {
        dispatch(setDetail(response))
      }
      if (response && response.isOnboardingComplete) await fetchMemberThemes()
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('ERROR ', e)
    } finally {
      dispatch(hideLoader())
    }
  }

  const updateMember = async (member: MemberToUpdate): Promise<void> => {
    dispatch(showLoader())
    const response: Member | undefined = await put({
      endpoint,
      id: '',
      body: member,
    })
    if (response) dispatch(setDetail(response))
    dispatch(hideLoader())
  }

  const uploadMemberImage = async (imgBlob: string): Promise<string> => {
    dispatch(showLoader())
    const imageData = new FormData()
    const imageBlob = await fetch(imgBlob).then((r) => r.blob())
    imageData.append('imageFile', imageBlob, 'avatar.jpeg')
    const response: MemberImage | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: imageData,
      isMultipart: true,
    })
    dispatch(hideLoader())
    return response?.uri ?? ''
  }

  const insertMember = async (member: MemberToSend) => {
    dispatch(showLoader())
    const response: Member | undefined = await post({
      endpoint: `${endpoint}/onboarding`,
      body: member,
    })
    if (response) {
      dispatch(setDetail(response))
      await fetchMemberThemes()
    }
    dispatch(hideLoader())
  }

  const activateChannel = async (id: string) => {
    try {
      await post({
        endpoint: `${endpoint}/channels/${id}/active`,
        body: {},
      })
      dispatch(activateChannelState({ id }))
      await fetchMemberThemes()
    } catch (e) {
      //controlar error
    }
  }

  const updateMemberChannels = async (channels: string[]) => {
    await put({
      endpoint: `${endpoint}/channels`,
      id: '',
      body: channels,
    })
  }
  const updateMemberChannelsFromActivity = async (channels: string[]) => {
    await put({
      endpoint: `${endpoint}/channels-from-activity`,
      body: channels,
    })
  }

  const getMemberThemes = async () => {
    const response: MemberTheme[] | undefined = await get({
      endpoint: `${endpoint}/themes`,
    })
    return response
  }

  const fetchMemberThemes = async () => {
    const response: MemberTheme[] | undefined = await getMemberThemes()
    if (response) dispatch(setThemes(response))
  }

  const activateMemberThemes = async (activeThemes: ActivateMember[]) => {
    try {
      await post({
        endpoint: `${endpoint}/themes/active`,
        body: activeThemes,
      })
      dispatch(
        setThemes(
          themes.map((item) => ({
            ...item,
            isActive: !!activeThemes.find(
              (activeTheme) => activeTheme.id === item.id
            ),
          }))
        )
      )
    } catch (e) {
      throw e
    }
  }

  const resetLanguage = async () => {
    /* Todos las cambios de estado que dependean del idioma */
    await fetchMemberThemes()
  }

  const changeLocalization = async (
    body: ChangeLocalizationForm,
    preventReset?: boolean
  ) => {
    dispatch(showLoader())
    const response: Member | undefined = await post({
      endpoint: `${endpoint}/localization`,
      body,
    })
    if (response) dispatch(setDetail(response))
    if (!preventReset) {
      await resetLanguage()
    }
    dispatch(hideLoader())
  }

  const changeLocalMemberLang = (isoCode: IsoCode) => {
    if (detail) {
      dispatch(setDetail({ ...detail, isoCode }))
    }
  }

  const fetchMemberProfile = async (id: string) => {
    dispatch(showLoader())
    const response: MemberProfile | undefined = await get({
      endpoint: `${endpoint}/${id}/profile`,
    })
    if (response) {
      dispatch(setMemberProfile(response))
    }
    dispatch(hideLoader())
  }

  const visibleMember = async (id: string) => {
    const response: Member | undefined = await patch({
      endpoint: `${endpoint}/${id}/visible`,
      body: {},
    })
    if (response) dispatch(setDetail(response))
    dispatch(hideLoader())
  }

  const validateDataMember = async (id: string) => {
    try {
      await patch({
        endpoint: `${endpoint}/${id}/validate-change`,
        body: {},
      })
      if (detail) {
        dispatch(setValidateDataState(false))
      }
    } catch (error) {}
  }

  const anonimizeMember = async (id: string) => {
    try {
      await patch({
        endpoint: `${endpoint}/anonimize`,
        body: { id },
      })
    } catch (error) {}
  }

  return {
    member: detail,
    memberProfile,
    memberThemes: themes,
    isLoaded,
    fetchMember,
    getMember,
    updateMember,
    uploadMemberImage,
    insertMember,
    activateChannel,
    updateMemberChannels,
    updateMemberChannelsFromActivity,
    getMemberThemes,
    activateMemberThemes,
    changeLocalization,
    changeLocalMemberLang,
    fetchMemberProfile,
    fetchMemberThemes,
    unsetMemberProfile: () => dispatch(setMemberProfile(null)),
    visibleMember,
    validateDataMember,
    anonimizeMember,
  }
}

export default useMember
