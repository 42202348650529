import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UsePostsResult,
  PostImage,
  PostListResponse,
  PostListPayload,
  PostParams,
  CreatePostInterface,
  Comment,
  CommentPostInterface,
  ReactionTypeEnum,
  Reactions,
  Post,
  CommentPutInterface,
  TranslatePost,
} from './types'
import {
  setList,
  setPostComments,
  deletePostComment,
  updatePostComment,
  addPostComment,
  showLoader,
  hideLoader,
} from './store'
import useFetch from '../../utils/fetch/useFetch'

const usePosts = (source?: string): UsePostsResult => {
  const dispatch = useAppDispatch()
  const { detail, list, page, pageSize, hasNextPage, isLoaded } =
    useAppSelector((state) => state.posts)
  const { get, post, put, del } = useFetch()

  const endpoint = 'posts'
  const commentsEndpoint = 'comments'
  const endpointMetrics = 'sponsor-post-metrics'

  const getPosts = async (params?: PostParams): Promise<PostListPayload> => {
    const response: PostListResponse | undefined = await get({
      endpoint: `${source ? source + '/' : ''}${endpoint}`,
      params,
    })
    if (!response)
      return {
        list: [],
        page: 0,
        pageSize: 0,
        hasNextPage: false,
      }
    return {
      list: response.items,
      page: response.page,
      pageSize: response.pageSize,
      hasNextPage: response.page < response.availablePages,
    }
  }

  const getComments = async (postId: string): Promise<Comment[]> => {
    const response: Comment[] | undefined = await get({
      endpoint: `${endpoint}/${postId}/comments`,
    })

    return response ?? []
  }

  const fetchPosts = async (params?: PostParams) => {
    dispatch(showLoader())
    const response: PostListPayload = await getPosts({
      page_size: pageSize,
      page: 1,
      ...params,
    })
    dispatch(setList(response))
    dispatch(hideLoader())
  }

  const fetchNextPagePosts = async (params?: PostParams) => {
    dispatch(showLoader())
    const response: PostListPayload = await getPosts({
      page_size: pageSize,
      ...params,
      page: page + 1,
    })
    response.list = [...list, ...response.list]
    dispatch(setList(response))
    dispatch(hideLoader())
  }

  const createPost = async (body: CreatePostInterface) => {
    await post({
      endpoint,
      body,
    })
  }

  const deletePost = async (id: string) => {
    await del({ endpoint, id })
  }

  const uploadPostImage = async (imageUrl: string) => {
    dispatch(showLoader())
    const imageData = new FormData()
    const imageBlob = await fetch(imageUrl).then((r) => r.blob())
    imageData.append('imageFile', imageBlob, 'postImage')
    const response: PostImage | undefined = await post({
      endpoint: `${endpoint}/image`,
      body: imageData,
      isMultipart: true,
    })
    dispatch(hideLoader())
    return response
  }

  // Comments

  const fetchPostComments = async (postId: string) => {
    const response: Comment[] | undefined = await get({
      endpoint: `${endpoint}/${postId}/comments`,
    })
    dispatch(setPostComments({ postId, list: response ?? [] }))
  }

  const createComment = async (
    postId: string,
    commentToSave: CommentPostInterface
  ): Promise<void> => {
    const response: Comment | undefined = await post({
      endpoint: commentsEndpoint,
      body: { ...commentToSave, postId },
    })
    dispatch(
      addPostComment({
        postId,
        comment: response,
      })
    )
  }

  const deleteComment = async (postId: string, commentId: string) => {
    await del({ endpoint: commentsEndpoint, id: commentId })
    dispatch(deletePostComment({ postId, commentId }))
  }

  const updateComment = async (
    postId: string,
    comment: CommentPutInterface
  ): Promise<Comment | undefined> => {
    const result = await put<Comment>({
      endpoint: commentsEndpoint,
      id: comment.id,
      body: comment,
    })
    dispatch(
      updatePostComment({
        postId,
        comment: result,
      })
    )
    return result
  }

  const updateReaction = async (
    endpoint: string,
    reaction: ReactionTypeEnum
  ): Promise<Reactions | undefined> => {
    const response: Reactions | undefined = await put({
      endpoint,
      body: { type: reaction },
    })
    return response
  }

  const updatePostReaction = async (
    postId: string,
    reaction: ReactionTypeEnum
  ): Promise<Reactions | undefined> => {
    const response: Reactions | undefined = await updateReaction(
      `${endpoint}/${postId}/reaction`,
      reaction
    )
    return response
  }

  const updateCommentReaction = async (
    postId: string,
    commentId: string,
    reaction: ReactionTypeEnum
  ): Promise<Reactions | undefined> => {
    const response: Reactions | undefined = await updateReaction(
      `${endpoint}/${postId}/comment/${commentId}/reaction`,
      reaction
    )
    return response
  }

  const updatePost = async (
    postId: string,
    body: CreatePostInterface
  ): Promise<Post | undefined> => {
    const response: Post | undefined = await put({
      endpoint: `${endpoint}/${postId}`,
      body,
    })
    return response
  }

  const getTranslatePost = async (
    brandId: string,
    lang: string
  ): Promise<TranslatePost | undefined> => {
    const response: TranslatePost | undefined = await get({
      endpoint: `${endpoint}/${brandId}/translate`,
      params: {
        to: lang,
      },
    })
    return response
  }

  const getTranslateComment = async (
    commentId: string,
    lang: string
  ): Promise<TranslatePost | undefined> => {
    const response: TranslatePost | undefined = await get({
      endpoint: `${commentsEndpoint}/${commentId}/translate`,
      params: {
        to: lang,
      },
    })
    return response
  }

  const viewMorePostSponsored = async (postId: string) => {
    await post({
      endpoint: `${endpointMetrics}/view-post`,
      body: { postId },
    })
  }

  const viewPostSponsor = async (postId: string) => {
    await post({
      endpoint: `${endpointMetrics}/view-sponsor`,
      body: { postId },
    })
  }

  return {
    post: detail,
    postList: list,
    isLoaded,
    hasNextPostsPage: hasNextPage,
    fetchPosts,
    fetchNextPagePosts,
    createPost,
    deletePost,
    uploadPostImage,
    fetchPostComments,
    createComment,
    deleteComment,
    updatePostReaction,
    updateCommentReaction,
    updatePost,
    updateComment,
    getTranslatePost,
    getTranslateComment,
    viewMorePostSponsored,
    viewPostSponsor,
  }
}

export default usePosts
