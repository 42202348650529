import { FC } from 'react'
import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Main } from '../../components/Main'
import useMember from '../../core/member/useMember'
import useAuth from '../../core/auth/useAuth'
import { IdpType } from '../../core/auth/types'
import useSettings from '../../core/settings/useSettings'

import './styles.scss'
import IconButton from '../../components/Buttons/IconButton'

const ConfigurationScreen: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { member } = useMember()
  const { user } = useAuth()
  const { settings } = useSettings()

  const handleNavigate = () => {
    navigate('localization')
  }
  const changePassword = () => {
    if (settings?.identity) {
      const params = new URLSearchParams()
      params.append('clientId', settings.identity.clientId)
      params.append(
        'sourceUrl',
        `${window.location.origin}${location.pathname}`
      )
      window.location.href = `${settings.identity.authority}?${params}`
    }
  }

  const getLabel = () => {
    switch (member?.isoCode) {
      case 'es-ES':
        return t('localizations.es-ES')
      case 'en-GB':
        return t('localizations.en-GB')
      default:
        return t('localizations.es-ES')
    }
  }

  const verifyIdp = () => {
    if (user?.profile.idp === IdpType.Local) {
      return t('configuration.password.text')
    }
    return (
      <div className="contentNotAccess">
        <Icon name="report_problem" />
        {t('configuration.not.access')}
      </div>
    )
  }

  return (
    <Main ariaLabelledby="configuration" className="Configuration">
      <div className="row">
        <div className="col-xs-12 col-lg-9">
          <h1 id="configuration-title" className="h3">
            {t('common.configuration')}
          </h1>
          <p className="Configuration__subtitle">{t('configuration.text')}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-9 Configuration__box">
          <div className="Configuration__box__item">
            <div className="Configuration__box__item__actions">
              <p>{t('common.language')}</p>
              <IconButton icon="edit" onClick={handleNavigate} />
            </div>
            <div className="Configuration__box__item__text">
              <p>{t('configuration.language.text')}</p>
            </div>
            <div className="Configuration__box__item__label">
              <p>{getLabel()}</p>
            </div>
          </div>
          <hr />
          <div className="Configuration__box__item">
            <div className="Configuration__box__item__actions">
              <p>{t('common.password')}</p>
              <IconButton
                icon="edit"
                disabled={user?.profile.idp !== IdpType.Local}
                onClick={changePassword}
              />
            </div>
            <div className="Configuration__box__item__text">
              <p>{verifyIdp()}</p>
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default ConfigurationScreen
