import { Icon, IconName } from '@liveconnect/icons'
import { FC } from 'react'

import './styles.scss'

type IconButtonProps = {
  onClick: () => void
  icon: IconName
  disabled?: boolean
}

const IconButton: FC<IconButtonProps> = ({ onClick, icon, disabled }) => {
  return (
    <button
      disabled={disabled ? disabled : false}
      className="IconButton"
      onClick={onClick}
    >
      <Icon name={icon} />
    </button>
  )
}

export default IconButton
