import { User } from 'oidc-client'

export interface AuthState {
  user?: User | null | undefined
  loaded: boolean
  isLoggedIn: boolean
}

export enum IdpType {
  Local = 'local',
}

export interface UseAuthResult {
  user?: User | null | undefined
  loaded: boolean
  isLoggedIn: boolean
  login: () => void
  getChangePasswordUrl: () => void | undefined
  getPostLogoutUri: () => string | null
  logout: (url?: string) => Promise<void> | undefined
  signinSilentCallback: () => Promise<User | undefined> | undefined
  signinRedirectCallback: () => Promise<{
    user: User | undefined
    redirectPath: string
  }>
  renewToken: () => Promise<User | undefined>
  updateUser: () => void
}
