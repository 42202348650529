import * as yup from 'yup'
import { TFunction } from 'react-i18next'

export function buildValidationSchema(t: TFunction): yup.AnyObjectSchema {
  return yup.object().shape({
    startDate: yup
      .string()
      .nullable()
      .test(
        'endDate',
        t('validations.greaterThan', {
          label: t('activities.filter.date.since'),
          other: t('activities.filter.date.until'),
        }),
        (value, context) => {
          const { endDate } = context.parent
          if (value && endDate) {
            return new Date(endDate) > new Date(value)
          }
          return true
        }
      ),
    endDate: yup
      .string()
      .nullable()
      .test(
        'startDate',
        t('validations.lowerThan', {
          label: t('activities.filter.date.since'),
          other: t('activities.filter.date.until'),
        }),
        (value, context) => {
          const { startDate } = context.parent
          if (value && startDate) {
            return new Date(startDate) < new Date(value)
          }
          return true
        }
      ),
  })
}
