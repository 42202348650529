import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { randomId, TabsComponent } from '@liveconnect/communities-ui'

import { Main } from '../../components/Main'
import ReceivedList from './ReceivedList'
import SentList from './SentList'
import useChat from '../../core/chat/useChat'
import { Link } from 'react-router-dom'
import { Icon } from '@liveconnect/icons'
import { useCustomRouter } from '../../utils/useCustomRouter'
import useIsMobile from '../../utils/useIsMobile'
import AdSlot from '../../containers/AdSlot'
import useSettings from '../../core/settings/useSettings'

import './styles.scss'

const ContactRequestsScreen: FC = () => {
  const { t } = useTranslation()
  const { initChat } = useChat()
  const { basePath } = useCustomRouter()
  const isMobile = useIsMobile()
  const { settings } = useSettings()

  const tabs = [
    {
      id: randomId(),
      label: t('contacts.receivedList.label'),
      component: <ReceivedList />,
    },
    {
      id: randomId(),
      label: t('contacts.sentList.label'),
      component: <SentList />,
    },
  ]

  useEffect(() => {
    initChat()
  }, [])

  return (
    <Main ariaLabelledby="user-list-title" className="ContactRequests">
      <div className="row">
        <div className="col-xs-12 col-lg-9">
          <div className="UserList__header">
            <div>
              <h1 id="user-list-title" className="h3">
                {t('contacts.title')}
              </h1>
              <p>{t('contacts.subtitle')}</p>
            </div>
            <div className="UserList__header__link">
              <Link className="btn btn-link" to={`${basePath}/people`}>
                <Icon name="keyboard_arrow_left" />
                <span>{t('contacts.backToPeople')}</span>
              </Link>
            </div>
          </div>
          <TabsComponent tabs={tabs} />
        </div>
        <div className="col-lg-3 d-none d-lg-block">
          {!isMobile && (
            <AdSlot
              context={settings?.environment}
              section="comunity365Networking"
              container="sideBarRight"
              device="desktop"
              id="contactDesktop"
            />
          )}
        </div>
      </div>
    </Main>
  )
}

export default ContactRequestsScreen
