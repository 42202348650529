import classNames from 'classnames'
import { FC, useEffect, useRef } from 'react'

import useAdManager from '../../core/adManager/useAdManager'
import useMember from '../../core/member/useMember'
import { DEFAULT_LANG } from '../../i18n/config'

interface IAdSlot {
  container: string
  context?: string
  section: string
  device: string
  id: string
  className?: string
}

const AdSlot: FC<IAdSlot> = ({
  container,
  context,
  section,
  device,
  id,
  className,
}) => {
  const adRef = useRef<googletag.Slot>()
  const { application, accountId, getContainerSize } = useAdManager()
  const { member } = useMember()

  const language = member?.isoCode || DEFAULT_LANG
  const size = getContainerSize(container)
  const targets = { context, section, language, device, application }

  const addTargeting = (ad: googletag.Slot) => {
    Object.keys(targets).map((key) => {
      const item = targets[key as keyof typeof targets]
      if (item) ad.setTargeting(key, item)
    })
  }

  const createAd = () => {
    const path = `/${accountId}/${container}`
    const googletag = window.googletag || {}
    googletag.cmd = googletag.cmd || []
    googletag.cmd.push(() => {
      const ad = googletag
        .defineSlot(path, size, id)
        .addService(googletag.pubads())
      addTargeting(ad)
      adRef.current = ad
      googletag.pubads().disableInitialLoad()
      googletag.enableServices()
      googletag.display(id)
      googletag.pubads().refresh([ad])
    })
  }

  const destroyCurrentAd = () => {
    if (adRef.current) {
      const googletag = window.googletag || {}
      googletag.destroySlots([adRef.current])
    }
  }

  useEffect(() => {
    destroyCurrentAd()
    if (accountId) createAd()
    return destroyCurrentAd
  }, [
    accountId,
    application,
    language,
    container,
    context,
    section,
    device,
    id,
  ])

  if (!accountId) <></>

  return <div className={classNames('AdSlot', className)} id={id} />
}

export default AdSlot
