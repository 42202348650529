import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import ConfigurationScreen from '../pages/Configuration'
import LocalizationScreen from '../pages/Configuration/Localization'

const ConfigurationRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<ConfigurationScreen />} />
      <Route path="localization" element={<LocalizationScreen />} />
    </Routes>
  )
}

export default ConfigurationRouter
