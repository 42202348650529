import React, { useEffect, useState } from "react";
import { useChat } from "../../../core/new-chat/useChat";
import useSignalR from "../../../utils/signalR/useSignalR";
import useMember from "../../../core/member/useMember";
import { useParams } from "react-router-dom";
import ConversationsList from "../ConversationsList/ConversationsList";
import ChatWindow from "../ChatWindow/ChatWindow";
import './ChatComponent.scss';
import  * as ChatType from '../../../core/new-chat/types';

interface ChatComponentProps {
  chats : ChatType.Chat[];
  activeChat : ChatType.Chat | null;
  setActiveChat : (chat: ChatType.Chat | null) => void;
  isMobile : boolean;
  onSendMessage: (message : string) => void;
}

const Chat: React.FC<ChatComponentProps> = ({chats, activeChat, setActiveChat, isMobile, onSendMessage}) => {
  const [messageToSend, setMessageToSend] = useState<string>(""); //Para pruebas
  const [groupId, setGroupId] = useState<string>(""); // Para pruebas
  const [inputGroupId, setInputGroupId] = useState<string>(""); // Para pruebas
  const [messages, setMessages] = useState<{ username: string; message: string }[]>([]); // Para pruebas
  const { tenantId } = useParams();
  const hub = "notificationhub";
  const { member } = useMember();
  const { initConnection, isConnectionLoaded, connection, connectionId } = useSignalR(hub);
  const { sendMessageToChat, sendMessageToGroup, joinGroup, leaveGroup, sendDirectMessage, connect } = useChat(
    tenantId!,
    connectionId || "",
  );
  const [receiverId, setReceiverId] = useState<string>(""); // Para el ID del receptor // Para pruebas
  const [directMessage, setDirectMessage] = useState<string>(""); // Para el mensaje directo // Para pruebas

  const user = `${member?.name || ""} ${member?.surname || ""}`; // Nombre completo del usuario

  // useEffect(() => {
  //   // Iniciar conexión al cargar el componente
  //   initConnection();
  // }, []);

  useEffect(() => {
    if (member) {
      connect(member);
    }
  }, [connectionId]);

  useEffect(() => {
    if (isConnectionLoaded && connection && connectionId) {
      connection.on("ReceiveMessage", (username: string, message: string) => {
        setMessages((prevMessages) => [...prevMessages, { username, message }]);
      });

      connection.on("AddToGroup", (username: string) => {
        console.log("AddToGroup", username);
      });

      connection.on("ReceiveGroupMessage", (username: string, message: string) => {
        setMessages((prevMessages) => [...prevMessages, { username, message }]);
      });

      connection.on("ReceiveDirectMessage", (senderId: string, message: string) => {
        console.log("ReceiveDirectMessage senderId: ", senderId);
        console.log("ReceiveDirectMessage message: ", message);
        setMessages((prevMessages) => [
          ...prevMessages,
          { username: senderId, message },
        ]);
      });

      connection.on("DirectMessageStatus", (status, message) => {
        if (status === "Sent") {
            console.log(message); // Mensaje enviado correctamente.
        } else if (status === "NotConnected") {
            console.log(message); // El usuario receptor no está conectado.
        }
    });

    connection.on("ReceiveChatHistory", (chatHistory) => {
      console.log("ReceiveChatHistory: ", chatHistory);
    })
    }
  }, [isConnectionLoaded, connection, connectionId]);

  // const handleSendMessage = async () => {
  //   if (groupId) {
  //     // Enviar mensaje al grupo si está conectado
  //     await sendMessageToGroup(user, messageToSend, groupId);
  //   } else {
  //     // Enviar mensaje al chat general si no hay grupo conectado
  //     await sendMessageToChat(user, messageToSend);
  //   }

  //   setMessageToSend(""); // Limpiar el input del mensaje
  // };

  const handleSetMessage = (message: string) => {

  }

  const handleJoinGroup = async () => {
    if (!inputGroupId.trim()) {
      alert("Por favor, introduce un ID de grupo válido.");
      return;
    }

    if (!member) {
      alert("No se ha cargado el usuario.");
      return;
    }

    try {
      setGroupId(inputGroupId); // Actualizar el estado del ID del grupo
      await joinGroup(member, inputGroupId);
    } catch (error) {
      alert("Hubo un problema al unirse al grupo.");
      console.error(error);
    }
  };

  const handleLeaveGroup = async () => {
    if (!groupId || !member) return;

    try {
      await leaveGroup(member, groupId); // Llamar a la función leaveGroup del hook
      setGroupId(""); // Limpiar el ID del grupo
      setMessages([]); // Limpiar los mensajes
      setInputGroupId(""); // Limpiar el input temporal
    } catch (error) {
      alert("Hubo un problema al salir del grupo.");
      console.error(error);
    }
  };

  const handleSendDirectMessage = async () => {
    if (!receiverId.trim() || !directMessage.trim()) {
      alert("Introduce un receptor y un mensaje válido.");
      return;
    }
  
    try {
      if (member) {
        await sendDirectMessage(receiverId, directMessage, member);
        alert("Mensaje enviado.");
        setDirectMessage(""); // Limpiar el input
      }
      
      
    } catch (error) {
      console.error("Error al enviar mensaje directo", error);
    }
  };

  return (
    // <div>
    //   <h2>Enviar Mensaje Directo</h2>
    //   <input
    //     type="text"
    //     placeholder="ID del Receptor"
    //     value={receiverId}
    //     onChange={(e) => setReceiverId(e.target.value)}
    //   />
    //   <input
    //     type="text"
    //     placeholder="Mensaje"
    //     value={directMessage}
    //     onChange={(e) => setDirectMessage(e.target.value)}
    //   />
    //   <button onClick={handleSendDirectMessage}>Enviar</button>
    // </div>

    <div className="chat-container">
    {/* Mostrar lista de conversaciones en móviles y pantallas grandes */}
    {!isMobile || !activeChat ? (
        <ConversationsList chats={chats} activeChat={activeChat} onSelectChat={setActiveChat} />
    ) : null}

    {/* Mostrar la ventana de chat solo cuando se seleccione una conversación */}
    {!isMobile || activeChat ? (
        <ChatWindow chat={activeChat} onSendMessage={onSendMessage} />
    ) : null}
</div>

  );
};

export default Chat;
