export interface Localization {
  isoCode: string
  name: string
}

export interface ChangeLocalizationForm {
  isoCode: string
}

export enum isoCodeEnum {
  ES = 'es-ES',
  EN = 'en-GB',
}

export interface UseLocalizationsResult {
  getLocalizations: () => Promise<Localization[] | undefined>
}
