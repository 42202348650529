import { FC, useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { Icon } from '@liveconnect/icons'
import { TypePublishEnum } from '@liveconnect/communities-ui'
import {
  FormMultiSelect,
  FormProvider,
  FormRichText,
  Tooltip,
} from '@liveconnect/components'

import { SimpleUploadControl } from '../../../components/forms/SimpleUploadControl'
import { buildValidationSchema } from './validations'
import usePosts from '../../../core/posts/usePosts'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useMember from '../../../core/member/useMember'
import { MemberChannel, MemberTheme } from '../../../core/member/types'
import { CreatePostInterface, DataEditProps } from '../../../core/posts/types'
import useUi from '../../../core/ui/useUi'
import VideoUpload from './VideoUpload'
import VideoPlayer from '../../../components/VideoPlayer'
import useGTM from '../../../utils/useGTM'

import './styles.scss'

interface CreatePostProps {
  onClose: () => void
  onCreate: (themesIds: string[]) => void
  dataPost?: DataEditProps
}

interface ThemeOption {
  label: string
  value: string
}

const CreatePost: FC<CreatePostProps> = ({ onClose, onCreate, dataPost }) => {
  const { t } = useTranslation()
  const notify = useSnackBars()
  const { showConfirmation } = useUi()
  const { uploadPostImage, createPost, updatePost } = usePosts()
  const { member, getMemberThemes } = useMember()
  const activeChannRef = useRef<MemberChannel>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [imagePath, setimagePath] = useState<string>('')
  const [themes, setThemes] = useState<ThemeOption[]>([])
  const [showVideoUploadModal, setShowVideoUploadModal] =
    useState<boolean>(false)
  const [showImageUploadModal, setShowImageUploadModal] =
    useState<boolean>(false)
  const [videoURL, setVideoURL] = useState<string>('')
  const [isVideoOrImageValid, setIsVideoOrImageValid] = useState<boolean>(true)
  const { pushDataLayer } = useGTM()

  const methods = useForm<CreatePostInterface>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: dataPost
      ? {
          themesIds: dataPost.themes.map((item) => item.id),
          title: dataPost.title,
          message: dataPost.message,
          image: dataPost.image,
          video: dataPost.video,
        }
      : {
          themesIds: [],
          title: '',
          message: '',
        },
  })
  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
  } = methods

  const initThemes = async () => {
    const _themes = (await getMemberThemes()) || []
    setThemes(
      _themes.map((item: MemberTheme) => ({ label: item.name, value: item.id }))
    )
  }

  const handleClose = () => {
    const action =
      typeof dataPost === 'undefined' ? t('common.create') : t('common.edit')
    showConfirmation({
      title: t(`post.cancel.title`),
      subtitle: t(`post.cancel.subtitle`, { action: action }),
      text: t(`post.cancel.text`),
      confirmText: t('common.yes'),
      cancelText: t('common.no'),
      iconName: 'report_problem',
      onConfirm: onClose,
    })
  }

  const handleRemoveImage = () => {
    setimagePath('')
    setValue('image', '')
    setValue('imagePath', '')
    setIsVideoOrImageValid(true)
  }

  const handleSetImage = (image: string) => {
    setimagePath(image)
    setShowImageUploadModal(false)
    handleRemoveVideo()
  }

  const handleRemoveVideo = () => {
    setVideoURL('')
    setValue('video', '')
    setIsVideoOrImageValid(true)
  }

  const handleSetVideo = (videoUrl: string) => {
    setValue('video', videoUrl)
    setVideoURL(videoUrl)
    setShowVideoUploadModal(false)
    handleRemoveImage()
  }

  const onSubmit = async (data: CreatePostInterface) => {
    setIsSubmitting(true)
    const post: CreatePostInterface = {
      ...data,
      publisherId: member?.id || '',
      publisherType: TypePublishEnum.User,
      channelsIds: [activeChannRef?.current?.id || ''],
    }
    if (imagePath && imagePath.startsWith('blob')) {
      try {
        const image = await uploadPostImage(imagePath)
        post.image = image?.uri || ''
      } catch {
        showConfirmation({
          title: t('common.warning'),
          subtitle: t('explicitImage.subtitle'),
          text: t('explicitImage.text'),
          confirmText: t('common.accept'),
          iconName: 'report_problem',
          hideCancel: true,
        })
        setIsVideoOrImageValid(false)
        setIsSubmitting(false)
        return
      }
    }
    try {
      if (typeof dataPost === 'undefined') {
        await createPost(post)
        pushDataLayer({
          event: 'content_create_community',
          category: 'interaction',
          action: 'content_create',
          extraParams: {
            content_title: post.title,
            content_channel: post.channelsIds.join('|'),
            content_topic: post.themesIds.join('|'),
            content_highlight: 0,
            content_author: member?.id,
            content_date: format(new Date(), 'yyyy-MM-dd'),
          },
        })
      } else {
        await updatePost(dataPost.id, post)
      }
      onCreate(post.themesIds)
      onClose()
      if (typeof dataPost === 'undefined') {
        notify.success(t('post.create.succeessFeedback'))
      } else notify.success(t('post.edit.succeessFeedback'))
    } catch (error: unknown) {
      if (typeof dataPost === 'undefined') {
        notify.error(t('post.create.unknownError'))
      } else notify.error(t('post.edit.unknownError'))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const shouldBlurActiveElement =
      !showVideoUploadModal || !showImageUploadModal
    if (shouldBlurActiveElement) (document.activeElement as HTMLElement).blur()
  }, [showVideoUploadModal, showImageUploadModal])

  useEffect(() => {
    activeChannRef.current = member?.channels.filter(
      (item) => item.isEnabled
    )[0]
    initThemes()
    if (dataPost) {
      setimagePath(dataPost.image)
      setVideoURL(dataPost.video)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Modal
        show={true}
        backdrop="static"
        onHide={handleClose}
        size="lg"
        className="CreatePost"
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {!dataPost ? t('post.modal.title.new') : t('post.modal.title.edit')}
          </Modal.Title>
        </Modal.Header>
        <FormProvider methods={methods}>
          <Modal.Body>
            <div className="CreatePost">
              <div className="d-flex flex-row-reverse CreatePost__leyend">
                <div className="bd-highlight">{t('common.required')}</div>
              </div>
              <div className="CreatePost__info mb-3 mt-4 p-3">
                <div className="d-flex CreatePost__info__content">
                  <div className="CreatePost__info__label d-flex">
                    {!dataPost
                      ? t('post.create.publishing')
                      : t('post.edit.publishing')}
                    <Tooltip
                      content={t('post.create.channel.tooltip')}
                      className="ms-2"
                    >
                      <Icon name="info_outline" tabIndex={0} />
                    </Tooltip>
                  </div>
                  <div className="CreatePost__info__value">
                    {member?.channels.filter((item) => item.isEnabled)[0].name}
                  </div>
                </div>
              </div>
              <div className="row CreatePost__contentForm CreatePost__contentForm__themesIds">
                <div className="col-12">
                  <FormMultiSelect
                    control={control}
                    name="themesIds"
                    label={t('post.create.theme.label')}
                    placeholder={t('post.create.theme.label')}
                    options={themes}
                    required={true}
                    tooltip={t('post.alertThemeSelected.tooltip')}
                  />
                </div>
              </div>
              <div className="row CreatePost__contentForm">
                <div className="col-12">
                  <FormRichText
                    label={t('post.create.message.label')}
                    control={control}
                    name="message"
                    placeholder={t('post.modal.text')}
                    required={true}
                    helperText={t('validations.leyendField.max', {
                      number: 5000,
                    })}
                  />
                </div>
              </div>
              <div className="CreatePost__uploader-container">
                <div className="CreatePost__uploader-container__components">
                  <div className="CreatePost__image">
                    <SimpleUploadControl
                      control={control}
                      title={t('post.modal.image')}
                      label={t('common.addImage')}
                      name="imagePath"
                      value={imagePath}
                      onChange={handleSetImage}
                      onRemoveFile={handleRemoveImage}
                      isOpen={showImageUploadModal}
                      handleOpen={() => setShowImageUploadModal(false)}
                    />
                  </div>
                  <div className="CreatePost__video">
                    {videoURL && (
                      <>
                        <div className="CreatePost__video__player">
                          <VideoPlayer
                            url={videoURL}
                            onError={() => setIsVideoOrImageValid(false)}
                            onSuccess={() => setIsVideoOrImageValid(true)}
                          />
                          {!isVideoOrImageValid && (
                            <div className="CreatePost__video__error">
                              <Icon name="report_problem" />
                              {t('post.videoUpload.error.message')}
                            </div>
                          )}
                        </div>
                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                        <div
                          className="CreatePost__video__delete"
                          onClick={handleRemoveVideo}
                        >
                          <div className="CreatePost__video__delete__inner">
                            <Icon name="delete_outline" />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="CreatePost__uploader-container__buttons">
                  <span className="fw-bold d-none d-lg-inline" tabIndex={-1}>
                    <Tooltip
                      content={t('common.addImage')}
                      className={
                        imagePath || videoURL
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="image"
                        onClick={() => setShowImageUploadModal(true)}
                        tabIndex={0}
                      />
                    </Tooltip>
                    <Tooltip
                      content={t('post.videoUpload.title')}
                      className={
                        videoURL || imagePath
                          ? 'toolActions withVideo'
                          : 'toolActions'
                      }
                      position="right"
                    >
                      <Icon
                        name="video_link"
                        className="CreatePost__video-icon"
                        onClick={() => setShowVideoUploadModal(true)}
                        tabIndex={0}
                      />
                    </Tooltip>
                  </span>
                  <span className="fw-bold d-inline d-lg-none">
                    <Icon
                      name="image"
                      onClick={() => setShowImageUploadModal(true)}
                    />
                    <Icon
                      name="video_link"
                      className="CreatePost__video-icon"
                      onClick={() => setShowVideoUploadModal(true)}
                    />
                  </span>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-primary" onClick={handleClose}>
              {t('common.cancel')}
            </button>
            <button
              className="btn btn-primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid || isSubmitting || !isVideoOrImageValid}
            >
              {typeof dataPost === 'undefined'
                ? t('common.publish')
                : t('common.save')}
            </button>
          </Modal.Footer>
        </FormProvider>
      </Modal>
      {showVideoUploadModal && (
        <VideoUpload
          onClose={() => setShowVideoUploadModal(false)}
          onSetVideo={handleSetVideo}
        />
      )}
    </>
  )
}

export default CreatePost
