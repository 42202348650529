/* eslint-disable camelcase */
import { UserManagerSettings, WebStorageStateStore } from 'oidc-client'

import { Settings } from '../settings/types'

export const buildOidcSettings = (settings: Settings): UserManagerSettings => {
  const { authority, clientId, clientScope } = settings.identity
  const clientRoot = location.origin

  return {
    authority: authority,
    automaticSilentRenew: true,
    scope: clientScope,
    client_id: clientId,
    userStore: new WebStorageStateStore({ store: localStorage }),
    post_logout_redirect_uri: `${clientRoot}/signed-out`,
    response_type: 'code',
    redirect_uri: `${clientRoot}/signin-callback`,
    silent_redirect_uri: `${clientRoot}/signin-silent-renew`,
  }
}

export class SigninCallbackError extends Error {
  redirectPath: string

  constructor(e: Error, redirectUri: string) {
    super(e.message)
    this.name = 'SigninCallbackError'
    this.redirectPath = redirectUri
  }
}
