import { useState } from "react";
import useFetch from "../../utils/fetch/useFetch";
import { Member } from "../member/types";

export const useChat = (tenantId : string, connectionId : string) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);

  // Base URL con versión fija
  const endpoint = 'chat'
  const { get, put, post, patch } = useFetch();

  const sendMessageToChat = async (user: string, message: string): Promise<void> => {
    try {
      const body = {
        username : user,
        message : message
      };
      await post({
        endpoint: `${endpoint}/send`,
        body : body
      });

    } catch (error) {
      console.error("Error al enviar el mensaje", error);
    }
  };

  const joinGroup = async (user : Member, groupId : string) => {
    const body = {
      tenantId : tenantId,
      connectionId : connectionId,
      username: (user.name || '') + ' ' + (user.surname || ''),
      groupId : groupId
    }

    try {

      await post({
        endpoint: `${endpoint}/joinGroup`,
        body : body
      });

    } catch (error) {
      console.error("Error al entrar al grupo", error);
    }

  }

  const leaveGroup = async (user : Member, groupId : string) => {
    const body = {
      tenantId : tenantId,
      connectionId : connectionId,
      username: (user.name || '') + ' ' + (user.surname || ''),
      groupId : groupId
    }

    try {

      await post({
        endpoint: `${endpoint}/leaveGroup`,
        body : body
      });

    } catch (error) {
      console.error("Error al entrar al grupo", error);
    }

  }

  const sendMessageToGroup = async (user: string, message: string, groupId : string): Promise<void> => {
    try {
      const body = {
        username : user,
        message : message,
        tenantId : tenantId,
        groupId : groupId
      };
      await post({
        endpoint: `${endpoint}/sendToGroup`,
        body : body
      });

    } catch (error) {
      console.error("Error al enviar el mensaje", error);
    }
  };

  const connect = async(user : Member) => {
    if (!connectionId) return;

    const body = {
      tenantId : tenantId,
      connectionId : connectionId,
      userId : user.id
    }

    try {
      await post({
        endpoint: `${endpoint}/handleConnectionStarted`,
        body : body
      });
    } catch (error) {
      console.error("Error al enviar el mensaje", error);
    }

  }

  const sendDirectMessage = async (receiverUserId: string, message: string, member : Member): Promise<void> => {
    try {
      const body = {
        receiverUserId,
        senderUserId: member?.id,
        message,
      };
  
      await post({
        endpoint: `${endpoint}/sendDirectMessage`,
        body,
      });
    } catch (error) {
      console.error("Error al enviar el mensaje directo", error);
    }
  };

  const getHistory = async () => {
    try {
      await get({
        endpoint: `${endpoint}/chat-history`,
      })
    }
    catch (error) {
      console.error("Error al obtener el historial de chats:", error);
    }
  }
  

  return {
    isConnected,
    sendMessageToChat,
    joinGroup,
    leaveGroup,
    sendMessageToGroup,
    sendDirectMessage, 
    connect,
    getHistory
  };
};
