import { FC, useEffect, useRef } from 'react'
import classNames from 'classnames'

import useScroll from '../../utils/useScroll'

import './index.scss'

interface MainProps {
  ariaLabelledby: string
  className?: string
}

export const Main: FC<MainProps> = ({
  ariaLabelledby,
  className,
  children,
}) => {
  const mainRef = useRef<HTMLDivElement>()
  const { scrollTop } = useScroll()

  function resetFocus() {
    mainRef.current.focus()
  }

  useEffect(() => {
    scrollTop()
    resetFocus()
  }, [])
  return (
    <main
      ref={mainRef}
      aria-labelledby={ariaLabelledby}
      className={classNames(
        'container-fluid lc-main container-fluid-xxl',
        className
      )}
      tabIndex={-1}
    >
      {children}
    </main>
  )
}
