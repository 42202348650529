import { FC, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { FormCheckbox, FormProvider } from '@liveconnect/components'
import { useTranslation } from 'react-i18next'
import { Channel, Topic } from '../../../core/activities/types'
import useMember from '../../../core/member/useMember'
import topicPlaceholder from '../../../../assets/images/topic-placeholder.png'

import './styles.scss'

interface ChannelsModalProps {
  onClose: () => void
  onSignUp: (values: string[]) => void
  topics: Topic[]
}

const ChannelsModal: FC<ChannelsModalProps> = ({
  onClose,
  topics,
  onSignUp,
}) => {
  const { t } = useTranslation()
  const { member } = useMember()
  const methods = useForm({
    mode: 'onChange',
  })
  const { control, handleSubmit, setValue } = methods

  const handleCancel = () => {
    onClose()
  }

  const handleConfirm = (values: Record<string, boolean>) => {
    const channelsToSend: string[] = Object.keys(values).filter(
      (key) => values[key]
    )
    onSignUp(channelsToSend)
  }

  const setSelectedChannels = () => {
    topics.forEach((topic: Topic) => {
      topic.channels.forEach((channel: Channel) => {
        setValue(channel.id, true)
      })
    })
  }

  const isDisabled = (id: string) => {
    const data = member?.channels.find((channel) => channel.id === id)
    if (data) {
      return true
    }
    return false
  }

  useEffect(() => {
    setSelectedChannels()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      show={true}
      backdrop="static"
      size="lg"
      className="channelsModal"
      enforceFocus={false}
    >
      <FormProvider methods={methods}>
        <Modal.Header className="justify-content-start">
          <Modal.Title>{t('activity.register.modal.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="channelsModal__title">
            <p>{t('activity.register.modal.head')}</p>
          </div>
          <p className="channelsModal__text">
            {t('activity.register.modal.body.text')}
          </p>
          <div className="channelsModal__content">
            <div className="row">
              {topics?.map((topic, index) => (
                <div className="col-12 col-md-3" key={index}>
                  <div className="channelsModal__content__topicFamiliy">
                    <img
                      src={topic.imageUrl || topicPlaceholder}
                      alt={topic.name}
                    />
                    <div className="channelsModal__content__topicFamiliy__content">
                      <div className="channelsModal__content__topicFamiliy__content__title">
                        {topic.name}
                      </div>
                      <div className="channelsModal__content__topicFamiliy__content__channels">
                        {(topic.channels || []).map((channel) => (
                          <FormCheckbox
                            key={channel.id}
                            control={control}
                            name={channel.id}
                            label={channel.name}
                            disabled={isDisabled(channel.id)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-primary" onClick={handleCancel}>
            {t('common.no.thanks')}
          </button>
          <button
            className="btn btn-primary"
            onClick={handleSubmit(handleConfirm)}
          >
            {t('home.go_to_channels')}
          </button>
        </Modal.Footer>
      </FormProvider>
    </Modal>
  )
}

export default ChannelsModal
