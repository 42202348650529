import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AccessDenyInfoResponse,
  TenantConfig,
  TenantConfigState,
} from './types'

const initialState: TenantConfigState = {
  tenantConfig: null,
  accessDenyInfo: null,
}

export const slice = createSlice({
  name: 'tenantConfig',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<TenantConfig>) => {
      state.tenantConfig = action.payload
    },
    setAccessDenyInfo: (
      state,
      action: PayloadAction<AccessDenyInfoResponse[]>
    ) => {
      state.accessDenyInfo = action.payload
    },
  },
})

export const { setConfig, setAccessDenyInfo } = slice.actions

export default slice.reducer
