import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { BadgeRecomendedMember } from '../../core/recomendedMembers/types'

import './styles.scss'

interface ViewBadgesProps {
  badges: BadgeRecomendedMember[]
}

const ViewBadges: FC<ViewBadgesProps> = ({ badges }) => {
  const { t } = useTranslation()
  const sliceBadges = useMemo(() => {
    if (badges.length >= 3) {
      return badges
        .slice(badges.length - 3)
        .map((badge: BadgeRecomendedMember) => (
          <div key={badge.id} className="content-image">
            <img src={badge.imageUrl} alt={badge.title} />
          </div>
        ))
    }
    return badges
      .slice(0, badges.length)
      .map((badge: BadgeRecomendedMember) => (
        <div key={badge.id} className="content-image">
          <img src={badge.imageUrl} alt={badge.title} />
        </div>
      ))
  }, [badges])

  return (
    <div className="ViewBadges">
      {badges && badges.length > 0 && (
        <div className="ViewBadges__badges">
          {sliceBadges}
          <p>
            {badges[badges.length - 1].title}{' '}
            {badges.length >= 3 &&
              t('badges.more', {
                number: badges.length - 1,
              })}
            {badges.length === 2 && t('badges.more.singular')}
          </p>
        </div>
      )}
    </div>
  )
}

export default ViewBadges
