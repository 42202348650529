import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useNavigate } from 'react-router-dom'

import { FormCheckbox, Loader } from '@liveconnect/components'

import { Main } from '../../../components/Main/index'
import FormActions from '../../../components/FormActions'

import useTopics from '../../../core/topicFamilies/useTopicFamilies'
import useUi from '../../../core/ui/useUi'
import useAuth from '../../../core/auth/useAuth'
import useMember from '../../../core/member/useMember'
import useSnackBars from '../../../utils/notifications/useNotifications'
import { useCustomRouter } from '../../../utils/useCustomRouter'

import { Topic } from '../../../core/topicFamilies/types'

import { selectChannelsValidationSchema } from './validations'

import people from '../../../../assets/images/onboarding_ilus.svg'
import logo from '../../../../assets/images/logo.svg'
import topicPlaceholder from '../../../../assets/images/topic-placeholder2.png'

interface SelectChannelsForm {
  channels: Record<string, boolean>
}

const SelectChannelsScreen = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showConfirmation } = useUi()
  const { getTopics } = useTopics()
  const { updateMemberChannels, fetchMember } = useMember()
  const notify = useSnackBars()
  const { basePath } = useCustomRouter()
  const { logout } = useAuth()
  const [topics, setTopics] = useState<Topic[]>()
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const methods = useForm<SelectChannelsForm>({
    mode: 'onChange',
    resolver: yupResolver(selectChannelsValidationSchema()),
  })
  const {
    control,
    formState: { isValid },
    reset,
    handleSubmit,
  } = methods

  const fetchTopics = async () => {
    const response = await getTopics()
    setTopics(response)
  }

  const onSubmit = async (values: SelectChannelsForm) => {
    setIsSubmitting(true)
    try {
      const channelsToSend: string[] = Object.keys(values.channels).filter(
        (key) => values.channels[key]
      )
      await updateMemberChannels(channelsToSend)
      reset()
      await fetchMember()
      navigate(basePath)
    } catch (e) {
      notify.error(t('channels.list.unknownError'))
    } finally {
      setIsSubmitting(false)
    }
  }

  const onBack = async () => {
    showConfirmation({
      title: t(`common.warning`),
      subtitle: t(`selectChannels.backModal.subtitle`),
      confirmText: t('common.accept'),
      cancelText: t('common.exit'),
      iconName: 'report_problem',
      onCancel: logout,
    })
  }

  useEffect(() => {
    fetchTopics()
  }, [])

  return (
    <div className="onboarding">
      <div className="onboarding__header">
        <div className="onboarding__header-background"></div>
        <Main ariaLabelledby="onboardingheader">
          <div className="row">
            <div className="col-9 col-md-7 onboarding__header__text">
              <h1>{t('selectChannels.skew.title')}</h1>
              <p>{t('selectChannels.skew.subtitle')}</p>
            </div>
            <div className="col-2 col-lg-4">
              <img
                src={people}
                alt="people"
                className="onboarding__header-people"
              />
            </div>
            <div className="col-lg-1 d-none d-lg-block">
              <img src={logo} alt="people" className="dsadsadsa" />
            </div>
          </div>
        </Main>
        <img
          src={logo}
          alt="people"
          className="onboarding__header-logo d-lg-none"
        />
      </div>
      <Main ariaLabelledby="onboarding">
        <h2 className="h4">{t('selectChannels.title')}</h2>
        <p className="mb-4">{t('selectChannels.subtitle')}</p>

        <div className="Step2__list d-flex flex-wrap">
          {(topics || []).map((topic, index) => (
            <div className="Step2__list__topic" key={index}>
              <img src={topic.imageUrl || topicPlaceholder} alt="title" />
              <div className="Step2__list__topic__content">
                <div className="Step2__list__topic__content__title">
                  {topic.name}
                </div>
                <div className="Step2__list__topic__content__channels">
                  {topic.channels.map((channel) => (
                    <FormCheckbox
                      key={channel.id}
                      control={control}
                      name={`channels.${channel.id}`}
                      label={channel.name}
                    />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
        <FormActions>
          <div className="formContainer__actions">
            <button
              type="button"
              onClick={onBack}
              className="btn btn-outline-primary"
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn-primary"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            >
              {isSubmitting ? <Loader /> : t('common.save')}
            </button>
          </div>
        </FormActions>
      </Main>
    </div>
  )
}

export default SelectChannelsScreen
