import useFetch from '../../utils/fetch/useFetch'
import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  hideLoader,
  setFooterConfiguration,
  setOnboardingConfiguration,
  showLoader,
} from './store'

import {
  ConfigurationFooterResponse,
  ConfigurationOnboardingResponse,
} from './types'

const useConfiguration = () => {
  const dispatch = useAppDispatch()
  const { isLoaded, footer, onboarding } = useAppSelector(
    (state) => state.configuration
  )

  const { get } = useFetch()

  enum ENDPOINTS {
    Footer = 'footers',
    Onboarding = 'onboarding-configuration',
  }

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchFooterConfiguration = async () => {
    await dispatchLoaders(async () => {
      const response: ConfigurationFooterResponse | undefined = await get({
        endpoint: ENDPOINTS.Footer,
      })
      if (response) dispatch(setFooterConfiguration(response))
    })
  }

  const fetchOnboardingConfiguration = async () => {
    await dispatchLoaders(async () => {
      const response: ConfigurationOnboardingResponse | undefined = await get({
        endpoint: ENDPOINTS.Onboarding,
      })
      if (response) dispatch(setOnboardingConfiguration(response))
    })
  }

  return {
    isLoaded,

    footer,
    fetchFooterConfiguration,

    onboarding,
    fetchOnboardingConfiguration,
  }
}

export default useConfiguration
