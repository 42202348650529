import { FC, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'

import { FormCheckbox, FormProvider } from '@liveconnect/components'
import { FiltersModal } from '@liveconnect/communities-ui'

import useMember from '../../../core/member/useMember'
import { ActivateMember, MemberTheme } from '../../../core/member/types'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useGTM from '../../../utils/useGTM'

import './styles.scss'

interface FilterPanelProps {
  onClose: () => void
  isOpen: boolean
}

interface ResponseFilter {
  [x: string]: boolean | undefined
}

const FilterPanel: FC<FilterPanelProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()
  const notify = useSnackBars()
  const { member, activateMemberThemes, memberThemes } = useMember()
  const { pushDataLayer } = useGTM()
  const filterPanelWrapper = useRef<HTMLDivElement>(
    document.createElement('div')
  )

  const methods = useForm<ResponseFilter>({
    mode: 'onChange',
  })
  const { control, handleSubmit, setValue } = methods

  const activeChannel = useMemo(
    () => (member?.channels || []).filter((item) => item.isEnabled)[0],
    [member?.channels]
  )

  const setSelectedChannels = async () => {
    memberThemes.forEach((theme: MemberTheme) => {
      setValue(theme.id, theme.isActive)
    })
  }

  const handleReset = () => {
    memberThemes.forEach((theme: MemberTheme) => {
      setValue(theme.id, false)
    })
  }

  const onSubmit = async (values: ResponseFilter) => {
    try {
      const activeThemes: ActivateMember[] = Object.keys(values)
        .filter((key) => values[key])
        .map((item) => ({ id: item }))
      await activateMemberThemes(activeThemes)
      pushDataLayer({
        event: 'apply_filter_community',
        category: 'interaction',
        action: 'apply_filter',
      })
      notify.success(t('Channels_filter.panel.succeessFeedback'))
    } catch (e) {
      notify.error(t('Channels_filter.panel.unknownError'))
    } finally {
      onClose()
    }
  }

  useEffect(() => {
    isOpen && setSelectedChannels()
  }, [isOpen])

  useEffect(() => {
    const filterPanelElement = document.getElementById('left-panel')
    filterPanelElement?.appendChild(filterPanelWrapper.current)
  }, [])

  return (
    <FiltersModal
      isOpened={isOpen}
      onClose={onClose}
      onReset={handleReset}
      onFilter={handleSubmit(onSubmit)}
      texts={{
        title: t('Channels_filter.panel.title'),
        reset: t('Channels_filter.panel.reset'),
        viewResults: t('Channels_filter.panel.apply'),
      }}
    >
      <FormProvider methods={methods}>
        <FiltersModal.Section title={activeChannel?.name}>
          {memberThemes.length ? (
            <>
              {memberThemes.map((theme: MemberTheme) => {
                return (
                  <div className="FilterPanel__checkbox" key={theme.id}>
                    <FormCheckbox
                      control={control}
                      name={theme.id}
                      label={theme.name}
                    />
                  </div>
                )
              })}
            </>
          ) : (
            <div className="FilterPanel__checkbox">
              {t('Channels_filter.panel.empty')}
            </div>
          )}
        </FiltersModal.Section>
      </FormProvider>
    </FiltersModal>
  )
}

export default FilterPanel
