import React from 'react';
import './ConversationItem.scss';

import deleteIcon from '../../../../assets/images/delete.svg';
import { Chat } from '../../../core/new-chat/types';
import useMember from '../../../core/member/useMember';
import { Avatar } from '@liveconnect/components';

interface ConversationItemProps {
  chat: Chat;
  isActive: boolean;
  onClick?: () => void;
}

const ConversationItem: React.FC<ConversationItemProps> = ({
  chat,
  isActive,
  onClick
}) => {

  const { name, messages } = chat;
  let otherName = '';
  const lastMessage = messages[messages.length - 1];
  const lastMessageTime = lastMessage
    ? new Date(lastMessage.sentAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
    : '';
    const lastMessageContent = lastMessage?.content || '';

    const { member } = useMember();
    // const myId = member?.id;
    const myId = 'user7';

    let participantName = "";
    let participantSurname = "";
    let participantImageUrl = "";

    //TODO Comprobar también el tipo de chat y no solo el nombre
    if (chat.participants.length === 2) {
      const otherParticipant = chat.participants.find(participant => participant.userId !== myId);
      
      if (otherParticipant) {
        const { name, surname, imageUrl } = otherParticipant;
        participantName = name;
        participantSurname = surname;
        participantImageUrl = imageUrl || "";
        otherName = `${name} ${surname}`;
      }
    }

  return (
    <div className={`conversation-item ${isActive ? 'conversation-item--active' : ''}`} onClick={onClick}>
      <Avatar name={participantName} surname={participantSurname} imageUrl={participantImageUrl} size="md" />
      <div className='conversation-item__details'>
        <div className='conversation-item__details__texts'>
        <p className="conversation-item__details__name">{name || otherName}</p>
          <p className="conversation-item__details__last-message">{lastMessageContent}</p>
        </div>
        <div className='conversation-item__details__actions'></div>
          {/* TODO cambiar por lastMessageTime */}
          <p className='conversation-item__details__time'>10:00</p>
          <button
            className="conversation-item__delete-button"
            aria-label="Eliminar conversación"
          >
           <img
                src={deleteIcon}
              />
          </button>
      </div>
      {/* TODO: unreadMessages */}
      {/* {unreadMessages && unreadMessages > 0 && (
          <div className="conversation-item__unread-messages"></div>
        )} */}
    </div>
  );
};

export default ConversationItem;
