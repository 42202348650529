import { useAppDispatch, useAppSelector } from '../reduxProvider'
import { useUiResult } from './types'
import {
  showPanelRight,
  hidePanelRight,
  showPanelLeft,
  hidePanelLeft,
  showConfirmation,
  hideConfirmation,
} from './store'

const useUi = (): useUiResult => {
  const dispatch = useAppDispatch()
  const { isPanelVisibleRight, isPanelVisibleLeft, confirmation } =
    useAppSelector((state) => state.ui)

  const toggleShowPanelRight = () => {
    isPanelVisibleLeft && dispatch(hidePanelLeft())
    isPanelVisibleRight
      ? dispatch(hidePanelRight())
      : dispatch(showPanelRight())
  }

  const toggleShowPanelLeft = () => {
    isPanelVisibleRight && dispatch(hidePanelRight())
    isPanelVisibleLeft ? dispatch(hidePanelLeft()) : dispatch(showPanelLeft())
  }

  return {
    isPanelVisibleRight,
    isPanelVisibleLeft,
    confirmation,
    toggleShowPanelRight,
    toggleShowPanelLeft,
    showConfirmation: (data) => dispatch(showConfirmation(data)),
    hideConfirmation: () => dispatch(hideConfirmation()),
  }
}

export default useUi
