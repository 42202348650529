import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseContactsResult,
  ContactListResponse,
  ContactRequestParams,
} from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useContacts = (): UseContactsResult => {
  const dispatch = useAppDispatch()
  const {
    list,
    page,
    pageSize,
    availablePages,
    availableItems,
    search,
    isLoaded,
  } = useAppSelector((state) => state.contacts)
  const { get, del } = useFetch()

  const endpoint = 'contacts'

  const parseContactParams = (requestParams?: ContactRequestParams) => {
    const params: ContactRequestParams = {
      page_size: pageSize,
      search: (requestParams?.search !== undefined
        ? requestParams?.search
        : search) as string,
      page: (requestParams?.page || page) as number,
    }
    return params
  }

  const getContacts = async (params?: ContactRequestParams) => {
    const response: ContactListResponse | undefined = await get({
      endpoint,
      params,
    })
    return response
  }

  const fetchContacts = async (params?: ContactRequestParams) => {
    dispatch(showLoader())
    const reqParams = parseContactParams(params)
    const response: ContactListResponse | undefined = await getContacts(
      reqParams
    )
    response &&
      dispatch(
        setList({
          search: reqParams.search as string,
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const clearContactList = () => dispatch(clearList())

  const deleteContact = async (id: string) => {
    return await del({ endpoint, id })
  }

  return {
    contactList: list,
    page,
    availablePages,
    availableItems,
    pageSize,
    search,
    isLoaded,
    fetchContacts,
    clearContactList,
    deleteContact,
  }
}

export default useContacts
