import { useAppSelector, useAppDispatch } from '../reduxProvider'
import useFetch from '../../utils/fetch/useFetch'
import { showLoader, hideLoader, setList, setDetail } from './store'
import { ElementDetail, ElementListParams, ElementListResponse } from './types'

const useElements = () => {
  const dispatch = useAppDispatch()

  const { list, detail, isLoaded } = useAppSelector((state) => state.elements)
  const { get } = useFetch()

  const endpoint = 'elements'

  const dispatchLoaders = async function dispatchLoaders<T>(
    asynCall: () => Promise<T>
  ) {
    dispatch(showLoader())
    try {
      return await asynCall()
    } finally {
      dispatch(hideLoader())
    }
  }

  const fetchElements = async (params: ElementListParams) => {
    await dispatchLoaders(async () => {
      const response: ElementListResponse | undefined = await get({
        endpoint,
        params,
      })
      if (response)
        if (list && list.page < params.page) {
          dispatch(
            setList({
              ...response,
              items: [...(list ? list.items : []), ...response.items],
            })
          )
        } else {
          dispatch(setList(response))
        }
    })
  }

  const fetchElement = async (id: string) => {
    await dispatchLoaders(async () => {
      const response: ElementDetail | undefined = await get({
        endpoint: `${endpoint}/${id}`,
      })
      if (response) dispatch(setDetail(response))
    })
  }

  return {
    list,
    detail,
    isLoaded,
    fetchElements,
    fetchElement,
  }
}

export default useElements
