import { useTranslation } from 'react-i18next'

import { ErrorPage } from '@liveconnect/components'

import { Main } from '../../../components/Main'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <Main ariaLabelledby="page-not-found">
      <ErrorPage className="page-error-not-found">
        <>
          <ErrorPage.Icon name="report_problem" />
          <ErrorPage.Title text={t('notFound.title')} />
        </>
      </ErrorPage>
    </Main>
  )
}

export default NotFound
