import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ChatState } from './types'

const initialState: ChatState = {
  isLoaded: false,
  hasNewMessages: false,
  hasErrors: false,
}

export const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload
    },
    setHasNewMessages: (state, action: PayloadAction<boolean>) => {
      state.hasNewMessages = action.payload
    },
    setHasErrors: (state, action: PayloadAction<boolean>) => {
      state.hasErrors = action.payload
    },
  },
})

export const { setIsLoaded, setHasNewMessages, setHasErrors } = slice.actions

export default slice.reducer
