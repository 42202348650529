import { Controller, FieldError, Control, Path } from 'react-hook-form'
import { useEffect, useRef } from 'react'

import { Icon } from '@liveconnect/icons'

import './index.scss'

interface UploadControlProps<Z> {
  control: Control<Z, object>
  name: keyof Z
  title: string
  label: string
  error?: FieldError
  readonly?: boolean
  required?: boolean
  videoMaxWeight?: number
  aspectRatio?: [number, number]
  value: string
  tooltip?: string
  onRemoveFile?: () => void
  onChange: (url: string) => void
  isOpen: boolean
  handleOpen: () => void
}

export const SimpleUploadControl = function <T>({
  control,
  name,
  value,
  onChange,
  onRemoveFile,
  isOpen,
  handleOpen,
  ...props
}: UploadControlProps<T>) {
  const inputFileRef = useRef<HTMLInputElement>(null)

  const preparePreview = async (files: File[]) => {
    onChange(URL.createObjectURL(files[0]))
  }

  const handleChange = () => {
    preparePreview(Array.from(inputFileRef?.current?.files ?? []))
  }

  const handleRemove = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
    }

    preparePreview(Array.from(inputFileRef?.current?.files ?? []))
    onRemoveFile && onRemoveFile()
  }

  useEffect(() => {
    if (isOpen && inputFileRef.current !== null) {
      inputFileRef.current.click()
      handleOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <Controller
      control={control}
      name={name as unknown as Path<T>}
      render={() => (
        <div className="lc-form-control-group simple-input-file">
          {value && (
            <div className="simple-input-file__image-wrapper d-flex justify-content-flex-start">
              <img src={value} alt="" />
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
              <div className="simple-input-file__delete" onClick={handleRemove}>
                <div className="simple-input-file__delete__inner">
                  <Icon name="delete_outline" />
                </div>
              </div>
            </div>
          )}
          <div className="simple-input-file__info">
            <label className="btn btn-link p-0 m-0">
              <input
                name="simple-input-file-upload"
                {...props}
                ref={inputFileRef}
                type="file"
                hidden
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
      )}
    />
  )
}
