import { Chat } from "../../core/new-chat/types";

export const mockChats : Chat[] = [
    {
      chatId: '1',
      chatType: { chatTypeId: '2', name: 'Direct' },
      createdAt: new Date(),
      createdBy: 'user7',  // Tu userId
      participants: [
        {
          chatParticipantId: '1',
          chatId: '1',
          userId: 'user7',  // Tu userId
          name: 'Julián',
          surname: 'Test',
          jobTitle: 'Developer',
          joinedAt: new Date(),
          chatRole: { id: '2', role: 'Member' },
        },
        {
          chatParticipantId: '2',
          chatId: '1',
          userId: 'user2',
          name: 'Carlos',
          surname: 'García',
          jobTitle: 'Developer',
          joinedAt: new Date(),
          chatRole: { id: '2', role: 'Member' },
        },
      ],
      messages: [
        {
          messageId: '1',
          content: '¡Hola! ¿Cómo estás?',
          sentAt: new Date('2024-12-02T15:30:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '2',
            chatId: '1',
            userId: 'user2',
            name: 'Carlos',
            surname: 'García',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '2',
          content: 'Todo bien, ¿y tú?',
          sentAt: new Date('2024-12-02T15:45:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '3',
          content: 'Todo en orden, gracias por preguntar.',
          sentAt: new Date('2024-12-02T15:50:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '4',
          content: '¿Nos vemos mañana para la reunión?',
          sentAt: new Date('2024-12-03T10:00:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '5',
          content: 'Sí, claro, ¿a qué hora?',
          sentAt: new Date('2024-12-03T10:05:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '2',
            chatId: '1',
            userId: 'user2',
            name: 'Carlos',
            surname: 'García',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '6',
          content: '¿A las 2 PM te va bien?',
          sentAt: new Date('2024-12-03T10:10:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '7',
          content: 'Perfecto, nos vemos a las 2.',
          sentAt: new Date('2024-12-03T10:15:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '2',
            chatId: '1',
            userId: 'user2',
            name: 'Carlos',
            surname: 'García',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '8',
          content: 'Genial, hasta luego.',
          sentAt: new Date('2024-12-03T10:20:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '9',
          content: '¡Hola Carlos! ¿Cómo va todo hoy?',
          sentAt: new Date('2024-12-04T09:30:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '10',
          content: 'Todo bien, estoy revisando los últimos cambios. ¿Y tú?',
          sentAt: new Date('2024-12-04T09:35:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '2',
            chatId: '1',
            userId: 'user2',
            name: 'Carlos',
            surname: 'García',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '11',
          content: 'Todo listo por aquí, solo revisando algunas tareas pendientes.',
          sentAt: new Date('2024-12-04T09:40:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '12',
          content: '¡No hay problema! Yo también estoy pendiente de mis tareas.',
          sentAt: new Date('2024-12-04T09:45:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '13',
          content: '¡Genial! ¿Cómo va el avance del proyecto?',
          sentAt: new Date('2024-12-04T09:50:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '14',
          content: 'e',
          sentAt: new Date('2024-12-04T09:50:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '1',
            chatId: '1',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Developer',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
      ],
    },
    {
      chatId: '2',
      chatType: { chatTypeId: '2', name: 'Direct' },
      createdAt: new Date('2024-11-01'),
      createdBy: 'user3',
      participants: [
        {
          chatParticipantId: '3',
          chatId: '2',
          userId: 'user7',  // Tu userId
          name: 'Julián',
          surname: 'Test',
          jobTitle: 'Manager',
          joinedAt: new Date(),
          chatRole: { id: '2', role: 'Member' },
        },
        {
          chatParticipantId: '4',
          chatId: '2',
          userId: 'user4',
          name: 'Laura',
          surname: 'Martínez',
          jobTitle: 'Manager',
          joinedAt: new Date(),
          chatRole: { id: '2', role: 'Member' },
        },
      ],
      messages: [
        {
          messageId: '1',
          content: '¡Hola! ¿Cómo estás?',
          sentAt: new Date('2024-12-01T18:00:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '4',
            chatId: '2',
            userId: 'user4',
            name: 'Laura',
            surname: 'Martínez',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '2',
          content: 'Todo bien, ¿y tú?',
          sentAt: new Date('2024-12-01T18:05:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '3',
            chatId: '2',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '3',
          content: 'Todo en orden, gracias por preguntar.',
          sentAt: new Date('2024-12-01T18:10:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '3',
            chatId: '2',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '4',
          content: '¿Nos vemos mañana para la reunión?',
          sentAt: new Date('2024-12-02T10:00:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '3',
            chatId: '2',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '5',
          content: 'Sí, claro, ¿a qué hora?',
          sentAt: new Date('2024-12-02T10:05:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '4',
            chatId: '2',
            userId: 'user4',
            name: 'Laura',
            surname: 'Martínez',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '6',
          content: '¿A las 2 PM te va bien?',
          sentAt: new Date('2024-12-02T10:10:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '3',
            chatId: '2',
            userId: 'user7',  // Tu mensaje
            name: 'Julián',
            surname: 'Test',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '7',
          content: 'Perfecto, nos vemos a las 2.',
          sentAt: new Date('2024-12-02T10:15:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '4',
            chatId: '2',
            userId: 'user4',
            name: 'Laura',
            surname: 'Martínez',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
        {
          messageId: '8',
          content: 'Mejor a y media',
          sentAt: new Date('2024-12-02T10:15:00'),
          isDeleted: false,
          sentBy: {
            chatParticipantId: '4',
            chatId: '2',
            userId: 'user4',
            name: 'Laura',
            surname: 'Martínez',
            jobTitle: 'Manager',
            joinedAt: new Date(),
            chatRole: { id: '2', role: 'Member' },
          },
        },
      ],
    },
  ];