import { Icon } from '@liveconnect/icons'
import { useTranslation } from 'react-i18next'

import useMember from '../../../core/member/useMember'
import { useCustomRouter } from '../../../utils/useCustomRouter'

import './styles.scss'

const ValidateDataMember = () => {
  const { t } = useTranslation()
  const { validateDataMember, member } = useMember()
  const { section } = useCustomRouter()

  const handleValidate = async () => {
    if (member && member.id) {
      await validateDataMember(member.id)
    }
  }

  return member &&
    member.id &&
    member?.changeToValidate &&
    section !== 'member' ? (
    <div className="validate-data-member">
      <Icon className="validate-data-member__icon" name="info_outline" />
      <span
        className="validate-data-member__content"
        dangerouslySetInnerHTML={{ __html: t('changeToValidate.warningText') }}
      ></span>
      <button
        onClick={handleValidate}
        type="button"
        className="validate-data-member__close-btn"
      >
        <Icon name="close" />
      </button>
    </div>
  ) : (
    <></>
  )
}

export default ValidateDataMember
