import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Icon } from '@liveconnect/icons'
import { Loader, Avatar } from '@liveconnect/components'

import {
  Contact,
  Attendees,
  TypeActivity,
} from '../../../core/activities/types'
import { isoCodeEnum } from '../../../core/Localizations/types'
import useCulture from '../../../utils/culture'
import useActivities from '../../../core/activities/useActivities'

import './styles.scss'

export interface RegisterComponentProps {
  type: TypeActivity | string
  start: string
  end: string
  language: string
  attendees: Attendees
  isRegistered: boolean
  isSubmitting: boolean
  videoUrl: string | undefined
  onClick: () => void
  handleCancel: () => void
}

export const RegisterComponent: FC<RegisterComponentProps> = ({
  type,
  start,
  end,
  language,
  attendees,
  isRegistered,
  isSubmitting,
  videoUrl,
  onClick,
  handleCancel,
}): JSX.Element => {
  const { t } = useTranslation()
  const { formatDate } = useCulture()
  const [join, setJoin] = useState<boolean>(false)
  const [endActivity, setEndactivity] = useState<boolean>(false)
  const { activity } = useActivities()

  const addVal = (num: number) => {
    if (num >= 0 && num < 10) {
      return `0${num}`
    }
    return num
  }

  const isToday = (other: Date) => {
    const today = new Date()
    const todayYear = today.getFullYear()
    const todayMonth = today.getMonth()
    const todayDay = today.getDate()
    const otherYear = other.getFullYear()
    const otherMonth = other.getMonth()
    const otherDay = other.getDate()
    if (
      todayYear === otherYear &&
      todayMonth === otherMonth &&
      todayDay === otherDay
    ) {
      return true
    }
    return false
  }

  const transformDate = () => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const hour = `${addVal(startDate.getHours())}:${addVal(
      startDate.getMinutes()
    )} - ${addVal(endDate.getHours())}:${addVal(endDate.getMinutes())}`

    return hour
  }

  const transformLang = () => {
    if (language !== isoCodeEnum.EN) {
      return t('localizations.es-ES')
    }
    return t('localizations.en-GB')
  }

  const parseDates = () => {
    const startDate = new Date(start).getTime()
    const endDate = new Date(end).getTime()
    const today = new Date().getTime()
    if (today >= startDate && today < endDate && isRegistered) {
      setJoin(true)
    }
    if (today > endDate) {
      setEndactivity(true)
    }
  }

  useEffect(() => {
    parseDates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end])

  return (
    <>
      <div className="Register">
        <div className="Register__header">
          <div className="icon">
            <Icon name="access_time" className="icon--disabled" />
          </div>
          <div className="text">
            <p>{t('activity.register.date')}</p>
          </div>
        </div>
        <div className="Register__content">
          <div className="icon"></div>
          <div className="text">
            <p>
              {isToday(new Date(start)) &&
                `${t('activity.register.date.today')}, `}
              {activity?.type === TypeActivity.Challenge
                ? formatDate(new Date(start), 'LONG_DATE_TIME')
                : formatDate(new Date(start), 'LONG_DATE')}
            </p>
            <p>
              {activity?.type === TypeActivity.Challenge
                ? formatDate(new Date(end), 'LONG_DATE_TIME')
                : transformDate()}
            </p>
          </div>
        </div>
        <div className="Register__header">
          <div className="icon">
            <Icon name="room_preferences" className="icon--disabled" />
          </div>
          <div className="text">
            <p>{t('activity.register.type')}</p>
          </div>
        </div>
        <div className="Register__content">
          <div className="icon"></div>
          <div className="text">
            <p>{type}</p>
            {activity?.type === TypeActivity.FaceToFace && (
              <div className="contentPlace">
                {activity.address && (
                  <div>
                    <p>{activity.address.fullAddress}</p>
                    <a
                      href={activity.address.mapUrl}
                      className="text-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('activity.place.link')}
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="Register__header">
          <div className="icon">
            <Icon name="language" className="icon--disabled" />
          </div>
          <div className="text">
            <p>{t('activity.register.language')}</p>
          </div>
        </div>
        <div className="Register__content">
          <div className="icon"></div>
          <div className="text">
            <p>{transformLang()}</p>
          </div>
        </div>
        <div className="Register__header">
          <div className="icon">
            <Icon name="people" className="icon--disabled" />
          </div>
          <div className="text">
            <p>{t('activity.register.attendees')}</p>
          </div>
        </div>
        <div className="Register__content">
          <div className="icon"></div>
          <div className="text">
            {attendees.hasMaxAttendees ? (
              <>
                <p>
                  {t('activity.register.attendees.less', {
                    less: attendees.max - attendees.count,
                  })}
                </p>
                <p className="maxAtten">
                  {t('activity.register.attendees.max', { max: attendees.max })}
                </p>
              </>
            ) : (
              <p>{t('activity.register.attendees.noMaxAttendees')}</p>
            )}
          </div>
        </div>
        <div className="Register__header">
          <div className="icon">
            <Icon name="groups" className="icon--disabled" />
          </div>
          <div className="text">
            {t('activity.register.registeredAttendees')}
          </div>
        </div>
        <div className="Register__content">
          <div className="icon"></div>
          <div className="text">
            <p>
              {attendees.contacts.length === 1
                ? t('activity.register.attende.contact', {
                    num: attendees.contacts.length,
                  })
                : t('activity.register.attendees.contact', {
                    num: attendees.contacts.length,
                  })}
            </p>
            {attendees.contacts.length > 0 && (
              <div className="contentAvatar">
                {attendees.contacts.slice(0, 4).map((contact: Contact) => (
                  <Avatar
                    key={contact.id}
                    name={contact.name}
                    surname={contact.surname}
                    alt={`${contact.name} ${contact.surname}`}
                    imageUrl={contact.imageUrl || undefined}
                    size="sm"
                  />
                ))}
                {attendees.contacts.length > 4 && (
                  <span className="contentNumber">
                    +{attendees.contacts.length - 4}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
        {!isRegistered && !endActivity && (
          <button
            className="btn btn-primary Register__button"
            onClick={onClick}
            disabled={
              attendees.hasMaxAttendees && attendees.count >= attendees.max
            }
          >
            {isSubmitting ? (
              <Loader />
            ) : activity?.type === TypeActivity.Challenge ? (
              t('activity.register.signupChallenge')
            ) : (
              t('activity.register.signup')
            )}
          </button>
        )}
        {join &&
          activity?.type !== TypeActivity.FaceToFace &&
          activity?.type !== TypeActivity.Challenge && (
            <a
              href={videoUrl ? videoUrl : ''}
              className="btn btn-primary Register__button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('activity.join')}
            </a>
          )}
        {join &&
          activity?.type === TypeActivity.Challenge &&
          activity?.challengeTypeUrl && (
            <a
              href={activity.challengeTypeUrl}
              className="btn btn-primary Register__button"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('activity.goToChallenge')}
            </a>
          )}
      </div>
      {isRegistered && !endActivity && (
        <div className="isRegistered">
          <div className="isRegistered__header">
            <div className="icon">
              <Icon name="info_outline" className="icon--disabled" />
            </div>
            <div className="text">
              <p>{t('activity.register.signed')}</p>
            </div>
          </div>
          <div className="isRegistered__content">
            <div className="icon"></div>
            <div className="text">
              <button className="btn btn-link" onClick={handleCancel}>
                {t('activity.register.signed.action')}
                <Icon name="keyboard_arrow_right" />
              </button>
            </div>
          </div>
        </div>
      )}
      {attendees.hasMaxAttendees &&
        !isRegistered &&
        attendees.count >= attendees.max && (
          <div className="isFinished">
            <div className="isFinished__header">
              <div className="icon">
                <Icon name="info_outline" className="icon--disabled" />
              </div>
              <div className="text">
                <p>{t('activity.register.full')}</p>
              </div>
            </div>
          </div>
        )}
      {endActivity && (
        <div className="isFinished">
          <div className="isFinished__header">
            <div className="icon">
              <Icon name="info_outline" className="icon--disabled" />
            </div>
            <div className="text">
              <p>{t('activity.finished')}</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
