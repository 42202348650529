import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import ChatScreen from '../pages/Chat'
import ChatPage from '../pages/NewChat'

const ChatRouter: FC = () => {
  return (
    <Routes>
      {/* <Route path="/" element={<ChatScreen />} />
      <Route path="/:userId" element={<ChatScreen />} /> */}

      <Route path="/" element={<ChatPage />} />
      <Route path="/:userId" element={<ChatPage />} />
    </Routes>
  )
}

export default ChatRouter
