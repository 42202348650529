import { FC, useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FieldValues, useForm } from 'react-hook-form'
import { FiltersModal } from '@liveconnect/communities-ui'
import {
  FormCheckbox,
  FormDate,
  FormMultiSelect,
  FormSelect,
  FormProvider,
} from '@liveconnect/components'

import {
  ActivitiesRequestParams,
  BrandSpace,
  Channel,
  TypeActivity,
} from '../../../core/activities/types'
import useActivities from '../../../core/activities/useActivities'
import useSnackBars from '../../../utils/notifications/useNotifications'
import { buildValidationSchema } from './validations'
import { yupResolver } from '@hookform/resolvers/yup'
import useGTM from '../../../utils/useGTM'

import './styles.scss'

interface FilterPanelProps {
  onClose: () => void
  isOpen: boolean
  withParams: (param: boolean) => void
}

interface SelectType {
  value: string
  label: string
}

interface responseFilter {
  brands: string
  channels: string[]
  video: boolean
  debate: boolean
  challenge: boolean
  roundTable: boolean
  askAuthor: boolean
  faceToFace: boolean
  startDate: string
  endDate: string
}

const DEFAULT_VALUES = {
  brands: '',
  channels: [],
  video: false,
  debate: false,
  challenge: false,
  roundTable: false,
  askAuthor: false,
  faceToFace: false,
  startDate: '',
  endDate: '',
}

const FilterActivities: FC<FilterPanelProps> = ({
  onClose,
  isOpen,
  withParams,
}) => {
  const { t } = useTranslation()
  const notify = useSnackBars()
  const { fetchActivities, brandSpaces, fetchChannels } = useActivities()
  const [valuesBrands, setValuesBrands] = useState<SelectType[]>([])
  const [valuesChannels, setValuesChannels] = useState<SelectType[]>([])
  const { pushDataLayer } = useGTM()
  const filterPanelWrapper = useRef<HTMLDivElement>(
    document.createElement('div')
  )

  const methods = useForm<responseFilter | FieldValues>({
    mode: 'onChange',
    resolver: yupResolver(buildValidationSchema(t)),
    defaultValues: DEFAULT_VALUES,
  })
  const { control, handleSubmit, watch, reset, trigger, setValue } = methods

  const watchBrands = watch('brands')
  const watchStartDate = watch('startDate')
  const watchEndDate = watch('endDate')

  const parseTypes = (values: responseFilter | FieldValues) => {
    const data: TypeActivity[] = []
    if (values.challenge) {
      data.push(TypeActivity.Challenge)
    }
    if (values.debate) {
      data.push(TypeActivity.Debate)
    }
    if (values.faceToFace) {
      data.push(TypeActivity.FaceToFace)
    }
    if (values.video) {
      data.push(TypeActivity.Video)
    }
    if (values.roundTable) {
      data.push(TypeActivity.RoundTable)
    }
    if (values.askAuthor) {
      data.push(TypeActivity.AskAuthor)
    }
    return data
  }

  const parseRes = async (values: responseFilter | FieldValues) => {
    const dataParse: ActivitiesRequestParams = {}
    if (values.brands !== '') {
      dataParse.brandSpace = values.brands
    }
    if (values.channels.length > 0) {
      dataParse.channels = values.channels
    }
    if (values.startDate !== '') {
      dataParse.startDate = new Date(values.startDate).toISOString()
    }
    if (values.endDate !== '') {
      dataParse.endDate = new Date(values.endDate).toISOString()
    }
    const types = await parseTypes(values)
    if (types.length > 0) {
      dataParse.types = types
    }
    if (
      !dataParse.brandSpace &&
      !dataParse.startDate &&
      !dataParse.endDate &&
      !dataParse.channels &&
      !dataParse.types
    ) {
      return undefined
    }
    return dataParse
  }

  const onSubmit = async (values: responseFilter | FieldValues) => {
    try {
      const resParse: ActivitiesRequestParams | undefined = await parseRes(
        values
      )
      if (!resParse) {
        await fetchActivities()
        withParams(false)
      } else {
        await fetchActivities(resParse)
        withParams(true)
      }
      pushDataLayer({
        event: 'apply_filter_community',
        category: 'interaction',
        action: 'apply_filter',
      })
      notify.success(t('Channels_filter.panel.succeessFeedback'))
    } catch (e) {
      notify.error(t('Channels_filter.panel.unknownError'))
    } finally {
      onClose()
    }
  }

  const parseBrands = async () => {
    const parseBrandSpaces = brandSpaces.map((brand: BrandSpace) => ({
      value: brand.id,
      label: brand.name ? brand.name : '',
    }))
    parseBrandSpaces && setValuesBrands(parseBrandSpaces)
  }

  const parseValuesChannels = (channels: Channel[]) => {
    const parseChannels = channels.map((channel) => ({
      value: channel.id,
      label: channel.name ? channel.name : '',
    }))
    parseChannels && setValuesChannels(parseChannels)
  }

  const initChannelsWithoutSpace = async () => {
    try {
      const resCh = await fetchChannels()
      if (resCh) {
        parseValuesChannels(resCh)
      }
    } catch (e) {
      notify.error(t('Channels_filter.panel.unknownError'))
    }
  }

  const initChannelsWithSpace = async () => {
    try {
      const resCh = await fetchChannels(watchBrands)
      if (resCh) {
        parseValuesChannels(resCh)
      }
    } catch (e) {
      notify.error(t('Channels_filter.panel.unknownError'))
    }
  }

  const initChannels = () => {
    if (!watchBrands) {
      setValue('brands', '')
      return initChannelsWithoutSpace()
    }
    setValue('channels', [])
    initChannelsWithSpace()
  }

  useEffect(() => {
    parseBrands()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandSpaces])

  useEffect(() => {
    initChannels()
  }, [watchBrands])

  useEffect(() => {
    const filterPanelElement = document.getElementById('left-panel')
    filterPanelElement?.appendChild(filterPanelWrapper.current)
  }, [])

  useEffect(() => {
    trigger('startDate')
    trigger('endDate')
  }, [watchStartDate, watchEndDate])

  return (
    <FiltersModal
      isOpened={isOpen}
      onClose={onClose}
      onReset={() => reset(DEFAULT_VALUES)}
      onFilter={handleSubmit(onSubmit)}
      texts={{
        title: t('activities.filter.title'),
        reset: t('Channels_filter.panel.reset'),
        viewResults: t('activities.filter.results'),
      }}
    >
      <FormProvider methods={methods}>
        <FiltersModal.Section title={t('activities.filter.organizer')}>
          <FormSelect
            control={control}
            name="brands"
            label={t('brands.title')}
            placeholder={t('activities.filter.organizer.placeholder')}
            options={valuesBrands}
            noOptionsMessage={t('select.empty')}
          />
          <FormMultiSelect
            control={control}
            name="channels"
            label={t('activities.filter.organizer.channels')}
            placeholder={t('activities.filter.organizer.channels.placeholder')}
            options={valuesChannels}
          />
        </FiltersModal.Section>
        <FiltersModal.Section title={t('activities.filter.type')}>
          <FormCheckbox
            name="video"
            control={control}
            label={t('activities.filter.type.video')}
          />
          <FormCheckbox
            name="roundTable"
            control={control}
            label={t('activities.filter.type.roundTable')}
          />
          <FormCheckbox
            name="askAuthor"
            control={control}
            label={t('activities.filter.type.askAuthor')}
          />
          <FormCheckbox
            name="faceToFace"
            control={control}
            label={t('activities.filter.type.faceToFace')}
          />
          <FormCheckbox
            name="challenge"
            control={control}
            label={t('activities.filter.type.challenge')}
          />
        </FiltersModal.Section>
        <FiltersModal.Section title={t('activities.filter.date')}>
          <FormDate
            control={control}
            name="startDate"
            label={t('activities.filter.date.since')}
            isUTC={true}
          />
          <FormDate
            control={control}
            name="endDate"
            label={t('activities.filter.date.until')}
            isUTC={true}
          />
        </FiltersModal.Section>
      </FormProvider>
    </FiltersModal>
  )
}

export default FilterActivities
