import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FormRadio, FormProvider, Loader } from '@liveconnect/components'

import { Main } from '../../../components/Main'
import FormActions from '../../../components/FormActions'
import useSnackBars from '../../../utils/notifications/useNotifications'
import useMember from '../../../core/member/useMember'
import { useForm } from 'react-hook-form'
import useLocalizations from '../../../core/Localizations/useLocalizations'
import { ChangeLocalizationForm } from '../../../core/Localizations/types'
import { useCustomRouter } from '../../../utils/useCustomRouter'
import { DEFAULT_LANG } from '../../../i18n/config'
import useUi from '../../../core/ui/useUi'
import { useBlockRouteChangeWithDialog } from '../../../utils/routing/useBlockRouteChange'

import './styles.scss'

interface Option {
  value: string
  label: string
}

const LocalizationScreen: FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const notify = useSnackBars()
  const { basePath } = useCustomRouter()
  const { changeLocalization, member } = useMember()
  const { getLocalizations } = useLocalizations()
  const { showConfirmation } = useUi()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [options, setOptions] = useState<Option[]>([])

  const methods = useForm<ChangeLocalizationForm>({
    mode: 'onChange',
    defaultValues: { isoCode: member?.isoCode ?? DEFAULT_LANG },
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, isDirty },
  } = methods
  useBlockRouteChangeWithDialog(isDirty && !isSubmitting)

  const fetchLocalizations = async () => {
    const localizations = await getLocalizations()
    setOptions(
      (localizations ?? []).map((item) => ({
        value: item.isoCode,
        label: item.name,
      }))
    )
  }

  const handleConfirm = (data: ChangeLocalizationForm) => {
    showConfirmation({
      title: t(`localization.confirmation.title`),
      subtitle: t(`localization.confirmation.subtitle`),
      text: t(`localization.confirmation.text`),
      confirmText: t('common.accept'),
      cancelText: t('common.cancel'),
      iconName: 'report_problem',
      onConfirm: () => onSubmit(data),
    })
  }

  const onSubmit = async (data: ChangeLocalizationForm) => {
    setIsSubmitting(true)
    try {
      await changeLocalization(data)
      notify.success(t('localization.succeessFeedback'))
      reset()
      navigate(`${basePath}/configuration`)
    } catch (e) {
      notify.error(t('localization.unknownError'))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    fetchLocalizations()
  }, [])

  return (
    <Main ariaLabelledby="localization" className="Localization">
      <h1 className="h3">{t('common.language')}</h1>
      <p>{t('localization.hint')}</p>
      <FormProvider methods={methods}>
        <div>
          <FormRadio name="isoCode" control={control} options={options} />
        </div>
        <FormActions>
          <div className="Localization__actions">
            <button
              type="button"
              onClick={() => navigate(`${basePath}/configuration`)}
              className="btn btn-outline-primary mr-2"
            >
              {t('common.cancel')}
            </button>
            <button
              className="btn btn-primary"
              disabled={!isValid}
              onClick={handleSubmit(handleConfirm)}
            >
              {isSubmitting ? <Loader /> : t('common.save')}
            </button>
          </div>
        </FormActions>
      </FormProvider>
    </Main>
  )
}

export default LocalizationScreen
