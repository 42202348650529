import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type { RootState } from '../reduxProvider'
import { TenantState } from './types'

import ifemaLogo from '../../../assets/images/ifema-placeholder.svg'

const initialState: TenantState = {
  detail: {
    name: 'IFEMA Madrid',
    description: 'Siente la inspiración',
    image: ifemaLogo,
    id: '3a88c5e5-a6e1-4898-b72b-103e4eed1731',
  },
}

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState,
  reducers: {},
})

export const {} = tenantSlice.actions

export const selectTopics = (state: RootState) => state.tenant

export default tenantSlice.reducer
