import { FC } from 'react'
import { Modal } from 'react-bootstrap'
import ReactPlayer from 'react-player'
import { Pagination, Navigation, Keyboard } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import './styles.scss'

export interface MediaCarrouselItem {
  multimediaUrl: string
  type: 'Image' | 'Video'
  title?: string
}

interface MediaCarrouselProps {
  show: boolean
  initialSlide?: number
  onHide: () => void
  items: MediaCarrouselItem[]
}

const MultimediaCarrousel: FC<MediaCarrouselProps> = ({
  show,
  initialSlide = 0,
  onHide,
  items,
}) => {
  return (
    <Modal
      className="multimedia-carrousel-modal"
      show={show}
      fullscreen={true}
      onHide={onHide}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Swiper
          pagination={{
            type: 'fraction',
          }}
          keyboard={{
            enabled: true,
          }}
          loop={true}
          initialSlide={initialSlide}
          navigation={true}
          modules={[Pagination, Navigation, Keyboard]}
          className="multimedia-carrousel-swiper"
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="multimedia-carrousel-item">
                <div className="multimedia-carrousel-item__multimedia">
                  {item.type === 'Image' ? (
                    <img
                      loading="lazy"
                      className="multimedia-carrousel-item__img"
                      src={item.multimediaUrl}
                      alt={item.title}
                    />
                  ) : (
                    <>
                      <ReactPlayer
                        url={item.multimediaUrl}
                        width="100%"
                        height="100%"
                        autoPlay
                        controls
                      />
                    </>
                  )}
                </div>
                <p className="multimedia-carrousel-item__title">{item.title}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Modal.Body>
    </Modal>
  )
}

export default MultimediaCarrousel
