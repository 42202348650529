import { NavbarItem } from './types'

export const navbarItems: NavbarItem[] = [
  {
    key: 'home',
    trnsKey: 'navMenu.home',
    icon: 'home',
    path: '/home',
    exact: false,
    disabled: false,
  },
  {
    key: 'brands',
    trnsKey: 'navMenu.brand',
    icon: 'space_365',
    path: '/brands',
    exact: false,
    disabled: false,
  },
  {
    key: 'people',
    trnsKey: 'navMenu.people',
    icon: 'people',
    path: '/people',
    exact: false,
    disabled: false,
  },
  {
    key: 'activities',
    trnsKey: 'navMenu.activities',
    icon: 'event',
    path: '/activities',
    exact: false,
    disabled: false,
  },
]
