import { useAppSelector, useAppDispatch } from '../reduxProvider'
import { AccessDenyInfoResponse, TenantConfig } from './types'
import { setConfig, setAccessDenyInfo } from './store'
import useFetch from '../../utils/fetch/useFetch'

const API_VERSION = 'v1'
const APP_CODE = 'CommunitiesUser'

const useTenantConfig = () => {
  const dispatch = useAppDispatch()
  const { tenantConfig, accessDenyInfo } = useAppSelector(
    (state) => state.tenantConfig
  )
  const { settings } = useAppSelector((state) => state.settings)
  const { get: getTenant } = useFetch(
    `${settings?.tenantBff?.url}/api/${API_VERSION}`
  )
  const { get } = useFetch()

  const endpoint = 'tenant-config'
  const tenantsEndpoint = 'tenants'

  const fetchTenantConfig = async () => {
    const response: TenantConfig | undefined = await getTenant({
      endpoint,
      params: { application: APP_CODE },
    })
    response && dispatch(setConfig(response))
  }

  const fetchPublicTenantConfig = async () => {
    const tenantId = window.location.pathname.split('/').at(2)
    if (tenantId) {
      const response: TenantConfig | undefined = await getTenant({
        endpoint: `${endpoint}/public/${tenantId}`,
        isPublic: true,
      })
      response && dispatch(setConfig(response))
    }
  }

  const fetchAccessDenyPageInfo = async (tenantId: string) => {
    const response: AccessDenyInfoResponse[] | undefined = await get({
      endpoint: `${tenantsEndpoint}/access-deny-info/${tenantId}`,
    })
    if (response) dispatch(setAccessDenyInfo(response))
  }

  return {
    tenantConfig,
    fetchTenantConfig,
    fetchPublicTenantConfig,

    accessDenyInfo,
    fetchAccessDenyPageInfo,
  }
}

export default useTenantConfig
