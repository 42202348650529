import { useAppSelector, useAppDispatch } from '../reduxProvider'
import {
  UseContactRequestsResult,
  ContactRequestParams,
  ContactRequestListResponse,
  ContactRequestType,
  ContactRequestStatus,
} from './types'
import { setList, clearList, showLoader, hideLoader } from './store'
import useFetch from '../../utils/fetch/useFetch'

const useContactRequests = (
  type: ContactRequestType = 'Received'
): UseContactRequestsResult => {
  const dispatch = useAppDispatch()
  const { list, page, pageSize, availablePages, availableItems, isLoaded } =
    useAppSelector((state) => state.contactRequests)
  const { get, post, patch } = useFetch()

  const endpoint = 'contact-request'

  const getContactRequests = async (params?: ContactRequestParams) => {
    const response: ContactRequestListResponse | undefined = await get({
      endpoint,
      params: {
        page_size: pageSize,
        page: (params?.page || page) as number,
        type,
      },
    })
    return response
  }

  const fetchContactRequests = async (params?: ContactRequestParams) => {
    dispatch(showLoader())
    const response: ContactRequestListResponse | undefined =
      await getContactRequests(params)
    response &&
      dispatch(
        setList({
          list: response.items,
          page: response.page,
          pageSize: response.pageSize,
          availableItems: response.availableItems,
          availablePages: response.availablePages,
        })
      )
    dispatch(hideLoader())
  }

  const clearContactRequestList = () => dispatch(clearList())

  const createContactRequest = async (userId: string, message?: string) => {
    return await post({
      endpoint,
      body: message ? { userId, message } : { userId },
    })
  }

  const updateContactRequestStatus = async (
    status: ContactRequestStatus,
    id: string
  ) => {
    return await patch({
      endpoint: `${endpoint}/${id}`,
      body: { status },
    })
  }

  const acceptContactRequest = async (id: string) => {
    const status = 'Accepted'
    return await updateContactRequestStatus(status, id)
  }

  const cancelContactRequest = async (id: string) => {
    const status = 'Canceled'
    return await updateContactRequestStatus(status, id)
  }

  const rejectContactRequest = async (id: string) => {
    const status = 'Rejected'
    return await updateContactRequestStatus(status, id)
  }

  return {
    contactRequestList: list,
    page,
    availablePages,
    availableItems,
    pageSize,
    isLoaded,
    fetchContactRequests,
    clearContactRequestList,
    createContactRequest,
    acceptContactRequest,
    cancelContactRequest,
    rejectContactRequest,
  }
}

export default useContactRequests
